import React from 'react'
import ReactDOM from 'react-dom'
import { ApolloClient, ApolloProvider, createHttpLink, ApolloLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import jwt_decode from 'jwt-decode'
import axios from 'axios'

import { refreshToken } from './api/user'
import { isTokenExpired, logout } from './actions/user'

import './i18n';
import { cache } from './cache/cache'

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

axios.defaults.baseURL = process.env.REACT_APP_SERVER_URL
const accessToken = sessionStorage.getItem('AccessToken')
if (accessToken !== null)
    axios.defaults.headers.common['Authorization'] = accessToken

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_SERVER_URL}/graphql`
})

const authLink = setContext((_, { headers }) => {
    const token = sessionStorage.getItem('AccessToken')
    return {
        headers: {
            ...headers,
            authorization: token !== null ? token : "",
        }
    }
})

const refreshTokenLink = new TokenRefreshLink({
    isTokenValidOrUndefined: () => isTokenExpired(),
    fetchAccessToken: () =>  {
        const token = localStorage.getItem('RefreshToken')
        return refreshToken(token) as Promise<any>
    },
    handleFetch: accessToken => {
        const decoded: any = jwt_decode(accessToken)
        sessionStorage.setItem('AccessToken', accessToken)
        sessionStorage.setItem('ExpiresIn', decoded.exp)
    },
    handleResponse: (operation, accessTokenField) => response => {
        return {
            access_token: response.data.AccessToken
        }
    },
    handleError: err => {
        console.log('TOKEN handleError', err)
        logout()
    }
  })

const client = new ApolloClient({
    link: ApolloLink.from([refreshTokenLink, authLink, httpLink]),
    cache: cache
})

ReactDOM.render(
  <React.StrictMode>
      <ApolloProvider client={client}>
        <React.Suspense fallback={<div className="preloader"></div>}>
            <App />
        </React.Suspense>
      </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
