import mixpanel from 'mixpanel-browser'

if (process.env.REACT_APP_MIXPANEL_TOKEN)
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN)

let actions = {
    identify: (id) => {
        mixpanel.identify(id)
        console.log(`Mixpanel identify: ID: ${id}`)
    },
    alias: (id) => {
        mixpanel.alias(id)
    },
    reset: () => {
        mixpanel.reset()
        console.log(`Mixpanel reset`)
    },
    track: (name, props = {}) => {
        mixpanel.track(name, props)
        console.log(`Mixpanel track Event: ${name}, Props:`, props)
    },
    people: {
        set: (props) => {
            mixpanel.people.set(props)
            console.log(`Mixpanel people.set: Props:`, props)
        },
    },
}

export let Mixpanel = actions