import React from 'react'
import { BrowserRouter as Router, Route, Switch, useLocation, useHistory } from 'react-router-dom'
import { useQuery, useReactiveVar } from '@apollo/client'
import ym from 'react-yandex-metrika'
import { YMInitializer } from 'react-yandex-metrika'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { countries } from 'countries-list'

import { Mixpanel } from './mixpanel'
import usePrevious from './hooks/usePrevious'
import { loginGoogle } from './actions/user'
import { getSimpleLangCode, getURLforLanguages } from './common'

import { useGetUserInfoQuery, useUserRegionLazyQuery, useUserRegionQuery } from './types/graphql'
import { userVar, categoryFilterVar, successVar, errorsVar, regionVar } from './cache/cache'

const Home = React.lazy(() => import('./pages/Home'))
const User = React.lazy(() => import('./pages/User'))
const Settings = React.lazy(() => import('./pages/Settings'))
const Messages = React.lazy(() => import('./pages/Messages'))
const Search = React.lazy(() => import('./pages/Search'))
const Profile = React.lazy(() => import('./pages/Profile'))
const PostDetail = React.lazy(() => import('./pages/PostDetail'))
const Categories = React.lazy(() => import('./pages/Categories'))
const Catalog = React.lazy(() => import('./pages/Catalog'))
const ManageProfile = React.lazy(() => import('./pages/ManageProfile'))
const ManageCategory = React.lazy(() => import('./pages/ManageCategory'))
const Bookmarks = React.lazy(() => import('./pages/Bookmarks'))
const MyPosts = React.lazy(() => import('./pages/MyPosts'))
const MyComments = React.lazy(() => import('./pages/MyComments'))
const MyAds = React.lazy(() => import('./pages/MyAds'))
const MyShop = React.lazy(() => import('./pages/MyShop'))
const Compare = React.lazy(() => import('./pages/Compare'))
const Notifications = React.lazy(() => import('./pages/Notifications'))
const NotFound = React.lazy(() => import('./pages/NotFound/NotFound'))
const Privacy = React.lazy(() => import('./pages/Privacy'))
const Terms = React.lazy(() => import('./pages/Terms'))
const Contacts = React.lazy(() => import('./pages/Contacts'))
const Help = React.lazy(() => import('./pages/Help'))
const PersonalFeed = React.lazy(() => import('./pages/PersonalFeed'))
const Ad = React.lazy(() => import('./pages/Ad'))
const ManageAd = React.lazy(() => import('./pages/ManageAd'))
const CreateShop = React.lazy(() => import('./pages/CreateShop'))
const Popup = React.lazy(() => import('./components/common/Popup'))
const Login = React.lazy(() => import('./components/common/Login'))
const Register = React.lazy(() => import('./components/common/Register'))
const ConfirmUser = React.lazy(() => import('./components/common/ConfirmUser'))
const ForgotPassword = React.lazy(() => import('./components/common/ForgotPassword'))
const RegionSettings = React.lazy(() => import('./components/common/RegionSettings'))

const App: React.FC = () => {
    const { t, i18n } = useTranslation()
    const history = useHistory()
    const currentLang = getSimpleLangCode(i18n.language)
    const availableLangs = ['ru', 'fi', 'sv'] //en is default
    const basename = (availableLangs.includes(currentLang)) ? `/${currentLang}` : ''
    const region = useReactiveVar(regionVar)

    const { refetch, loading, data } = useGetUserInfoQuery({
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            if (data?.userInfo) {
                Mixpanel.people.set({
                    "$first_name": data.userInfo.name,
                    "$last_name": data.userInfo.family_name,
                    "$email": data.userInfo.email
                })
                Mixpanel.identify(data.userInfo.id)
                userVar(data?.userInfo)
            }
        },
        onError: err => {
            console.log('OnError', err)
        },
        errorPolicy: 'ignore'
    })

    const setGoogleToken = React.useCallback(async (token) => {
        await loginGoogle(token)
    }, [])

    React.useEffect(() => {
        //Google auth
        const paramString = new URLSearchParams(window.location.href.split('#')[1])
        const token = paramString.get('access_token')
        const accessToken = sessionStorage.getItem('AccessToken')
        console.log('GOOGLE', accessToken, token, loading)
        if (accessToken === null && token !== null && loading === false) {
            setGoogleToken(token)
            refetch()
        }
    }, [setGoogleToken, refetch, loading, history])

    //Autodetect user region by IP
    useUserRegionQuery({
        skip: (region.country && region.country.code !== null && region.country.code !== ''),
        onCompleted: data => {
            let currency
            let phoneCode
            if (data.userRegion?.countryCode) {
                currency = countries[data.userRegion.countryCode].currency.split(',')
                phoneCode = countries[data.userRegion.countryCode].phone
            }
            
            const region = (data?.userRegion) 
                ? {
                    ...(data.userRegion?.countryCode) && {
                        country: {
                            ...(data.userRegion?.country) && { name: data.userRegion?.country },
                            ...(data.userRegion?.countryCode) && { code: data.userRegion?.countryCode },
                            ...(phoneCode) && { phoneCode: phoneCode }
                        }
                    },
                    ...(data.userRegion?.city) && {
                        city: { 
                            ...(data.userRegion?.city?.name) && { name: data.userRegion.city.name }, 
                            ...(data.userRegion?.city?.coords) && { coords: data.userRegion.city.coords as number[] }, 
                            ...(data.userRegion?.city?.postal) && { postal: data.userRegion.city.postal }, 
                        } 
                    },
                    ...(currency) && {
                        currency: currency[0]
                    }
                } 
                : {
                    country: {
                        name: 'Finland',
                        code: 'FI'
                    },
                    city: {
                        name: "Helsinki",
                        coords: [60.1719, 24.9347],
                        postal: "00921",
                    },
                    currency: "EUR"
                }
            localStorage.setItem('region', JSON.stringify(region))
            regionVar(region)
        }
    })

    return (
        <div id="app">
            <Helmet>
                <html lang={getSimpleLangCode(i18n.language)} />
                <title>Squid</title>
                <meta name="description" content={t('App.meta-description')} />
            </Helmet>
            {
                (process.env.NODE_ENV === 'production') &&
                    <YMInitializer accounts={[73107007]} options={{defer:true, clickmap:true, trackLinks:true, accurateTrackBounce:true, webvisor: true}} version="2" />
            }
            
            <Router>
                <React.Suspense fallback={<div className="preloader"></div>}>
                    <Switch>
                        <Route exact path={(basename !== '') ? basename : '/'} component={Home} />
                        <Route exact path={`${basename}/user/:userId`} render={ () => <User activeTab="feed"/> } />
                        <Route exact path={`${basename}/user/:userId/products`} render={ () => <User activeTab="products"/> } />
                        <Route exact path={`${basename}/user/:userId/marketplace`} render={ () => <User activeTab="marketplace"/> } />
                        <Route exact path={`${basename}/user/:userId/collections`} render={ () => <User activeTab="collections"/> } />
                        <Route exact path={`${basename}/user/:userId/rewards`} render={ () => <User activeTab="rewards"/> } />
                        <Route path={`${basename}/forgot-password`} component={ForgotPassword} />
                        <Route path={`${basename}/settings`} component={Settings} />
                        <Route path={`${basename}/messages`} component={Messages} />
                        <Route path={`${basename}/search`} component={Search} />
                        <Route path={`${basename}/category/:categoryId`} component={Catalog} />
                        <Route path={`${basename}/category/`} component={Categories} />
                        <Route exact path={`${basename}/profile/:profileId`} render={ () => <Profile activeTab="feed"/> } />
                        <Route exact path={`${basename}/profile/:profileId/characteristics`} render={ () => <Profile activeTab="attributes"/> } />
                        <Route exact path={`${basename}/profile/:profileId/reviews`} render={ () => <Profile activeTab="reviews"/> } />
                        <Route exact path={`${basename}/profile/:profileId/offers`} render={ () => <Profile activeTab="offers"/> } />
                        <Route exact path={`${basename}/profile/:profileId/media`} render={ () => <Profile activeTab="media"/> } />
                        <Route exact path={`${basename}/profile/:profileId/questions`} render={ () => <Profile activeTab="questions"/> } />
                        <Route path={`${basename}/post/:postId`} component={PostDetail} />
                        <Route path={`${basename}/manage-profile/:profileId`} component={ManageProfile} />
                        <Route path={`${basename}/manage-profile`} component={ManageProfile} />
                        <Route path={`${basename}/manage-category/:categoryId`} component={ManageCategory} />
                        <Route path={`${basename}/manage-category`} component={ManageCategory} />
                        <Route path={`${basename}/bookmarks`} component={Bookmarks} />
                        <Route path={`${basename}/myposts`} component={MyPosts} />
                        <Route path={`${basename}/mycomments`} component={MyComments} />
                        <Route exact path={`${basename}/myads/active`} component={MyAds} />
                        <Route exact path={`${basename}/myads/inactive`} component={MyAds} />
                        <Route exact path={`${basename}/myads/archive`} component={MyAds} />
                        <Route exact path={`${basename}/myshop`} component={MyShop} />
                        <Route exact path={`${basename}/myshop/:shopId/overview`} render={ () => <MyShop activePage="overview"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/products/sources`} render={ () => <MyShop activePage="products/sources"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/products/catalog`} render={ () => <MyShop activePage="products/catalog"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/products/catalog/:offerId/info`} render={ () => <MyShop activePage="products/catalog/info"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/products/catalog/:offerId/control`} render={ () => <MyShop activePage="products/catalog/control"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/products/catalog/:offerId/stats`} render={ () => <MyShop activePage="products/catalog/stats"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/strategy`} render={ () => <MyShop activePage="strategy"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/quality`} render={ () => <MyShop activePage="quality"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/settings/common`} render={ () => <MyShop activePage="settings/common"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/settings/delivery`} render={ () => <MyShop activePage="settings/delivery"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/settings/pickup`} render={ () => <MyShop activePage="settings/pickup"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/settings/access`} render={ () => <MyShop activePage="settings/access"/> } />
                        <Route exact path={`${basename}/myshop/:shopId/settings/others`} render={ () => <MyShop activePage="settings/others"/> } />
                        <Route path={`${basename}/notifications`} component={Notifications} />
                        <Route path={`${basename}/compare`} component={Compare} />
                        <Route path={`${basename}/privacy`} component={Privacy} />
                        <Route path={`${basename}/terms`} component={Terms} />
                        <Route path={`${basename}/contacts`} component={Contacts} />
                        <Route path={`${basename}/help`} component={Help} />
                        <Route path={`${basename}/feed`} component={PersonalFeed} />
                        <Route path={`${basename}/create-shop`} component={CreateShop} />
                        <Route exact path={`${basename}/ad/:adId`} render={ () => <Ad activeTab="common"/> } />
                        <Route exact path={`${basename}/ad/:adId/characteristics`} render={ () => <Ad activeTab="attributes"/> } />
                        <Route exact path={`${basename}/ad/:adId/offers`} render={ () => <Ad activeTab="offers"/> } />
                        <Route path={`${basename}/manage-ad`} component={ManageAd} />
                        <Route path={(basename !== '') ? basename : '/'} component={NotFound} />
                    </Switch>
                </React.Suspense>
                <Location/>
            </Router>
            
        </div>
    )
}

const Location: React.FC =() => {
    const { i18n, t } = useTranslation()
    const location = useLocation()
    const errors = useReactiveVar(errorsVar)
    const success = useReactiveVar(successVar)
    const prevPageKey = usePrevious(location.key)
    const prevPagePath = usePrevious(location.pathname)
    const categoryFilter = useReactiveVar(categoryFilterVar)
    const currentLang = getSimpleLangCode(i18n.language)
    const availableLangs = ['ru', 'fi', 'sv'] //en is default

    React.useEffect(() => {
        if (location.key !== prevPageKey && prevPageKey !== undefined) {
            if (errors.length > 0) {
                errorsVar([])
            }
            if (success !== null) {
                successVar(null)
            }
                
        }
    }, [prevPageKey, location, errors, success])

    React.useEffect(() => {
        if (location.key !== prevPageKey) {
            if (location.hash === '#login')
                Mixpanel.track('Login start')
            if (location.hash === '#register')
                Mixpanel.track('Register start')
            if (location.pathname === '/manage-profile')
                Mixpanel.track('Add profile start')
        }
    }, [location, prevPageKey])

    //reset categoryFilter
    React.useEffect(() => {
        if (location.pathname !== prevPagePath && Object.keys(categoryFilter).length > 0)
            categoryFilterVar({}) 
    }, [location, prevPagePath, categoryFilter])

    //Yandex Metrika
    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production')
            ym('hit', location.pathname+location.search+location.hash)
    }, [location])

    //change language by url
    React.useEffect(() => {
        const pathLang = location.pathname.split('/')[1]
        if (pathLang !== currentLang) {
            if (availableLangs.includes(pathLang)) {
                i18n.changeLanguage(pathLang)
            } else if (pathLang.length !== 2 && currentLang !== 'en') {
                i18n.changeLanguage('en')
            }
        }
    }, [location, availableLangs, currentLang, i18n])


    //Popup
    //TODO: first opening popup scroll going to top page, need to fix it
    //const scroll = document.documentElement.scrollTop;
    //console.log('SCROLL', scroll)
    const renderPopup = (hash) => {
        switch(hash) {
            case '#login': return <Login/>
            case '#register': return <Register/>
            case '#confirm-user': return <ConfirmUser/>
            case '#forgot': return <ForgotPassword/>
            case '#region': return <RegionSettings />
            default: return ''
        }
    }
    if (['#login', '#register', '#confirm-user', '#forgot', '#region'].includes(location.hash))
        return (
            <Popup overflow={location.hash === '#region'} title={t(`Popup.${location.hash}-title`)}>
                { renderPopup(location.hash) }
            </Popup>
        )

    const pageUrls = getURLforLanguages(location.pathname)
    
    return (
        <>
            <Helmet>
                <link rel="alternate" hrefLang="en" href={process.env.REACT_APP_SITE_URL+pageUrls['en']} />
                <link rel="alternate" hrefLang="fi" href={process.env.REACT_APP_SITE_URL+pageUrls['fi']} />
                <link rel="alternate" hrefLang="sv" href={process.env.REACT_APP_SITE_URL+pageUrls['sv']} />
                <link rel="alternate" hrefLang="ru" href={process.env.REACT_APP_SITE_URL+pageUrls['ru']} />
            </Helmet>
        </>
    )
}

export default App