import axios from 'axios'
import jwt_decode from 'jwt-decode'

import { cache, userVar } from '../cache/cache'
import { Mixpanel } from '../mixpanel'

export const loginGoogle = (token: string) => {
    return new Promise(resolve => {
        const decoded: any = jwt_decode(token)
        axios.defaults.headers.common['Authorization'] = token
        sessionStorage.setItem('AccessToken', token) 
        sessionStorage.setItem('ExpiresIn', decoded.exp)

        Mixpanel.track('Login end')
        resolve(true)
    })
}

export const isTokenExpired = () => {
    const refreshToken = localStorage.getItem('RefreshToken')
    const accessToken = sessionStorage.getItem('AccessToken')
    const expiresIn = sessionStorage.getItem('ExpiresIn')
    const now = Math.round((new Date()).getTime() / 1000)

    if (accessToken === null && refreshToken === null) //not authenthication
        return true
    
    if (expiresIn && parseInt(expiresIn) > now)
        return true

    return false
}

export const logout = () => {
    localStorage.removeItem('RefreshToken')
    sessionStorage.removeItem('AccessToken')
    sessionStorage.removeItem('ExpiresIn')

    cache.modify({
        fields: {
            userInfo(existingUserRef, { DELETE }) {
                return null
            }
        }
    })

    userVar(null)

    Mixpanel.reset()
}