import _ from 'lodash'

import { errorType, errorsType } from './cache/cache'

export interface IReadAsDataURLResult {
    data: string | ArrayBuffer | null,
    name: string,
    size: number,
    type: string
}

export const readAsDataURL = (file): Promise<IReadAsDataURLResult> => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader()
        fileReader.onload = function() {
            return resolve({ data: fileReader.result, name: file.name, size: file.size, type: file.type })
        }
        fileReader.readAsDataURL(file)
    })
}

export const validateEmail = (email: string) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
    return re.test(String(email).toLowerCase())
}

export const validatePhone = (phone) => {
    const re = /^\+[1-9]\d{10,14}$/
    return re.test(phone)
}

export const validateURL = (str:string) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i') // fragment locator
    return !!pattern.test(str)
}

//en-US to en
export const getSimpleLangCode = (code: string) => {
    return (code.indexOf('-') > -1 && code.length > 2)? code.split('-')[0] : code
}

export const toHHMMSS = (secs: number) => {
    var hours   = Math.floor(secs / 3600)
    var minutes = Math.floor(secs / 60) % 60
    var seconds = secs % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}
/**
 * today - hh:ss
 * week - day of week
 * other - d.m.y
 * @param date ISO string
 */
export const dateFormat = (date: number, locale: string = 'en') => {
    const inputDate = new Date(date)
    const todaysDate = new Date()
    const firstDayOfWeek = new Date(new Date().setDate(todaysDate.getDate() - todaysDate.getDay()))
    const lastDayOfWeek = new Date(firstDayOfWeek)
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6)
    
    if (new Date(date).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) { //today
        return String(inputDate.getHours()).padStart(2, '0')+':'+String(inputDate.getMinutes()).padStart(2, '0')
    } else if (inputDate >= firstDayOfWeek && inputDate <= lastDayOfWeek) { //this week
        return todaysDate.toLocaleString(locale, {weekday: 'long'})
    } else { //default
        return ('0' + inputDate.getDate()).slice(-2) + '.' + ('0' + (inputDate.getMonth()+1)).slice(-2) + '.' + inputDate.getFullYear();
    }
}

export const groupByDay = (arr: any): any[] => {
    let newArr = []
    let prev = ''
    let i = 0
    arr.forEach(item => {
        const d = new Date(Number(item.node.creationDate))
        const dateIndex = d.getFullYear()+"."+d.getMonth()+"."+d.getDate()

        if (prev !== dateIndex) {
            newArr[dateIndex] = []
            i = 0
            prev = dateIndex
        }

        newArr[dateIndex][i] = item
        i++
    })
    return newArr
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 * 
 * @param text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "14px verdana").
 * 
 * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export const getTextWidth = (text, font) => {
    // if given, use cached canvas for better performance
    // else, create new canvas
    const canvas = getTextWidth['canvas'] || (getTextWidth['canvas'] = document.createElement("canvas"))
    const context = canvas.getContext("2d")
    context!.font = font
    const metrics = context?.measureText(text)
    return (metrics?.width) ? metrics?.width : 0
}

export const truncate = (str, len = 150) => {
    return str.length > len ? `${str.substring(0, len)}...` : str
}

export const addMetaNoindex = () => {
    let tag = document.createElement('meta')
        tag.name = 'robots'
        tag.content = 'noindex'
    document.head.appendChild(tag)
}

export const removeMetaNoindex = () => {
    document.querySelector("[name='robots']")?.remove()
}

export const URLwithLang = (lang, url) => {
    const langCode = getSimpleLangCode(lang)
    return (langCode === 'en') ? `/${url}` : `/${langCode}/${url}`
}

export const getURLforLanguages = (url) => {
    const urls = {}
    const languages = ['en', 'fi', 'sv', 'ru']
    const path = url.split('/')

    if (path[1].length === 2) {
        path.splice(1, 1)
    }

    languages.forEach(lang => {
        const url = [...path]
        if (lang !== 'en') {
            url.splice(1, 0, lang) 
        }
        urls[lang] = url.join('/')
    })

    return urls
}

export const addLastSlash = url => {
    return url.replace(/\/?$/, '/')
}

export const setErrors = (prevErrors: errorsType, errors: errorType[]): errorType[] => {
    return _.uniqWith([ ...prevErrors, ...(_.isArray(errors) ? errors : [errors]) ], _.isEqual)
}

export const deleteErrors = (prevErrors: errorsType, errors: errorType[]): errorType[] => {
    return _.filter(prevErrors, (item) => !errors.includes(item))
}

export const setLocalStorageWithTTL = (key, value, ttl) => {
    const itemStr = localStorage.getItem(key)
    const now = new Date()
    let expiry = now.getTime() + ttl

    if (itemStr !== null) {
        const currentitem = JSON.parse(itemStr)
        if (now.getTime() < currentitem.expiry) {
            expiry = currentitem?.expiry
        }
    }

    const item = {
        value: value,
        expiry: expiry,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

export const getLocalStorageWithTTL = (key) => {
    const itemStr = localStorage.getItem(key)

    if (!itemStr) {
        return null
    }

    const item = JSON.parse(itemStr)
    const now = new Date()

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }
    return item.value
}