import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date scalar type */
  Date: any;
  /** Mongo object id scalar type */
  ObjectId: any;
};

export type AuthenticationError = {
  __typename?: 'AuthenticationError';
  message?: Maybe<Scalars['String']>;
};

export type Author = {
  __typename?: 'Author';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Autocomplete = {
  __typename?: 'Autocomplete';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type Bookmark = Marketplace | Post;

export type BookmarkWithCount = {
  __typename?: 'BookmarkWithCount';
  bookmarks?: Maybe<Array<Maybe<Bookmark>>>;
  count?: Maybe<Scalars['Int']>;
};

export type BooleanValues = {
  __typename?: 'BooleanValues';
  count?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['ID']>;
};

export type Category = {
  __typename?: 'Category';
  filter?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  parameterGroups?: Maybe<Array<Maybe<ParameterGroups>>>;
  redirectId?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type CategoryFilters = {
  __typename?: 'CategoryFilters';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parameterType?: Maybe<Scalars['String']>;
  valuesInfo?: Maybe<FilterValues>;
};

export type CodeDeliveryDetailsType = {
  __typename?: 'CodeDeliveryDetailsType';
  AttributeName?: Maybe<Scalars['String']>;
  DeliveryMedium?: Maybe<Scalars['String']>;
  Destination?: Maybe<Scalars['String']>;
};

export type Collection = {
  __typename?: 'Collection';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  profiles?: Maybe<Array<Maybe<UserProduct>>>;
};

export type Comment = {
  __typename?: 'Comment';
  answer?: Maybe<Scalars['Boolean']>;
  author?: Maybe<Author>;
  creationDate?: Maybe<Scalars['Date']>;
  id: Scalars['ObjectId'];
  message?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['ObjectId']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  profileName?: Maybe<Scalars['String']>;
  replyCount?: Maybe<Scalars['Int']>;
};

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Comment>;
};

export type CommentWithCount = {
  __typename?: 'CommentWithCount';
  comments?: Maybe<Array<Maybe<MyComment>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Conditions = {
  __typename?: 'Conditions';
  new?: Maybe<Scalars['Int']>;
  used?: Maybe<Scalars['Int']>;
};

export type ConfirmationCodeResponse = {
  __typename?: 'ConfirmationCodeResponse';
  CodeDeliveryDetails?: Maybe<CodeDeliveryDetailsType>;
  Username?: Maybe<Scalars['String']>;
};

export type DeletedComment = {
  __typename?: 'DeletedComment';
  id?: Maybe<Scalars['ObjectId']>;
  message?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['ObjectId']>;
};

export type Dialog = {
  __typename?: 'Dialog';
  blockedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  creationDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ObjectId']>;
  lastMessage?: Maybe<Message>;
  participant?: Maybe<Author>;
  unreadMessages?: Maybe<Scalars['Int']>;
  updatedDate?: Maybe<Scalars['String']>;
};

export type DialogEdge = {
  __typename?: 'DialogEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Dialog>;
};

export type DocumentComment = {
  __typename?: 'DocumentComment';
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  pageInfo: PageInfo;
};

export type DocumentDialog = {
  __typename?: 'DocumentDialog';
  edges?: Maybe<Array<Maybe<DialogEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentMarketplace = {
  __typename?: 'DocumentMarketplace';
  edges?: Maybe<Array<Maybe<MarketplaceEdge>>>;
  pageInfo: PageInfo;
};

export type DocumentMessage = {
  __typename?: 'DocumentMessage';
  edges?: Maybe<Array<Maybe<MessageEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type DocumentPost = {
  __typename?: 'DocumentPost';
  edges?: Maybe<Array<Maybe<PostEdge>>>;
  pageInfo: PageInfo;
};

export type DocumentProfile = {
  __typename?: 'DocumentProfile';
  edges?: Maybe<Array<Maybe<ProfileEdge>>>;
  pageInfo: PageInfo;
};

export type DocumetnReply = {
  __typename?: 'DocumetnReply';
  edges?: Maybe<Array<Maybe<ReplyEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type FeatureGeometry = {
  __typename?: 'FeatureGeometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<Scalars['String']>;
};

export type FeatureProperties = {
  __typename?: 'FeatureProperties';
  cluster?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['ObjectId']>;
};

export type Filter = {
  condition?: Maybe<Scalars['String']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  language?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  maxPrice?: Maybe<Scalars['Float']>;
  mediaType?: Maybe<Scalars['String']>;
  minPrice?: Maybe<Scalars['Float']>;
  postType?: Maybe<Scalars['String']>;
  radius?: Maybe<Scalars['Int']>;
  rating?: Maybe<Array<Maybe<Scalars['Int']>>>;
  tradeType?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
};

export type FilterCount = {
  __typename?: 'FilterCount';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
};

export type FilterValues = {
  __typename?: 'FilterValues';
  Boolean?: Maybe<Array<Maybe<BooleanValues>>>;
  Group?: Maybe<Array<Maybe<GroupValues>>>;
  Number?: Maybe<NumberValues>;
  String?: Maybe<Array<Maybe<StringValues>>>;
  StringColor?: Maybe<Array<Maybe<StringColorValues>>>;
};

export type Filters = {
  __typename?: 'Filters';
  condition?: Maybe<Conditions>;
  language?: Maybe<Array<Maybe<PostLangs>>>;
  locations?: Maybe<Array<Maybe<LocationType>>>;
  maxPrice?: Maybe<PriceType>;
  mediaType?: Maybe<MediaType>;
  minPrice?: Maybe<PriceType>;
  postType?: Maybe<PostTypes>;
  rating?: Maybe<RatingsType>;
  tradeType?: Maybe<TradeTypes>;
};

export type GroupParameterInput = {
  group?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['ID']>;
};

export type GroupParameterValue = {
  __typename?: 'GroupParameterValue';
  group?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['String']>;
};

export type GroupParams = {
  __typename?: 'GroupParams';
  id?: Maybe<Scalars['ID']>;
  isFilter?: Maybe<Scalars['Boolean']>;
};

export type GroupValues = {
  __typename?: 'GroupValues';
  count?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['ID']>;
};

export type Image = {
  __typename?: 'Image';
  image?: Maybe<Scalars['String']>;
};

export type Location = {
  __typename?: 'Location';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type LocationFilter = {
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  radius?: Maybe<Scalars['Int']>;
};

export type LocationType = {
  __typename?: 'LocationType';
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  count?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
};

export type MainSearch = {
  __typename?: 'MainSearch';
  defaultPicture?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Market = {
  __typename?: 'Market';
  condition?: Maybe<Scalars['String']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  currency?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  tradeType?: Maybe<Scalars['String']>;
};

export type MarketInput = {
  condition?: Maybe<Scalars['String']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  currency?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  tradeType?: Maybe<Scalars['String']>;
};

export type MarketlaceWithCount = {
  __typename?: 'MarketlaceWithCount';
  marketplace?: Maybe<Array<Maybe<Marketplace>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Marketplace = {
  __typename?: 'Marketplace';
  author?: Maybe<MarketplaceAuthor>;
  bookmarks?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['ObjectId']>;
  condition?: Maybe<Scalars['String']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  creationDate?: Maybe<Scalars['Date']>;
  currency?: Maybe<Scalars['String']>;
  delivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  inBookmarks?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Maybe<Media>>>;
  oldPrice?: Maybe<Scalars['Float']>;
  otherMarketplaceCount?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  profile?: Maybe<UserProduct>;
  publishedDate?: Maybe<Scalars['Date']>;
  rejectReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  tradeType?: Maybe<Scalars['String']>;
  views?: Maybe<Array<Maybe<MarketplaceViews>>>;
  viewsCount?: Maybe<Scalars['Int']>;
};

export type MarketplaceAuthor = {
  __typename?: 'MarketplaceAuthor';
  creationDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
  marketplaceCount?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type MarketplaceCategorySearch = {
  __typename?: 'MarketplaceCategorySearch';
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  namePath?: Maybe<Scalars['String']>;
};

export type MarketplaceEdge = {
  __typename?: 'MarketplaceEdge';
  cursor: Scalars['String'];
  node?: Maybe<Marketplace>;
};

export type MarketplacePoint = {
  __typename?: 'MarketplacePoint';
  geometry?: Maybe<FeatureGeometry>;
  id?: Maybe<Scalars['ObjectId']>;
  properties?: Maybe<FeatureProperties>;
  type?: Maybe<Scalars['String']>;
};

export type MarketplaceUserCounters = {
  __typename?: 'MarketplaceUserCounters';
  published?: Maybe<Scalars['Int']>;
  rejected?: Maybe<Scalars['Int']>;
  unpublished?: Maybe<Scalars['Int']>;
};

export type MarketplaceUserWithCount = {
  __typename?: 'MarketplaceUserWithCount';
  counters?: Maybe<MarketplaceUserCounters>;
  marketplace?: Maybe<Array<Maybe<Marketplace>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MarketplaceViews = {
  __typename?: 'MarketplaceViews';
  date?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['Int']>;
};

export type MarketplacesOffersWithCount = {
  __typename?: 'MarketplacesOffersWithCount';
  marketsOffers?: Maybe<Array<Maybe<MarketsOffers>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type MarketsOffers = Marketplace | Offer;

export type Media = Image | Video;

export type MediaType = {
  __typename?: 'MediaType';
  image?: Maybe<Scalars['Int']>;
  video?: Maybe<Scalars['Int']>;
};

export type Message = {
  __typename?: 'Message';
  creationDate?: Maybe<Scalars['Date']>;
  dialogId?: Maybe<Scalars['ObjectId']>;
  id?: Maybe<Scalars['ObjectId']>;
  images?: Maybe<Array<Maybe<Image>>>;
  message?: Maybe<Scalars['String']>;
  postInfo?: Maybe<PostInfoMessage>;
  sender?: Maybe<Author>;
  status?: Maybe<Scalars['String']>;
};

export type MessageEdge = {
  __typename?: 'MessageEdge';
  cursor: Scalars['String'];
  node?: Maybe<Message>;
};

export type MinOffer = {
  __typename?: 'MinOffer';
  author?: Maybe<Author>;
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type Modification = {
  __typename?: 'Modification';
  barcode?: Maybe<Array<Maybe<Scalars['String']>>>;
  creationDate?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['ObjectId']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  mpn?: Maybe<Array<Maybe<Scalars['String']>>>;
  parameters?: Maybe<Array<Maybe<ProfileParamsValue>>>;
  profileId?: Maybe<Scalars['ObjectId']>;
  title?: Maybe<Scalars['String']>;
};

export type ModificationInput = {
  barcode?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  mpn?: Maybe<Array<Maybe<Scalars['String']>>>;
  parameters?: Maybe<Array<Maybe<ProfileParameterInput>>>;
  title?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  addImage?: Maybe<Scalars['String']>;
  addMarketplaceImage?: Maybe<Scalars['String']>;
  addMessageImage?: Maybe<Scalars['String']>;
  addPostImage?: Maybe<Scalars['String']>;
  addProfileImage?: Maybe<Scalars['String']>;
  addShopImage?: Maybe<Scalars['String']>;
  addToCollection?: Maybe<Collection>;
  createCategory?: Maybe<OkMessage>;
  createCollection?: Maybe<Collection>;
  createComment: CommentEdge;
  createMarketplace?: Maybe<Marketplace>;
  createMessage?: Maybe<Message>;
  createModification?: Maybe<OkMessage>;
  createPost: Post;
  createProfile?: Maybe<OkMessage>;
  createProfileLink?: Maybe<OkMessage>;
  createReply?: Maybe<ReplyEdge>;
  createShop?: Maybe<Shop>;
  createShopSource?: Maybe<Shop>;
  createShopStrategy?: Maybe<Shop>;
  createUnit?: Maybe<Unit>;
  deleteCollection?: Maybe<OkMessage>;
  deleteComment?: Maybe<DeletedComment>;
  deleteMarketplace?: Maybe<OkMessage>;
  deletePhoto?: Maybe<OkMessage>;
  deletePost?: Maybe<Post>;
  deleteReply?: Maybe<OkMessage>;
  deleteShopSource?: Maybe<OkMessage>;
  deleteShopStrategy?: Maybe<OkMessage>;
  likePost?: Maybe<Post>;
  moderateCategory?: Maybe<OkMessage>;
  moderateProfile?: Maybe<OkMessage>;
  removeFromCollection?: Maybe<OkMessage>;
  setQualityFixed: Quality;
  toggleAnswer?: Maybe<PostComment>;
  toggleBlockDialog?: Maybe<Dialog>;
  toggleBookmark?: Maybe<OkMessage>;
  toggleMarketplacePublish?: Maybe<OkMessage>;
  toggleShopStrategyStatus?: Maybe<Shop>;
  toggleSubscribe?: Maybe<Profile>;
  updateCategory?: Maybe<OkMessage>;
  updateCollection?: Maybe<Collection>;
  updateComment?: Maybe<Comment>;
  updateMarketplace?: Maybe<OkMessage>;
  updateOffer?: Maybe<Offer>;
  updatePost?: Maybe<Post>;
  updateProfile?: Maybe<OkMessage>;
  updateReply?: Maybe<Reply>;
  updateShop?: Maybe<Shop>;
  updateShopStrategy?: Maybe<Shop>;
  updateUser?: Maybe<User>;
  updateUserAttribute?: Maybe<ConfirmationCodeResponse>;
  uploadPhoto?: Maybe<Scalars['String']>;
  voteForQuiz?: Maybe<Post>;
};


export type MutationAddImageArgs = {
  id: Scalars['ID'];
  image: Scalars['String'];
};


export type MutationAddMarketplaceImageArgs = {
  image?: Maybe<Scalars['String']>;
};


export type MutationAddMessageImageArgs = {
  image: Scalars['String'];
};


export type MutationAddPostImageArgs = {
  image: Scalars['String'];
};


export type MutationAddProfileImageArgs = {
  image: Scalars['String'];
};


export type MutationAddShopImageArgs = {
  image: Scalars['String'];
  shopId: Scalars['ID'];
};


export type MutationAddToCollectionArgs = {
  collectionId: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parameterGroups?: Maybe<Array<Maybe<ParametersGroupInput>>>;
  redirectId?: Maybe<Scalars['ID']>;
  sort?: Maybe<Scalars['Int']>;
};


export type MutationCreateCollectionArgs = {
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreateCommentArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationCreateMarketplaceArgs = {
  categoryId?: Maybe<Scalars['ID']>;
  condition?: Maybe<Scalars['String']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  country: Scalars['String'];
  currency?: Maybe<Scalars['String']>;
  delivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  hidePhone?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Maybe<Scalars['String']>>>;
  modificationId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  tradeType?: Maybe<Scalars['String']>;
};


export type MutationCreateMessageArgs = {
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  message: Scalars['String'];
  participant?: Maybe<Scalars['String']>;
  postId?: Maybe<Scalars['ID']>;
};


export type MutationCreateModificationArgs = {
  barcode?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Scalars['String']>;
  mpn?: Maybe<Array<Maybe<Scalars['String']>>>;
  parameters?: Maybe<Array<Maybe<ProfileParameterInput>>>;
  title: Scalars['String'];
};


export type MutationCreatePostArgs = {
  id: Scalars['ID'];
  marketplace?: Maybe<MarketInput>;
  media?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  qa?: Maybe<Scalars['String']>;
  quiz?: Maybe<QuizInput>;
  rating?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};


export type MutationCreateProfileArgs = {
  barcode?: Maybe<Array<Maybe<Scalars['String']>>>;
  brand?: Maybe<Scalars['String']>;
  bulletPoints?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modifications?: Maybe<Array<Maybe<ModificationInput>>>;
  mpn?: Maybe<Array<Maybe<Scalars['String']>>>;
  parameters?: Maybe<Array<Maybe<ProfileParameterInput>>>;
  shortDescription?: Maybe<Scalars['String']>;
  shortSummary?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type MutationCreateProfileLinkArgs = {
  link: Scalars['String'];
};


export type MutationCreateReplyArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
  reply?: Maybe<Scalars['ID']>;
};


export type MutationCreateShopArgs = {
  address: Scalars['String'];
  companyName: Scalars['String'];
  name: Scalars['String'];
  openingHours?: Maybe<Array<Maybe<OpeningHoursInput>>>;
  personEmail: Scalars['String'];
  personName: Scalars['String'];
  personPhone: Scalars['String'];
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  taxNumber: Scalars['String'];
  website: Scalars['String'];
};


export type MutationCreateShopSourceArgs = {
  file?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  shopId: Scalars['ID'];
  url?: Maybe<Scalars['String']>;
};


export type MutationCreateShopStrategyArgs = {
  model: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  rate: Scalars['Float'];
  restrictions?: Maybe<Array<Maybe<RestrictionInput>>>;
  shopId: Scalars['ID'];
};


export type MutationCreateUnitArgs = {
  description?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
};


export type MutationDeleteCollectionArgs = {
  collectionId: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketplaceArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReplyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteShopSourceArgs = {
  shopId: Scalars['ID'];
  sourceId: Scalars['ID'];
};


export type MutationDeleteShopStrategyArgs = {
  shopId: Scalars['ID'];
  strategyId: Scalars['ID'];
};


export type MutationLikePostArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
};


export type MutationModerateCategoryArgs = {
  id: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationModerateProfileArgs = {
  id: Scalars['ID'];
  status: Scalars['String'];
};


export type MutationRemoveFromCollectionArgs = {
  collectionId: Scalars['ID'];
  profileId: Scalars['ID'];
};


export type MutationSetQualityFixedArgs = {
  qualityId: Scalars['ID'];
  shopId: Scalars['ID'];
};


export type MutationToggleAnswerArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
};


export type MutationToggleBlockDialogArgs = {
  id: Scalars['ID'];
};


export type MutationToggleBookmarkArgs = {
  id: Scalars['ID'];
};


export type MutationToggleMarketplacePublishArgs = {
  id: Scalars['ID'];
};


export type MutationToggleShopStrategyStatusArgs = {
  shopId: Scalars['ID'];
  strategyId: Scalars['ID'];
};


export type MutationToggleSubscribeArgs = {
  lang?: Maybe<Scalars['String']>;
  profileId: Scalars['ID'];
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parameterGroups?: Maybe<Array<Maybe<ParametersGroupInput>>>;
  parentIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MutationUpdateCollectionArgs = {
  collectionId: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type MutationUpdateCommentArgs = {
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};


export type MutationUpdateMarketplaceArgs = {
  categoryId?: Maybe<Scalars['ID']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  delivery?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  hidePhone?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  location?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Maybe<Scalars['String']>>>;
  modificationId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  profileId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  tradeType?: Maybe<Scalars['String']>;
};


export type MutationUpdateOfferArgs = {
  isDeactivate: Scalars['Boolean'];
  offerId: Scalars['ID'];
  productId?: Maybe<Scalars['ObjectId']>;
  shopId: Scalars['ID'];
};


export type MutationUpdatePostArgs = {
  id: Scalars['ID'];
  update?: Maybe<Update>;
};


export type MutationUpdateProfileArgs = {
  approved?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  modifications?: Maybe<Array<Maybe<ModificationInput>>>;
  parameters?: Maybe<Array<Maybe<ProfileParameterInput>>>;
  title: Scalars['String'];
};


export type MutationUpdateReplyArgs = {
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};


export type MutationUpdateShopArgs = {
  address: Scalars['String'];
  companyName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  openingHours?: Maybe<Array<Maybe<OpeningHoursInput>>>;
  personEmail: Scalars['String'];
  personName: Scalars['String'];
  personPhone: Scalars['String'];
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  taxNumber: Scalars['String'];
  website: Scalars['String'];
};


export type MutationUpdateShopStrategyArgs = {
  model: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  rate: Scalars['Float'];
  restrictions?: Maybe<Array<Maybe<RestrictionInput>>>;
  shopId: Scalars['ID'];
  strategyId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  about?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  previousPassword?: Maybe<Scalars['String']>;
  proposedPassword?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  vkontakte?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type MutationUpdateUserAttributeArgs = {
  login?: Maybe<Scalars['String']>;
};


export type MutationUploadPhotoArgs = {
  picture: Scalars['String'];
};


export type MutationVoteForQuizArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  optionId: Scalars['ID'];
};

export type MyComment = {
  __typename?: 'MyComment';
  author?: Maybe<Author>;
  creationDate?: Maybe<Scalars['Date']>;
  id: Scalars['ObjectId'];
  message?: Maybe<Scalars['String']>;
  post?: Maybe<Post>;
  profileId?: Maybe<Scalars['ObjectId']>;
  profileName?: Maybe<Scalars['String']>;
};

export type NumberFilter = {
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type NumberValues = {
  __typename?: 'NumberValues';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  unit?: Maybe<Unit>;
};

export type Offer = {
  __typename?: 'Offer';
  active: Scalars['Boolean'];
  availability: Scalars['String'];
  availabilityDate?: Maybe<Scalars['Date']>;
  createdAt?: Maybe<Scalars['Date']>;
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  image: Scalars['String'];
  isDeactivate: Scalars['Boolean'];
  price: Scalars['Float'];
  product?: Maybe<Product>;
  shopId: Scalars['ObjectId'];
  shopName?: Maybe<Scalars['String']>;
  shopSKU: Scalars['String'];
  sourceId: Scalars['ObjectId'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  url: Scalars['String'];
};

export type Offers = {
  __typename?: 'Offers';
  nodes?: Maybe<Array<Maybe<Offer>>>;
  total?: Maybe<Scalars['Int']>;
};

export type OkMessage = {
  __typename?: 'OkMessage';
  id?: Maybe<Scalars['ID']>;
  message?: Maybe<Scalars['String']>;
};

export type OpeningHours = {
  __typename?: 'OpeningHours';
  breakFrom?: Maybe<Scalars['String']>;
  breakTo?: Maybe<Scalars['String']>;
  daysOfWeek: Scalars['String'];
  workFrom: Scalars['String'];
  workTo: Scalars['String'];
};

export type OpeningHoursInput = {
  breakFrom?: Maybe<Scalars['String']>;
  breakTo?: Maybe<Scalars['String']>;
  daysOfWeek: Scalars['String'];
  workFrom: Scalars['String'];
  workTo: Scalars['String'];
};

export type OtherUserInfo = {
  __typename?: 'OtherUserInfo';
  about?: Maybe<Scalars['String']>;
  counters?: Maybe<UserCounters>;
  facebook?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  rewards?: Maybe<Array<Maybe<Reward>>>;
  twitter?: Maybe<Scalars['String']>;
  vkontakte?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Parameter = {
  __typename?: 'Parameter';
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ObjectId']>;
  inVariant?: Maybe<Scalars['Boolean']>;
  isFilter?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parameterType?: Maybe<Scalars['String']>;
  unit?: Maybe<Unit>;
};

export type ParameterInput = {
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ID']>;
  inVariant?: Maybe<Scalars['Boolean']>;
  isFilter?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parameterType?: Maybe<Scalars['String']>;
  unit?: Maybe<UnitInput>;
};

export type ParameterValue = {
  __typename?: 'ParameterValue';
  Boolean?: Maybe<Scalars['Boolean']>;
  Group?: Maybe<Array<Maybe<GroupParameterValue>>>;
  Number?: Maybe<Scalars['Float']>;
  String?: Maybe<Array<Maybe<ProfileStringValue>>>;
  StringColor?: Maybe<StringColorValue>;
};

export type ParameterValueCreate = {
  Boolean?: Maybe<Scalars['Boolean']>;
  Group?: Maybe<Array<Maybe<GroupParameterInput>>>;
  Number?: Maybe<NumberFilter>;
  String?: Maybe<Array<Maybe<Scalars['String']>>>;
  StringColor?: Maybe<Array<Maybe<StringColorInput>>>;
};

export type ParameterValueInput = {
  Boolean?: Maybe<Scalars['Boolean']>;
  Group?: Maybe<Array<Maybe<GroupParameterInput>>>;
  Number?: Maybe<Scalars['Float']>;
  String?: Maybe<Array<Maybe<StringInput>>>;
  StringColor?: Maybe<Array<Maybe<StringColorInput>>>;
};

export type ParametersGroupInput = {
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  params?: Maybe<Array<Maybe<ParameterInput>>>;
};

export type PopularCategories = {
  __typename?: 'PopularCategories';
  count?: Maybe<Scalars['Int']>;
  id: Scalars['ObjectId'];
  name?: Maybe<Scalars['String']>;
  parentIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  slug?: Maybe<Scalars['String']>;
};

export type Post = {
  __typename?: 'Post';
  author?: Maybe<Author>;
  comments?: Maybe<Scalars['Int']>;
  creationDate?: Maybe<Scalars['Date']>;
  id: Scalars['ObjectId'];
  isLiked?: Maybe<Scalars['Boolean']>;
  likes?: Maybe<Scalars['Int']>;
  market?: Maybe<Market>;
  media?: Maybe<Array<Maybe<Media>>>;
  message?: Maybe<Scalars['String']>;
  originalMessage?: Maybe<Scalars['String']>;
  postLanguage?: Maybe<Scalars['String']>;
  profileId?: Maybe<Scalars['ObjectId']>;
  profileName?: Maybe<Scalars['String']>;
  qa?: Maybe<Qa>;
  quiz?: Maybe<Quiz>;
  rating?: Maybe<Scalars['Int']>;
};

export type PostComment = {
  __typename?: 'PostComment';
  comment?: Maybe<Comment>;
  post?: Maybe<Post>;
};

export type PostCounters = {
  __typename?: 'PostCounters';
  image?: Maybe<Scalars['Int']>;
  marketplace?: Maybe<Scalars['Int']>;
  qa?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
  video?: Maybe<Scalars['Int']>;
};

export type PostEdge = {
  __typename?: 'PostEdge';
  cursor: Scalars['String'];
  node?: Maybe<Post>;
};

export type PostInfoMessage = {
  __typename?: 'PostInfoMessage';
  currency?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  imageId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type PostLangs = {
  __typename?: 'PostLangs';
  count?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
};

export type PostTypes = {
  __typename?: 'PostTypes';
  marketplace?: Maybe<Scalars['Int']>;
  media?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['Int']>;
};

export type PostWithCount = {
  __typename?: 'PostWithCount';
  count?: Maybe<Scalars['Int']>;
  posts?: Maybe<Array<Maybe<Post>>>;
};

export type PriceType = {
  __typename?: 'PriceType';
  currency?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Product = {
  __typename?: 'Product';
  categoryName?: Maybe<Scalars['String']>;
  defaultPicture?: Maybe<Scalars['String']>;
  productId?: Maybe<Scalars['ObjectId']>;
  title?: Maybe<Scalars['String']>;
};

export type ProductAveragePrice = {
  __typename?: 'ProductAveragePrice';
  count?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
};

export type ProductSearch = {
  __typename?: 'ProductSearch';
  avgPrice?: Maybe<ProductAveragePrice>;
  categoryName?: Maybe<Scalars['String']>;
  defaultPicture?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  modifications?: Maybe<Array<Maybe<Modification>>>;
  title?: Maybe<Scalars['String']>;
};

export type Profile = {
  __typename?: 'Profile';
  authorId?: Maybe<Scalars['String']>;
  barcode?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<PopularCategories>>>;
  countries?: Maybe<Array<Maybe<Scalars['String']>>>;
  creationDate?: Maybe<Scalars['Date']>;
  defaultPicture?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  imagesCount?: Maybe<Scalars['Int']>;
  isSubscribed?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  marketplaceCount?: Maybe<Scalars['Int']>;
  media?: Maybe<Scalars['Int']>;
  minOffer?: Maybe<MinOffer>;
  model?: Maybe<Scalars['String']>;
  modifications?: Maybe<Array<Maybe<Modification>>>;
  mpn?: Maybe<Array<Maybe<Scalars['String']>>>;
  parameters?: Maybe<Array<Maybe<ProfileParameters>>>;
  popularity?: Maybe<Scalars['Int']>;
  postCounters?: Maybe<PostCounters>;
  postLangs?: Maybe<Array<Maybe<PostLangs>>>;
  rating?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  youtubeVideos?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProfileCategories = {
  __typename?: 'ProfileCategories';
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
};

export type ProfileEdge = {
  __typename?: 'ProfileEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Profile>;
};

export type ProfileForSearch = {
  __typename?: 'ProfileForSearch';
  authorId?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<PopularCategories>>>;
  creationDate?: Maybe<Scalars['Date']>;
  defaultPicture?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  marketplaceCount?: Maybe<Scalars['Int']>;
  minOffer?: Maybe<MinOffer>;
  popularity?: Maybe<Scalars['Int']>;
  postCounters?: Maybe<PostCounters>;
  rating?: Maybe<Scalars['Float']>;
  slug?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type ProfileNames = {
  __typename?: 'ProfileNames';
  defaultPicture?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
};

export type ProfileParameter = {
  id?: Maybe<Scalars['ID']>;
  parameterType?: Maybe<Scalars['String']>;
  values?: Maybe<ParameterValueCreate>;
};

export type ProfileParameterGroups = {
  __typename?: 'ProfileParameterGroups';
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  params?: Maybe<Array<Maybe<ProfileParamsValue>>>;
};

export type ProfileParameterInput = {
  id?: Maybe<Scalars['ID']>;
  value?: Maybe<ParameterValueInput>;
};

export type ProfileParameters = {
  __typename?: 'ProfileParameters';
  categoryId?: Maybe<Scalars['ObjectId']>;
  categoryName?: Maybe<Scalars['String']>;
  parameterGroups?: Maybe<Array<Maybe<ProfileParameterGroups>>>;
};

export type ProfileParamsValue = {
  __typename?: 'ProfileParamsValue';
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Scalars['ObjectId']>;
  inVariant?: Maybe<Scalars['Boolean']>;
  isFilter?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  parameterType?: Maybe<Scalars['String']>;
  unit?: Maybe<Unit>;
  value?: Maybe<ParameterValue>;
};

export type ProfileStringValue = {
  __typename?: 'ProfileStringValue';
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['String']>;
};

export type ProfileWithCount = {
  __typename?: 'ProfileWithCount';
  categories?: Maybe<Array<Maybe<PopularCategories>>>;
  profiles?: Maybe<Array<Maybe<ProfileForSearch>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type Qa = {
  __typename?: 'QA';
  answer?: Maybe<Comment>;
  question?: Maybe<Scalars['String']>;
};

export type Quality = {
  __typename?: 'Quality';
  active: Scalars['Boolean'];
  error: Scalars['String'];
  errorRelated?: Maybe<Array<Maybe<ErorrRelated>>>;
  id: Scalars['ID'];
  isFixed: Scalars['Boolean'];
  number?: Maybe<Scalars['Int']>;
  shopId: Scalars['ObjectId'];
  sourceId: Scalars['ObjectId'];
  type: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  allCategories?: Maybe<Array<Maybe<PopularCategories>>>;
  categories?: Maybe<Array<Maybe<PopularCategories>>>;
  category?: Maybe<Category>;
  categoryFilters?: Maybe<Array<Maybe<CategoryFilters>>>;
  categoryVersion?: Maybe<Scalars['String']>;
  collections?: Maybe<Array<Maybe<Collection>>>;
  comments?: Maybe<DocumentComment>;
  confirm?: Maybe<UserWithToken>;
  confirmUserAttribute?: Maybe<OkMessage>;
  dialogs?: Maybe<DocumentDialog>;
  filters?: Maybe<Filters>;
  forgot?: Maybe<ConfirmationCodeResponse>;
  forgotConfirm?: Maybe<OkMessage>;
  getLocation?: Maybe<Location>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  locationAutocomplete?: Maybe<Array<Maybe<Autocomplete>>>;
  login?: Maybe<UserWithToken>;
  marketplace?: Maybe<Marketplace>;
  marketplaceList?: Maybe<MarketlaceWithCount>;
  marketplacePoints?: Maybe<Array<Maybe<MarketplacePoint>>>;
  marketplaceRegisterUser?: Maybe<UserRegisterResponse>;
  marketplaceRelated?: Maybe<MarketlaceWithCount>;
  marketplaceSearchCategory?: Maybe<Array<Maybe<MarketplaceCategorySearch>>>;
  marketplaceSearchProduct?: Maybe<Array<Maybe<ProductSearch>>>;
  marketplaceUser?: Maybe<MarketplaceUserWithCount>;
  marketplaceUserFilters?: Maybe<UserMarketplaceFiltes>;
  messages?: Maybe<DocumentMessage>;
  modifications?: Maybe<Array<Maybe<Modification>>>;
  myBookmarks?: Maybe<BookmarkWithCount>;
  myComments?: Maybe<CommentWithCount>;
  myPosts?: Maybe<PostWithCount>;
  offer?: Maybe<Offer>;
  offers?: Maybe<Offers>;
  otherUserInfo?: Maybe<OtherUserInfo>;
  parameterValues?: Maybe<Array<Maybe<Scalars['String']>>>;
  parentChildrenCategories?: Maybe<RelatedCategories>;
  popularCategories?: Maybe<Array<Maybe<PopularCategories>>>;
  post?: Maybe<Post>;
  posts?: Maybe<DocumentPost>;
  productOffers?: Maybe<MarketplacesOffersWithCount>;
  profile?: Maybe<Profile>;
  profileMedia?: Maybe<Array<Maybe<Media>>>;
  profileNames?: Maybe<Array<Maybe<ProfileNames>>>;
  profiles?: Maybe<ProfileWithCount>;
  profilesById?: Maybe<Array<Maybe<Profile>>>;
  profilesMap?: Maybe<DocumentProfile>;
  qualities: Array<Maybe<Quality>>;
  recentActivity?: Maybe<Array<Maybe<RecentActivityData>>>;
  register?: Maybe<UserRegisterResponse>;
  replies?: Maybe<DocumetnReply>;
  resendConfirm?: Maybe<ConfirmationCodeResponse>;
  resendConfirmUserAttribute?: Maybe<ConfirmationCodeResponse>;
  rewards?: Maybe<Array<Maybe<Reward>>>;
  search?: Maybe<Array<Maybe<MainSearch>>>;
  shop?: Maybe<Shop>;
  shops?: Maybe<Array<Maybe<Shop>>>;
  units?: Maybe<Array<Maybe<Unit>>>;
  userInfo?: Maybe<User>;
  userPhoneImage?: Maybe<Scalars['String']>;
  userProducts?: Maybe<Array<Maybe<UserProduct>>>;
  userRegion?: Maybe<UserRegion>;
};


export type QueryAllCategoriesArgs = {
  lang?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  view?: Maybe<Scalars['String']>;
};


export type QueryCategoriesArgs = {
  categories?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryCategoryArgs = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryCategoryFiltersArgs = {
  brand?: Maybe<Array<Maybe<Scalars['String']>>>;
  country?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<ProfileParameter>>>;
  id: Scalars['ID'];
  inStock?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  location?: Maybe<LocationFilter>;
  price?: Maybe<NumberFilter>;
  search?: Maybe<Scalars['String']>;
};


export type QueryCategoryVersionArgs = {
  id: Scalars['ID'];
};


export type QueryCollectionsArgs = {
  lang?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};


export type QueryCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: Maybe<Scalars['Int']>;
};


export type QueryConfirmArgs = {
  code?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
};


export type QueryConfirmUserAttributeArgs = {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryDialogsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: Maybe<Scalars['Int']>;
};


export type QueryFiltersArgs = {
  filter?: Maybe<Filter>;
  id: Scalars['ID'];
  userActivity?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  userSubscriptions?: Maybe<Scalars['String']>;
};


export type QueryForgotArgs = {
  login?: Maybe<Scalars['String']>;
};


export type QueryForgotConfirmArgs = {
  code?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
};


export type QueryGetLocationArgs = {
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};


export type QueryImagesArgs = {
  id: Scalars['ID'];
};


export type QueryLocationAutocompleteArgs = {
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  search: Scalars['String'];
};


export type QueryLoginArgs = {
  login?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceListArgs = {
  categoryId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<ProfileParameter>>>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<LocationFilter>;
  offset?: Maybe<Scalars['Int']>;
  price?: Maybe<NumberFilter>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryMarketplacePointsArgs = {
  bounds?: Maybe<Array<Maybe<Scalars['Float']>>>;
  categoryId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<ProfileParameter>>>;
  lang?: Maybe<Scalars['String']>;
  price?: Maybe<NumberFilter>;
  search?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceRegisterUserArgs = {
  email?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pass?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceRelatedArgs = {
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryMarketplaceSearchCategoryArgs = {
  lang?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceSearchProductArgs = {
  currency?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceUserArgs = {
  category?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryMarketplaceUserFiltersArgs = {
  lang?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};


export type QueryMessagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: Maybe<Scalars['Int']>;
};


export type QueryModificationsArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryMyBookmarksArgs = {
  filter?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryMyCommentsArgs = {
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryMyPostsArgs = {
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryOfferArgs = {
  offerId: Scalars['ID'];
  shopId: Scalars['ID'];
};


export type QueryOffersArgs = {
  categories?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  shopId: Scalars['ID'];
  status?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};


export type QueryOtherUserInfoArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryParameterValuesArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryParentChildrenCategoriesArgs = {
  category?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryPopularCategoriesArgs = {
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryPostArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryPostsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  filter?: Maybe<Filter>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
};


export type QueryProductOffersArgs = {
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  price?: Maybe<NumberFilter>;
  productId?: Maybe<Scalars['ID']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryProfileArgs = {
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
};


export type QueryProfileMediaArgs = {
  id: Scalars['ID'];
};


export type QueryProfileNamesArgs = {
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  search: Scalars['String'];
};


export type QueryProfilesArgs = {
  brand?: Maybe<Array<Maybe<Scalars['String']>>>;
  category?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<ProfileParameter>>>;
  inStock?: Maybe<Scalars['Boolean']>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<LocationFilter>;
  offset?: Maybe<Scalars['Int']>;
  price?: Maybe<NumberFilter>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryProfilesByIdArgs = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  lang?: Maybe<Scalars['String']>;
};


export type QueryProfilesMapArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  bounds?: Maybe<Array<Maybe<Scalars['Float']>>>;
  categoryId?: Maybe<Scalars['ID']>;
  country?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<ProfileParameter>>>;
  first?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  price?: Maybe<NumberFilter>;
  search?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
};


export type QueryQualitiesArgs = {
  active?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  shopId: Scalars['ID'];
};


export type QueryRecentActivityArgs = {
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRegisterArgs = {
  agree?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
};


export type QueryRepliesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  last?: Maybe<Scalars['Int']>;
};


export type QueryResendConfirmArgs = {
  login?: Maybe<Scalars['String']>;
};


export type QueryResendConfirmUserAttributeArgs = {
  name?: Maybe<Scalars['String']>;
};


export type QueryRewardsArgs = {
  userId?: Maybe<Scalars['String']>;
};


export type QuerySearchArgs = {
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryShopArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryUnitsArgs = {
  lang?: Maybe<Scalars['String']>;
};


export type QueryUserInfoArgs = {
  userId?: Maybe<Scalars['ID']>;
};


export type QueryUserPhoneImageArgs = {
  marketplaceId: Scalars['String'];
};


export type QueryUserProductsArgs = {
  lang?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
};

export type Quiz = {
  __typename?: 'Quiz';
  isVoted?: Maybe<Scalars['Boolean']>;
  multiple?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<QuizOptions>>>;
  topic?: Maybe<Scalars['String']>;
};

export type QuizInput = {
  multiple?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<Maybe<Scalars['String']>>>;
  topic?: Maybe<Scalars['String']>;
};

export type QuizOptions = {
  __typename?: 'QuizOptions';
  id?: Maybe<Scalars['ObjectId']>;
  lang?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['Float']>;
  userVote?: Maybe<Scalars['Boolean']>;
  votes?: Maybe<Scalars['Int']>;
};

export type RatingsType = {
  __typename?: 'RatingsType';
  five?: Maybe<Scalars['Int']>;
  four?: Maybe<Scalars['Int']>;
  one?: Maybe<Scalars['Int']>;
  three?: Maybe<Scalars['Int']>;
  two?: Maybe<Scalars['Int']>;
};

export type RecentActivityData = Comment | Marketplace | Post | ProfileForSearch;

export type RelatedCategories = {
  __typename?: 'RelatedCategories';
  children?: Maybe<Array<Maybe<PopularCategories>>>;
  count?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  parents?: Maybe<Array<Maybe<PopularCategories>>>;
};

export type Reply = {
  __typename?: 'Reply';
  author?: Maybe<Author>;
  creationDate?: Maybe<Scalars['Date']>;
  id: Scalars['ObjectId'];
  message?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ObjectId']>;
  replyTo?: Maybe<Scalars['String']>;
};

export type ReplyEdge = {
  __typename?: 'ReplyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Reply>;
};

export type Restriction = {
  __typename?: 'Restriction';
  categories?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<Maybe<Scalars['ObjectId']>>>;
};

export type RestrictionInput = {
  categories?: Maybe<Array<Maybe<Scalars['ID']>>>;
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
  products?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Reward = {
  __typename?: 'Reward';
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pointScale?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['Int']>;
};

export type Shop = {
  __typename?: 'Shop';
  address?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  companyName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  isLimitExpenses?: Maybe<Scalars['Boolean']>;
  limitExpensesValue?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  openingHours?: Maybe<Array<Maybe<OpeningHours>>>;
  personEmail?: Maybe<Scalars['String']>;
  personName?: Maybe<Scalars['String']>;
  personPhone?: Maybe<Scalars['String']>;
  photos?: Maybe<Array<Maybe<Scalars['String']>>>;
  sources?: Maybe<Array<Maybe<Source>>>;
  status?: Maybe<Scalars['Boolean']>;
  strategies?: Maybe<Array<Maybe<Strategy>>>;
  taxNumber?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['ID']>;
  website?: Maybe<Scalars['String']>;
};

export type Source = {
  __typename?: 'Source';
  count?: Maybe<Scalars['Int']>;
  file?: Maybe<Scalars['String']>;
  id: Scalars['ObjectId'];
  name: Scalars['String'];
  type: Scalars['String'];
  updatedAt: Scalars['Date'];
  url?: Maybe<Scalars['String']>;
};

export type Strategy = {
  __typename?: 'Strategy';
  id: Scalars['ObjectId'];
  model: Scalars['String'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  rate: Scalars['Float'];
  restrictions?: Maybe<Array<Maybe<Restriction>>>;
  status: Scalars['Boolean'];
};

export type StringColorInput = {
  color?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['ID']>;
};

export type StringColorValue = {
  __typename?: 'StringColorValue';
  color?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type StringColorValues = {
  __typename?: 'StringColorValues';
  color?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
  photo?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['ID']>;
};

export type StringInput = {
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['ID']>;
};

export type StringValues = {
  __typename?: 'StringValues';
  count?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
  valueId?: Maybe<Scalars['ID']>;
};

export type Success = {
  __typename?: 'Success';
  message?: Maybe<Scalars['String']>;
};

export type Tokens = {
  __typename?: 'Tokens';
  AccessToken?: Maybe<Scalars['String']>;
  ExpiresIn?: Maybe<Scalars['Float']>;
  IdToken?: Maybe<Scalars['String']>;
  RefreshToken?: Maybe<Scalars['String']>;
  TokenType?: Maybe<Scalars['String']>;
};

export type TradeTypes = {
  __typename?: 'TradeTypes';
  buy?: Maybe<Scalars['Int']>;
  sell?: Maybe<Scalars['Int']>;
};

export type Unit = {
  __typename?: 'Unit';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  name?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
};

export type UnitInput = {
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  sign?: Maybe<Scalars['String']>;
};

export type Update = {
  marketplace?: Maybe<MarketInput>;
  media?: Maybe<Array<Maybe<Scalars['String']>>>;
  message?: Maybe<Scalars['String']>;
  postLanguage?: Maybe<Scalars['String']>;
  qa?: Maybe<Scalars['String']>;
  quiz?: Maybe<QuizInput>;
  rating?: Maybe<Scalars['Int']>;
};

export type User = {
  __typename?: 'User';
  about?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  facebook?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phone_verified?: Maybe<Scalars['Boolean']>;
  twitter?: Maybe<Scalars['String']>;
  unreadMessages?: Maybe<Scalars['Int']>;
  vkontakte?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

export type UserCity = {
  __typename?: 'UserCity';
  coords?: Maybe<Array<Maybe<Scalars['Float']>>>;
  name?: Maybe<Scalars['String']>;
  postal?: Maybe<Scalars['String']>;
};

export type UserCounters = {
  __typename?: 'UserCounters';
  collections?: Maybe<Scalars['Int']>;
  marketplace?: Maybe<Scalars['Int']>;
  products?: Maybe<Scalars['Int']>;
};

export type UserMarketplaceFiltes = {
  __typename?: 'UserMarketplaceFiltes';
  categories?: Maybe<Array<Maybe<FilterCount>>>;
  locations?: Maybe<Array<Maybe<FilterCount>>>;
};

export type UserRegion = {
  __typename?: 'UserRegion';
  city?: Maybe<UserCity>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type UserRegisterResponse = {
  __typename?: 'UserRegisterResponse';
  CodeDeliveryDetails?: Maybe<CodeDeliveryDetailsType>;
  UserConfirmed?: Maybe<Scalars['Boolean']>;
  UserSub?: Maybe<Scalars['String']>;
  Username?: Maybe<Scalars['String']>;
};

export type UserWithToken = {
  __typename?: 'UserWithToken';
  token?: Maybe<Tokens>;
  userInfo?: Maybe<User>;
};

export type Video = {
  __typename?: 'Video';
  video?: Maybe<VideoInfo>;
};

export type VideoInfo = {
  __typename?: 'VideoInfo';
  duration?: Maybe<Scalars['Int']>;
  videoId?: Maybe<Scalars['String']>;
};

export type ErorrRelated = {
  __typename?: 'erorrRelated';
  offerSKU?: Maybe<Scalars['String']>;
  offerTitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ParameterGroups = {
  __typename?: 'parameterGroups';
  groupName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  params?: Maybe<Array<Maybe<Parameter>>>;
};

export type UserProduct = {
  __typename?: 'userProduct';
  creationDate?: Maybe<Scalars['Date']>;
  defaultPicture?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ObjectId']>;
  rating?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type CommentNodeFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
  & { author?: Maybe<(
    { __typename?: 'Author' }
    & Pick<Author, 'id' | 'name'>
  )> }
);

export type PostNodeFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
  & { author?: Maybe<(
    { __typename?: 'Author' }
    & Pick<Author, 'id' | 'name'>
  )>, media?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'image'>
  ) | (
    { __typename?: 'Video' }
    & { video?: Maybe<(
      { __typename?: 'VideoInfo' }
      & Pick<VideoInfo, 'duration' | 'videoId'>
    )> }
  )>>>, market?: Maybe<(
    { __typename?: 'Market' }
    & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
  )>, quiz?: Maybe<(
    { __typename?: 'Quiz' }
    & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
    & { options?: Maybe<Array<Maybe<(
      { __typename?: 'QuizOptions' }
      & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
    )>>> }
  )>, qa?: Maybe<(
    { __typename?: 'QA' }
    & Pick<Qa, 'question'>
    & { answer?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
      & { author?: Maybe<(
        { __typename?: 'Author' }
        & Pick<Author, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UserInfoFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'email_verified' | 'phone' | 'phone_verified' | 'unreadMessages' | 'name' | 'family_name' | 'about' | 'website' | 'facebook' | 'vkontakte' | 'twitter' | 'instagram'>
);

export type CollectionElementFragment = (
  { __typename?: 'Collection' }
  & Pick<Collection, 'id' | 'name' | 'description'>
  & { profiles?: Maybe<Array<Maybe<(
    { __typename?: 'userProduct' }
    & Pick<UserProduct, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating'>
  )>>> }
);

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { userInfo?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'email_verified' | 'phone' | 'phone_verified' | 'unreadMessages' | 'name' | 'family_name' | 'about' | 'website' | 'facebook' | 'vkontakte' | 'twitter' | 'instagram'>
  )> }
);

export type LoginQueryVariables = Exact<{
  login?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
}>;


export type LoginQuery = (
  { __typename?: 'Query' }
  & { login?: Maybe<(
    { __typename?: 'UserWithToken' }
    & { userInfo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'email_verified' | 'phone' | 'phone_verified' | 'unreadMessages' | 'name' | 'family_name' | 'about' | 'website' | 'facebook' | 'vkontakte' | 'twitter' | 'instagram'>
    )>, token?: Maybe<(
      { __typename?: 'Tokens' }
      & Pick<Tokens, 'RefreshToken' | 'AccessToken'>
    )> }
  )> }
);

export type RegisterQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  agree?: Maybe<Scalars['String']>;
}>;


export type RegisterQuery = (
  { __typename?: 'Query' }
  & { register?: Maybe<(
    { __typename?: 'UserRegisterResponse' }
    & Pick<UserRegisterResponse, 'UserConfirmed' | 'UserSub' | 'Username'>
    & { CodeDeliveryDetails?: Maybe<(
      { __typename?: 'CodeDeliveryDetailsType' }
      & Pick<CodeDeliveryDetailsType, 'Destination' | 'DeliveryMedium' | 'AttributeName'>
    )> }
  )> }
);

export type ConfirmUserQueryVariables = Exact<{
  login?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
}>;


export type ConfirmUserQuery = (
  { __typename?: 'Query' }
  & { confirm?: Maybe<(
    { __typename?: 'UserWithToken' }
    & { userInfo?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'email_verified' | 'phone' | 'phone_verified' | 'unreadMessages' | 'name' | 'family_name' | 'about' | 'website' | 'facebook' | 'vkontakte' | 'twitter' | 'instagram'>
    )>, token?: Maybe<(
      { __typename?: 'Tokens' }
      & Pick<Tokens, 'RefreshToken' | 'AccessToken'>
    )> }
  )> }
);

export type ResendConfirmUserQueryVariables = Exact<{
  login?: Maybe<Scalars['String']>;
}>;


export type ResendConfirmUserQuery = (
  { __typename?: 'Query' }
  & { resendConfirm?: Maybe<(
    { __typename?: 'ConfirmationCodeResponse' }
    & { CodeDeliveryDetails?: Maybe<(
      { __typename?: 'CodeDeliveryDetailsType' }
      & Pick<CodeDeliveryDetailsType, 'Destination' | 'DeliveryMedium' | 'AttributeName'>
    )> }
  )> }
);

export type ForgotPasswordQueryVariables = Exact<{
  login?: Maybe<Scalars['String']>;
}>;


export type ForgotPasswordQuery = (
  { __typename?: 'Query' }
  & { forgot?: Maybe<(
    { __typename?: 'ConfirmationCodeResponse' }
    & { CodeDeliveryDetails?: Maybe<(
      { __typename?: 'CodeDeliveryDetailsType' }
      & Pick<CodeDeliveryDetailsType, 'Destination' | 'DeliveryMedium' | 'AttributeName'>
    )> }
  )> }
);

export type ForgotPasswordConfirmQueryVariables = Exact<{
  login?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
}>;


export type ForgotPasswordConfirmQuery = (
  { __typename?: 'Query' }
  & { forgotConfirm?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type AddCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
}>;


export type AddCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment: (
    { __typename?: 'CommentEdge' }
    & Pick<CommentEdge, 'cursor'>
    & { node?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
      & { author?: Maybe<(
        { __typename?: 'Author' }
        & Pick<Author, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment?: Maybe<(
    { __typename?: 'DeletedComment' }
    & Pick<DeletedComment, 'id'>
  )> }
);

export type EditCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
}>;


export type EditCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment?: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )> }
  )> }
);

export type GetCommentsQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
}>;


export type GetCommentsQuery = (
  { __typename?: 'Query' }
  & { comments?: Maybe<(
    { __typename?: 'DocumentComment' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'CommentEdge' }
      & Pick<CommentEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type AddReplyMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
  reply?: Maybe<Scalars['ID']>;
}>;


export type AddReplyMutation = (
  { __typename?: 'Mutation' }
  & { createReply?: Maybe<(
    { __typename?: 'ReplyEdge' }
    & Pick<ReplyEdge, 'cursor'>
    & { node?: Maybe<(
      { __typename?: 'Reply' }
      & Pick<Reply, 'id' | 'parentId' | 'creationDate' | 'message' | 'replyTo'>
      & { author?: Maybe<(
        { __typename?: 'Author' }
        & Pick<Author, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type EditReplyMutationVariables = Exact<{
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
}>;


export type EditReplyMutation = (
  { __typename?: 'Mutation' }
  & { updateReply?: Maybe<(
    { __typename?: 'Reply' }
    & Pick<Reply, 'id' | 'parentId' | 'creationDate' | 'message' | 'replyTo'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'name' | 'id'>
    )> }
  )> }
);

export type DeleteReplyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteReplyMutation = (
  { __typename?: 'Mutation' }
  & { deleteReply?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type GetRepliesQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
}>;


export type GetRepliesQuery = (
  { __typename?: 'Query' }
  & { replies?: Maybe<(
    { __typename?: 'DocumetnReply' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ReplyEdge' }
      & Pick<ReplyEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Reply' }
        & Pick<Reply, 'id' | 'parentId' | 'creationDate' | 'message' | 'replyTo'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )> }
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    )> }
  )> }
);

export type GetPostsQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['ID']>;
  cursor?: Maybe<Scalars['String']>;
  filter?: Maybe<Filter>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetPostsQuery = (
  { __typename?: 'Query' }
  & { posts?: Maybe<(
    { __typename?: 'DocumentPost' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'PostEdge' }
      & Pick<PostEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Post' }
        & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )>, media?: Maybe<Array<Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'image'>
        ) | (
          { __typename?: 'Video' }
          & { video?: Maybe<(
            { __typename?: 'VideoInfo' }
            & Pick<VideoInfo, 'duration' | 'videoId'>
          )> }
        )>>>, market?: Maybe<(
          { __typename?: 'Market' }
          & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
        )>, quiz?: Maybe<(
          { __typename?: 'Quiz' }
          & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
          & { options?: Maybe<Array<Maybe<(
            { __typename?: 'QuizOptions' }
            & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
          )>>> }
        )>, qa?: Maybe<(
          { __typename?: 'QA' }
          & Pick<Qa, 'question'>
          & { answer?: Maybe<(
            { __typename?: 'Comment' }
            & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
            & { author?: Maybe<(
              { __typename?: 'Author' }
              & Pick<Author, 'id' | 'name'>
            )> }
          )> }
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ) }
  )> }
);

export type GetPostQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type GetPostQuery = (
  { __typename?: 'Query' }
  & { post?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, media?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    ) | (
      { __typename?: 'Video' }
      & { video?: Maybe<(
        { __typename?: 'VideoInfo' }
        & Pick<VideoInfo, 'duration' | 'videoId'>
      )> }
    )>>>, market?: Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
    )>, quiz?: Maybe<(
      { __typename?: 'Quiz' }
      & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'QuizOptions' }
        & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
      )>>> }
    )>, qa?: Maybe<(
      { __typename?: 'QA' }
      & Pick<Qa, 'question'>
      & { answer?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type AddPostMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
  media?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  rating?: Maybe<Scalars['Int']>;
  marketplace?: Maybe<MarketInput>;
  qa?: Maybe<Scalars['String']>;
  quiz?: Maybe<QuizInput>;
}>;


export type AddPostMutation = (
  { __typename?: 'Mutation' }
  & { createPost: (
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, media?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    ) | (
      { __typename?: 'Video' }
      & { video?: Maybe<(
        { __typename?: 'VideoInfo' }
        & Pick<VideoInfo, 'duration' | 'videoId'>
      )> }
    )>>>, market?: Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
    )>, quiz?: Maybe<(
      { __typename?: 'Quiz' }
      & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'QuizOptions' }
        & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
      )>>> }
    )>, qa?: Maybe<(
      { __typename?: 'QA' }
      & Pick<Qa, 'question'>
      & { answer?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )> }
    )> }
  ) }
);

export type DeletePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePostMutation = (
  { __typename?: 'Mutation' }
  & { deletePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'message'>
  )> }
);

export type UpdatePostMutationVariables = Exact<{
  id: Scalars['ID'];
  update?: Maybe<Update>;
}>;


export type UpdatePostMutation = (
  { __typename?: 'Mutation' }
  & { updatePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, media?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    ) | (
      { __typename?: 'Video' }
      & { video?: Maybe<(
        { __typename?: 'VideoInfo' }
        & Pick<VideoInfo, 'duration' | 'videoId'>
      )> }
    )>>>, market?: Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
    )>, quiz?: Maybe<(
      { __typename?: 'Quiz' }
      & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'QuizOptions' }
        & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
      )>>> }
    )>, qa?: Maybe<(
      { __typename?: 'QA' }
      & Pick<Qa, 'question'>
      & { answer?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type AddPostImageMutationVariables = Exact<{
  image: Scalars['String'];
}>;


export type AddPostImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addPostImage'>
);

export type GetRatingFilterQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: Maybe<Filter>;
}>;


export type GetRatingFilterQuery = (
  { __typename?: 'Query' }
  & { filters?: Maybe<(
    { __typename?: 'Filters' }
    & { rating?: Maybe<(
      { __typename?: 'RatingsType' }
      & Pick<RatingsType, 'five' | 'four' | 'one' | 'three' | 'two'>
    )> }
  )> }
);

export type GetFullprofileQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type GetFullprofileQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating' | 'isSubscribed' | 'imagesCount' | 'marketplaceCount'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'id' | 'slug' | 'name'>
    )>>>, postCounters?: Maybe<(
      { __typename?: 'PostCounters' }
      & Pick<PostCounters, 'rating' | 'image' | 'video' | 'marketplace' | 'qa'>
    )>, parameters?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileParameters' }
      & Pick<ProfileParameters, 'categoryId' | 'categoryName'>
      & { parameterGroups?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileParameterGroups' }
        & Pick<ProfileParameterGroups, 'id' | 'groupName'>
        & { params?: Maybe<Array<Maybe<(
          { __typename?: 'ProfileParamsValue' }
          & Pick<ProfileParamsValue, 'id' | 'name' | 'inVariant'>
          & { unit?: Maybe<(
            { __typename?: 'Unit' }
            & Pick<Unit, 'name'>
          )>, value?: Maybe<(
            { __typename?: 'ParameterValue' }
            & Pick<ParameterValue, 'Number' | 'Boolean'>
            & { String?: Maybe<Array<Maybe<(
              { __typename?: 'ProfileStringValue' }
              & Pick<ProfileStringValue, 'value' | 'valueId'>
            )>>>, StringColor?: Maybe<(
              { __typename?: 'StringColorValue' }
              & Pick<StringColorValue, 'value' | 'color'>
            )>, Group?: Maybe<Array<Maybe<(
              { __typename?: 'GroupParameterValue' }
              & Pick<GroupParameterValue, 'group' | 'value'>
            )>>> }
          )> }
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type GetProfileimagesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProfileimagesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'images'>
);

export type UploadProfileImageMutationVariables = Exact<{
  image: Scalars['String'];
  id: Scalars['ID'];
}>;


export type UploadProfileImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addImage'>
);

export type GetShortProfileQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type GetShortProfileQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'title' | 'defaultPicture' | 'rating'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'id' | 'name' | 'slug'>
    )>>> }
  )> }
);

export type GetEditprofileQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type GetEditprofileQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'title' | 'description' | 'model' | 'barcode' | 'countries'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'id' | 'name'>
    )>>>, parameters?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileParameters' }
      & Pick<ProfileParameters, 'categoryId' | 'categoryName'>
      & { parameterGroups?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileParameterGroups' }
        & Pick<ProfileParameterGroups, 'id' | 'groupName'>
        & { params?: Maybe<Array<Maybe<(
          { __typename?: 'ProfileParamsValue' }
          & Pick<ProfileParamsValue, 'id' | 'name' | 'parameterType'>
          & { unit?: Maybe<(
            { __typename?: 'Unit' }
            & Pick<Unit, 'id' | 'name'>
          )>, value?: Maybe<(
            { __typename?: 'ParameterValue' }
            & Pick<ParameterValue, 'Number' | 'Boolean'>
            & { String?: Maybe<Array<Maybe<(
              { __typename?: 'ProfileStringValue' }
              & Pick<ProfileStringValue, 'value' | 'valueId'>
            )>>>, StringColor?: Maybe<(
              { __typename?: 'StringColorValue' }
              & Pick<StringColorValue, 'color' | 'value'>
            )>, Group?: Maybe<Array<Maybe<(
              { __typename?: 'GroupParameterValue' }
              & Pick<GroupParameterValue, 'value' | 'group'>
            )>>> }
          )> }
        )>>> }
      )>>> }
    )>>>, modifications?: Maybe<Array<Maybe<(
      { __typename?: 'Modification' }
      & Pick<Modification, 'id' | 'title' | 'barcode' | 'images'>
      & { parameters?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileParamsValue' }
        & Pick<ProfileParamsValue, 'id'>
        & { value?: Maybe<(
          { __typename?: 'ParameterValue' }
          & Pick<ParameterValue, 'Boolean' | 'Number'>
          & { Group?: Maybe<Array<Maybe<(
            { __typename?: 'GroupParameterValue' }
            & Pick<GroupParameterValue, 'value' | 'group'>
          )>>>, StringColor?: Maybe<(
            { __typename?: 'StringColorValue' }
            & Pick<StringColorValue, 'color' | 'value'>
          )>, String?: Maybe<Array<Maybe<(
            { __typename?: 'ProfileStringValue' }
            & Pick<ProfileStringValue, 'value' | 'valueId'>
          )>>> }
        )> }
      )>>> }
    )>>> }
  )> }
);

export type GetSearchProfilesQueryVariables = Exact<{
  search: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetSearchProfilesQuery = (
  { __typename?: 'Query' }
  & { profiles?: Maybe<(
    { __typename?: 'ProfileWithCount' }
    & Pick<ProfileWithCount, 'totalCount'>
    & { profiles?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileForSearch' }
      & Pick<ProfileForSearch, 'id' | 'title' | 'description' | 'creationDate' | 'defaultPicture' | 'status' | 'rating'>
      & { categories?: Maybe<Array<Maybe<(
        { __typename?: 'PopularCategories' }
        & Pick<PopularCategories, 'id' | 'name' | 'slug' | 'count'>
      )>>>, minOffer?: Maybe<(
        { __typename?: 'MinOffer' }
        & Pick<MinOffer, 'price' | 'currency'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )>, postCounters?: Maybe<(
        { __typename?: 'PostCounters' }
        & Pick<PostCounters, 'marketplace' | 'image' | 'video'>
      )> }
    )>>> }
  )> }
);

export type DialogsQueryVariables = Exact<{
  id: Scalars['ID'];
  cursor?: Maybe<Scalars['String']>;
}>;


export type DialogsQuery = (
  { __typename?: 'Query' }
  & { dialogs?: Maybe<(
    { __typename?: 'DocumentDialog' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'DialogEdge' }
      & Pick<DialogEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Dialog' }
        & Pick<Dialog, 'id' | 'blockedBy' | 'creationDate' | 'updatedDate' | 'unreadMessages'>
        & { participant?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )>, lastMessage?: Maybe<(
          { __typename?: 'Message' }
          & Pick<Message, 'message'>
        )> }
      )> }
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    )> }
  )> }
);

export type MessagesQueryVariables = Exact<{
  id: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
}>;


export type MessagesQuery = (
  { __typename?: 'Query' }
  & { messages?: Maybe<(
    { __typename?: 'DocumentMessage' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'MessageEdge' }
      & Pick<MessageEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'dialogId' | 'status' | 'creationDate' | 'message'>
        & { sender?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )>, images?: Maybe<Array<Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'image'>
        )>>>, postInfo?: Maybe<(
          { __typename?: 'PostInfoMessage' }
          & Pick<PostInfoMessage, 'id' | 'imageId' | 'message' | 'price' | 'currency'>
        )> }
      )> }
    )>>>, pageInfo?: Maybe<(
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    )> }
  )> }
);

export type CreateMessageMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  participant?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  images?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  postId?: Maybe<Scalars['ID']>;
}>;


export type CreateMessageMutation = (
  { __typename?: 'Mutation' }
  & { createMessage?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'dialogId' | 'status' | 'creationDate' | 'message'>
    & { sender?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, images?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    )>>>, postInfo?: Maybe<(
      { __typename?: 'PostInfoMessage' }
      & Pick<PostInfoMessage, 'id' | 'message' | 'price' | 'currency'>
    )> }
  )> }
);

export type AddMessageImageMutationVariables = Exact<{
  image: Scalars['String'];
}>;


export type AddMessageImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addMessageImage'>
);

export type ToggleBlockDialogMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleBlockDialogMutation = (
  { __typename?: 'Mutation' }
  & { toggleBlockDialog?: Maybe<(
    { __typename?: 'Dialog' }
    & Pick<Dialog, 'id' | 'blockedBy' | 'creationDate' | 'updatedDate' | 'unreadMessages'>
    & { participant?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, lastMessage?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'message'>
    )> }
  )> }
);

export type CreateProfileLinkMutationVariables = Exact<{
  link: Scalars['String'];
}>;


export type CreateProfileLinkMutation = (
  { __typename?: 'Mutation' }
  & { createProfileLink?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type CreateProfileMutationVariables = Exact<{
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  barcode?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  categories?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  images?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  parameters?: Maybe<Array<Maybe<ProfileParameterInput>> | Maybe<ProfileParameterInput>>;
  modifications?: Maybe<Array<Maybe<ModificationInput>> | Maybe<ModificationInput>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type CreateProfileMutation = (
  { __typename?: 'Mutation' }
  & { createProfile?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type UpdateProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  parameters?: Maybe<Array<Maybe<ProfileParameterInput>> | Maybe<ProfileParameterInput>>;
  modifications?: Maybe<Array<Maybe<ModificationInput>> | Maybe<ModificationInput>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type UpdateProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateProfile?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type AddProfileImageMutationVariables = Exact<{
  image: Scalars['String'];
}>;


export type AddProfileImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addProfileImage'>
);

export type GetCategoryInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type GetCategoryInfoQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'status'>
    & { parameterGroups?: Maybe<Array<Maybe<(
      { __typename?: 'parameterGroups' }
      & Pick<ParameterGroups, 'id' | 'groupName'>
      & { params?: Maybe<Array<Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'id' | 'name' | 'parameterType' | 'isFilter' | 'inVariant' | 'groups'>
        & { unit?: Maybe<(
          { __typename?: 'Unit' }
          & Pick<Unit, 'id' | 'name'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type UnitsQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
}>;


export type UnitsQuery = (
  { __typename?: 'Query' }
  & { units?: Maybe<Array<Maybe<(
    { __typename?: 'Unit' }
    & Pick<Unit, 'id' | 'name'>
  )>>> }
);

export type GetCategoriesByNameQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetCategoriesByNameQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<Maybe<(
    { __typename?: 'PopularCategories' }
    & Pick<PopularCategories, 'id' | 'name'>
  )>>> }
);

export type GetRelatedCategoriesSuggestionQueryVariables = Exact<{
  categories?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetRelatedCategoriesSuggestionQuery = (
  { __typename?: 'Query' }
  & { categories?: Maybe<Array<Maybe<(
    { __typename?: 'PopularCategories' }
    & Pick<PopularCategories, 'id' | 'name'>
  )>>> }
);

export type CreateCategoryMutationVariables = Exact<{
  name: Scalars['String'];
  categories?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  parameters?: Maybe<Array<Maybe<ParametersGroupInput>> | Maybe<ParametersGroupInput>>;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type GetCategoryFullInfoQueryVariables = Exact<{
  id: Scalars['ID'];
  categories?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetCategoryFullInfoQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
    & { parameterGroups?: Maybe<Array<Maybe<(
      { __typename?: 'parameterGroups' }
      & Pick<ParameterGroups, 'id' | 'groupName'>
      & { params?: Maybe<Array<Maybe<(
        { __typename?: 'Parameter' }
        & Pick<Parameter, 'id' | 'name' | 'parameterType' | 'isFilter' | 'inVariant' | 'groups'>
        & { unit?: Maybe<(
          { __typename?: 'Unit' }
          & Pick<Unit, 'id'>
        )> }
      )>>> }
    )>>> }
  )>, categories?: Maybe<Array<Maybe<(
    { __typename?: 'PopularCategories' }
    & Pick<PopularCategories, 'id' | 'name'>
  )>>> }
);

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  parentIds?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  parameters?: Maybe<Array<Maybe<ParametersGroupInput>> | Maybe<ParametersGroupInput>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type PopularCategoriesQueryVariables = Exact<{
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type PopularCategoriesQuery = (
  { __typename?: 'Query' }
  & { popularCategories?: Maybe<Array<Maybe<(
    { __typename?: 'PopularCategories' }
    & Pick<PopularCategories, 'id' | 'slug' | 'name' | 'count'>
  )>>> }
);

export type GetCategoryProfilesQueryVariables = Exact<{
  category?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  search?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<ProfileParameter>> | Maybe<ProfileParameter>>;
  location?: Maybe<LocationFilter>;
  inStock?: Maybe<Scalars['Boolean']>;
  price?: Maybe<NumberFilter>;
  brand?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  sort?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetCategoryProfilesQuery = (
  { __typename?: 'Query' }
  & { profiles?: Maybe<(
    { __typename?: 'ProfileWithCount' }
    & Pick<ProfileWithCount, 'totalCount'>
    & { profiles?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileForSearch' }
      & Pick<ProfileForSearch, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating' | 'marketplaceCount'>
      & { categories?: Maybe<Array<Maybe<(
        { __typename?: 'PopularCategories' }
        & Pick<PopularCategories, 'id' | 'name'>
      )>>>, minOffer?: Maybe<(
        { __typename?: 'MinOffer' }
        & Pick<MinOffer, 'price' | 'currency'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )>, postCounters?: Maybe<(
        { __typename?: 'PostCounters' }
        & Pick<PostCounters, 'rating' | 'image' | 'video' | 'marketplace'>
      )> }
    )>>> }
  )> }
);

export type GetProfilesByIdQueryVariables = Exact<{
  ids?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetProfilesByIdQuery = (
  { __typename?: 'Query' }
  & { profilesById?: Maybe<Array<Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'title' | 'defaultPicture' | 'rating'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'id' | 'name'>
    )>>>, postCounters?: Maybe<(
      { __typename?: 'PostCounters' }
      & Pick<PostCounters, 'rating'>
    )>, parameters?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileParameters' }
      & Pick<ProfileParameters, 'categoryId' | 'categoryName'>
      & { parameterGroups?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileParameterGroups' }
        & Pick<ProfileParameterGroups, 'id' | 'groupName'>
        & { params?: Maybe<Array<Maybe<(
          { __typename?: 'ProfileParamsValue' }
          & Pick<ProfileParamsValue, 'id' | 'name'>
          & { unit?: Maybe<(
            { __typename?: 'Unit' }
            & Pick<Unit, 'id' | 'name'>
          )>, value?: Maybe<(
            { __typename?: 'ParameterValue' }
            & Pick<ParameterValue, 'Number' | 'Boolean'>
            & { String?: Maybe<Array<Maybe<(
              { __typename?: 'ProfileStringValue' }
              & Pick<ProfileStringValue, 'value' | 'valueId'>
            )>>>, StringColor?: Maybe<(
              { __typename?: 'StringColorValue' }
              & Pick<StringColorValue, 'value' | 'color'>
            )>, Group?: Maybe<Array<Maybe<(
              { __typename?: 'GroupParameterValue' }
              & Pick<GroupParameterValue, 'value' | 'group'>
            )>>> }
          )> }
        )>>> }
      )>>> }
    )>>> }
  )>>> }
);

export type CategoryFiltersQueryVariables = Exact<{
  id: Scalars['ID'];
  filter?: Maybe<Array<Maybe<ProfileParameter>> | Maybe<ProfileParameter>>;
  price?: Maybe<NumberFilter>;
  inStock?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type CategoryFiltersQuery = (
  { __typename?: 'Query' }
  & { categoryFilters?: Maybe<Array<Maybe<(
    { __typename?: 'CategoryFilters' }
    & Pick<CategoryFilters, 'id' | 'name' | 'parameterType'>
    & { valuesInfo?: Maybe<(
      { __typename?: 'FilterValues' }
      & { String?: Maybe<Array<Maybe<(
        { __typename?: 'StringValues' }
        & Pick<StringValues, 'valueId' | 'value' | 'count'>
      )>>>, Boolean?: Maybe<Array<Maybe<(
        { __typename?: 'BooleanValues' }
        & Pick<BooleanValues, 'valueId' | 'value' | 'count'>
      )>>>, Number?: Maybe<(
        { __typename?: 'NumberValues' }
        & Pick<NumberValues, 'min' | 'max'>
        & { unit?: Maybe<(
          { __typename?: 'Unit' }
          & Pick<Unit, 'id' | 'name'>
        )> }
      )>, StringColor?: Maybe<Array<Maybe<(
        { __typename?: 'StringColorValues' }
        & Pick<StringColorValues, 'valueId' | 'value' | 'count' | 'color'>
      )>>>, Group?: Maybe<Array<Maybe<(
        { __typename?: 'GroupValues' }
        & Pick<GroupValues, 'group' | 'value' | 'valueId' | 'count'>
      )>>> }
    )> }
  )>>> }
);

export type GetCategoryNameQueryVariables = Exact<{
  id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetCategoryNameQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'slug' | 'filter'>
  )>, parentChildrenCategories?: Maybe<(
    { __typename?: 'RelatedCategories' }
    & Pick<RelatedCategories, 'count' | 'level'>
    & { parents?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'id' | 'name' | 'count' | 'slug'>
    )>>>, children?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'slug' | 'count' | 'name' | 'id'>
    )>>> }
  )> }
);

export type GetAllCategoriesQueryVariables = Exact<{
  view?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetAllCategoriesQuery = (
  { __typename?: 'Query' }
  & { allCategories?: Maybe<Array<Maybe<(
    { __typename?: 'PopularCategories' }
    & Pick<PopularCategories, 'id' | 'slug' | 'count' | 'name' | 'parentIds'>
  )>>> }
);

export type GetRecentActivityQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetRecentActivityQuery = (
  { __typename?: 'Query' }
  & { recentActivity?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'postId' | 'profileId' | 'profileName' | 'creationDate' | 'message'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )> }
  ) | { __typename?: 'Marketplace' } | (
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message'>
    & { postRating: Post['rating'] }
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, media?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    ) | { __typename?: 'Video' }>>>, market?: Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'price' | 'currency'>
    )> }
  ) | (
    { __typename?: 'ProfileForSearch' }
    & Pick<ProfileForSearch, 'id' | 'creationDate' | 'title' | 'description' | 'rating' | 'defaultPicture'>
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'id' | 'name'>
    )>>> }
  )>>> }
);

export type GetParameterValuesQueryVariables = Exact<{
  id: Scalars['ID'];
  search?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetParameterValuesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'parameterValues'>
);

export type UploadUserPhotoMutationVariables = Exact<{
  picture: Scalars['String'];
}>;


export type UploadUserPhotoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadPhoto'>
);

export type DeleteUserPhotoMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserPhotoMutation = (
  { __typename?: 'Mutation' }
  & { deletePhoto?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type GetMyPostsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetMyPostsQuery = (
  { __typename?: 'Query' }
  & { myPosts?: Maybe<(
    { __typename?: 'PostWithCount' }
    & Pick<PostWithCount, 'count'>
    & { posts?: Maybe<Array<Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'originalMessage' | 'comments' | 'rating'>
      & { author?: Maybe<(
        { __typename?: 'Author' }
        & Pick<Author, 'id' | 'name'>
      )>, media?: Maybe<Array<Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'image'>
      ) | { __typename?: 'Video' }>>>, market?: Maybe<(
        { __typename?: 'Market' }
        & Pick<Market, 'currency' | 'price'>
      )> }
    )>>> }
  )> }
);

export type GetMyCommentsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetMyCommentsQuery = (
  { __typename?: 'Query' }
  & { myComments?: Maybe<(
    { __typename?: 'CommentWithCount' }
    & Pick<CommentWithCount, 'count'>
    & { comments?: Maybe<Array<Maybe<(
      { __typename?: 'MyComment' }
      & Pick<MyComment, 'id' | 'creationDate' | 'message' | 'profileId' | 'profileName'>
      & { post?: Maybe<(
        { __typename?: 'Post' }
        & Pick<Post, 'id' | 'creationDate' | 'originalMessage' | 'rating'>
        & { media?: Maybe<Array<Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'image'>
        ) | { __typename?: 'Video' }>>>, market?: Maybe<(
          { __typename?: 'Market' }
          & Pick<Market, 'currency' | 'price'>
        )> }
      )> }
    )>>> }
  )> }
);

export type PollVoteMutationVariables = Exact<{
  id: Scalars['ID'];
  optionId: Scalars['ID'];
}>;


export type PollVoteMutation = (
  { __typename?: 'Mutation' }
  & { voteForQuiz?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, media?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    ) | (
      { __typename?: 'Video' }
      & { video?: Maybe<(
        { __typename?: 'VideoInfo' }
        & Pick<VideoInfo, 'duration' | 'videoId'>
      )> }
    )>>>, market?: Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
    )>, quiz?: Maybe<(
      { __typename?: 'Quiz' }
      & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'QuizOptions' }
        & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
      )>>> }
    )>, qa?: Maybe<(
      { __typename?: 'QA' }
      & Pick<Qa, 'question'>
      & { answer?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type GetProfileMediaQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetProfileMediaQuery = (
  { __typename?: 'Query' }
  & { profileMedia?: Maybe<Array<Maybe<(
    { __typename?: 'Image' }
    & Pick<Image, 'image'>
  ) | (
    { __typename?: 'Video' }
    & { video?: Maybe<(
      { __typename?: 'VideoInfo' }
      & Pick<VideoInfo, 'duration' | 'videoId'>
    )> }
  )>>> }
);

export type UpdateUserMutationVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  family_name?: Maybe<Scalars['String']>;
  previousPassword?: Maybe<Scalars['String']>;
  ProposedPassword?: Maybe<Scalars['String']>;
  about?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  vkontakte?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'email_verified' | 'phone' | 'phone_verified' | 'unreadMessages' | 'name' | 'family_name' | 'about' | 'website' | 'facebook' | 'vkontakte' | 'twitter' | 'instagram'>
  )> }
);

export type UpdateUserAttributeMutationVariables = Exact<{
  login?: Maybe<Scalars['String']>;
}>;


export type UpdateUserAttributeMutation = (
  { __typename?: 'Mutation' }
  & { updateUserAttribute?: Maybe<(
    { __typename?: 'ConfirmationCodeResponse' }
    & { CodeDeliveryDetails?: Maybe<(
      { __typename?: 'CodeDeliveryDetailsType' }
      & Pick<CodeDeliveryDetailsType, 'Destination' | 'DeliveryMedium' | 'AttributeName'>
    )> }
  )> }
);

export type ConfirmUserAttributeQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
}>;


export type ConfirmUserAttributeQuery = (
  { __typename?: 'Query' }
  & { confirmUserAttribute?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id' | 'message'>
  )> }
);

export type ResendConfirmUserAttributeQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type ResendConfirmUserAttributeQuery = (
  { __typename?: 'Query' }
  & { resendConfirmUserAttribute?: Maybe<(
    { __typename?: 'ConfirmationCodeResponse' }
    & { CodeDeliveryDetails?: Maybe<(
      { __typename?: 'CodeDeliveryDetailsType' }
      & Pick<CodeDeliveryDetailsType, 'Destination' | 'DeliveryMedium' | 'AttributeName'>
    )> }
  )> }
);

export type UserRegionQueryVariables = Exact<{ [key: string]: never; }>;


export type UserRegionQuery = (
  { __typename?: 'Query' }
  & { userRegion?: Maybe<(
    { __typename?: 'UserRegion' }
    & Pick<UserRegion, 'country' | 'countryCode'>
    & { city?: Maybe<(
      { __typename?: 'UserCity' }
      & Pick<UserCity, 'name' | 'coords' | 'postal'>
    )> }
  )> }
);

export type ToggleAnswerMutationVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type ToggleAnswerMutation = (
  { __typename?: 'Mutation' }
  & { toggleAnswer?: Maybe<(
    { __typename?: 'PostComment' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
      & { author?: Maybe<(
        { __typename?: 'Author' }
        & Pick<Author, 'id' | 'name'>
      )> }
    )>, post?: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
      & { author?: Maybe<(
        { __typename?: 'Author' }
        & Pick<Author, 'id' | 'name'>
      )>, media?: Maybe<Array<Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'image'>
      ) | (
        { __typename?: 'Video' }
        & { video?: Maybe<(
          { __typename?: 'VideoInfo' }
          & Pick<VideoInfo, 'duration' | 'videoId'>
        )> }
      )>>>, market?: Maybe<(
        { __typename?: 'Market' }
        & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
      )>, quiz?: Maybe<(
        { __typename?: 'Quiz' }
        & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
        & { options?: Maybe<Array<Maybe<(
          { __typename?: 'QuizOptions' }
          & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
        )>>> }
      )>, qa?: Maybe<(
        { __typename?: 'QA' }
        & Pick<Qa, 'question'>
        & { answer?: Maybe<(
          { __typename?: 'Comment' }
          & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
          & { author?: Maybe<(
            { __typename?: 'Author' }
            & Pick<Author, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type LikePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LikePostMutation = (
  { __typename?: 'Mutation' }
  & { likePost?: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'profileId' | 'profileName' | 'creationDate' | 'message' | 'originalMessage' | 'postLanguage' | 'comments' | 'rating' | 'likes' | 'isLiked'>
    & { author?: Maybe<(
      { __typename?: 'Author' }
      & Pick<Author, 'id' | 'name'>
    )>, media?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    ) | (
      { __typename?: 'Video' }
      & { video?: Maybe<(
        { __typename?: 'VideoInfo' }
        & Pick<VideoInfo, 'duration' | 'videoId'>
      )> }
    )>>>, market?: Maybe<(
      { __typename?: 'Market' }
      & Pick<Market, 'tradeType' | 'location' | 'coords' | 'currency' | 'price' | 'condition'>
    )>, quiz?: Maybe<(
      { __typename?: 'Quiz' }
      & Pick<Quiz, 'topic' | 'multiple' | 'isVoted'>
      & { options?: Maybe<Array<Maybe<(
        { __typename?: 'QuizOptions' }
        & Pick<QuizOptions, 'name' | 'lang' | 'original' | 'id' | 'votes' | 'percent' | 'userVote'>
      )>>> }
    )>, qa?: Maybe<(
      { __typename?: 'QA' }
      & Pick<Qa, 'question'>
      & { answer?: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'id' | 'postId' | 'creationDate' | 'message' | 'answer' | 'replyCount'>
        & { author?: Maybe<(
          { __typename?: 'Author' }
          & Pick<Author, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ToggleSubscribeProductMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleSubscribeProductMutation = (
  { __typename?: 'Mutation' }
  & { toggleSubscribe?: Maybe<(
    { __typename?: 'Profile' }
    & Pick<Profile, 'id' | 'isSubscribed'>
  )> }
);

export type GetCollectionsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetCollectionsQuery = (
  { __typename?: 'Query' }
  & { collections?: Maybe<Array<Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'description'>
    & { profiles?: Maybe<Array<Maybe<(
      { __typename?: 'userProduct' }
      & Pick<UserProduct, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating'>
    )>>> }
  )>>> }
);

export type CreateCollectionMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { createCollection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'description'>
    & { profiles?: Maybe<Array<Maybe<(
      { __typename?: 'userProduct' }
      & Pick<UserProduct, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating'>
    )>>> }
  )> }
);

export type UpdateCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateCollectionMutation = (
  { __typename?: 'Mutation' }
  & { updateCollection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'description'>
    & { profiles?: Maybe<Array<Maybe<(
      { __typename?: 'userProduct' }
      & Pick<UserProduct, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating'>
    )>>> }
  )> }
);

export type DeleteCollectionMutationVariables = Exact<{
  collectionId: Scalars['ID'];
}>;


export type DeleteCollectionMutation = (
  { __typename?: 'Mutation' }
  & { deleteCollection?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type AddToCollectionMutationVariables = Exact<{
  profileId: Scalars['ID'];
  collectionId: Scalars['ID'];
}>;


export type AddToCollectionMutation = (
  { __typename?: 'Mutation' }
  & { addToCollection?: Maybe<(
    { __typename?: 'Collection' }
    & Pick<Collection, 'id' | 'name' | 'description'>
    & { profiles?: Maybe<Array<Maybe<(
      { __typename?: 'userProduct' }
      & Pick<UserProduct, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating'>
    )>>> }
  )> }
);

export type RemoveFromCollectionMutationVariables = Exact<{
  profileId: Scalars['ID'];
  collectionId: Scalars['ID'];
}>;


export type RemoveFromCollectionMutation = (
  { __typename?: 'Mutation' }
  & { removeFromCollection?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type GetRewardsQueryVariables = Exact<{
  userId?: Maybe<Scalars['String']>;
}>;


export type GetRewardsQuery = (
  { __typename?: 'Query' }
  & { rewards?: Maybe<Array<Maybe<(
    { __typename?: 'Reward' }
    & Pick<Reward, 'name' | 'level' | 'points' | 'pointScale'>
  )>>> }
);

export type GetOtherUserQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
}>;


export type GetOtherUserQuery = (
  { __typename?: 'Query' }
  & { otherUserInfo?: Maybe<(
    { __typename?: 'OtherUserInfo' }
    & Pick<OtherUserInfo, 'id' | 'name' | 'family_name' | 'about' | 'website' | 'facebook' | 'vkontakte' | 'twitter' | 'instagram'>
    & { rewards?: Maybe<Array<Maybe<(
      { __typename?: 'Reward' }
      & Pick<Reward, 'name' | 'level' | 'points' | 'pointScale'>
    )>>>, counters?: Maybe<(
      { __typename?: 'UserCounters' }
      & Pick<UserCounters, 'collections' | 'products' | 'marketplace'>
    )> }
  )> }
);

export type GetUserProductsQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetUserProductsQuery = (
  { __typename?: 'Query' }
  & { userProducts?: Maybe<Array<Maybe<(
    { __typename?: 'userProduct' }
    & Pick<UserProduct, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating'>
  )>>> }
);

export type ProfilesMapQueryVariables = Exact<{
  categoryId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  bounds?: Maybe<Array<Maybe<Scalars['Float']>> | Maybe<Scalars['Float']>>;
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type ProfilesMapQuery = (
  { __typename?: 'Query' }
  & { profilesMap?: Maybe<(
    { __typename?: 'DocumentProfile' }
    & { edges?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileEdge' }
      & Pick<ProfileEdge, 'cursor'>
      & { node?: Maybe<(
        { __typename?: 'Profile' }
        & Pick<Profile, 'id' | 'title' | 'description' | 'creationDate' | 'defaultPicture' | 'status' | 'rating'>
        & { minOffer?: Maybe<(
          { __typename?: 'MinOffer' }
          & Pick<MinOffer, 'price' | 'currency'>
          & { author?: Maybe<(
            { __typename?: 'Author' }
            & Pick<Author, 'id' | 'name'>
          )> }
        )>, categories?: Maybe<Array<Maybe<(
          { __typename?: 'PopularCategories' }
          & Pick<PopularCategories, 'id' | 'name' | 'slug' | 'count'>
        )>>>, postCounters?: Maybe<(
          { __typename?: 'PostCounters' }
          & Pick<PostCounters, 'marketplace' | 'image' | 'video'>
        )> }
      )> }
    )>>>, pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasPreviousPage' | 'hasNextPage'>
    ) }
  )> }
);

export type MarketplacePointsQueryVariables = Exact<{
  categoryId?: Maybe<Scalars['ID']>;
  bounds?: Maybe<Array<Maybe<Scalars['Float']>> | Maybe<Scalars['Float']>>;
  search?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type MarketplacePointsQuery = (
  { __typename?: 'Query' }
  & { marketplacePoints?: Maybe<Array<Maybe<(
    { __typename?: 'MarketplacePoint' }
    & Pick<MarketplacePoint, 'id' | 'type'>
    & { properties?: Maybe<(
      { __typename?: 'FeatureProperties' }
      & Pick<FeatureProperties, 'cluster' | 'profileId' | 'price' | 'currency'>
    )>, geometry?: Maybe<(
      { __typename?: 'FeatureGeometry' }
      & Pick<FeatureGeometry, 'type' | 'coordinates'>
    )> }
  )>>> }
);

export type MarketplaceSearchProductQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
}>;


export type MarketplaceSearchProductQuery = (
  { __typename?: 'Query' }
  & { marketplaceSearchProduct?: Maybe<Array<Maybe<(
    { __typename?: 'ProductSearch' }
    & Pick<ProductSearch, 'id' | 'title' | 'defaultPicture'>
    & { modifications?: Maybe<Array<Maybe<(
      { __typename?: 'Modification' }
      & Pick<Modification, 'id' | 'title'>
      & { parameters?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileParamsValue' }
        & Pick<ProfileParamsValue, 'id' | 'name' | 'parameterType' | 'groups'>
        & { unit?: Maybe<(
          { __typename?: 'Unit' }
          & Pick<Unit, 'id' | 'name'>
        )>, value?: Maybe<(
          { __typename?: 'ParameterValue' }
          & Pick<ParameterValue, 'Number' | 'Boolean'>
          & { String?: Maybe<Array<Maybe<(
            { __typename?: 'ProfileStringValue' }
            & Pick<ProfileStringValue, 'value' | 'valueId'>
          )>>>, StringColor?: Maybe<(
            { __typename?: 'StringColorValue' }
            & Pick<StringColorValue, 'value' | 'color'>
          )>, Group?: Maybe<Array<Maybe<(
            { __typename?: 'GroupParameterValue' }
            & Pick<GroupParameterValue, 'group' | 'value' | 'valueId'>
          )>>> }
        )> }
      )>>> }
    )>>>, avgPrice?: Maybe<(
      { __typename?: 'ProductAveragePrice' }
      & Pick<ProductAveragePrice, 'price' | 'currency' | 'count'>
    )> }
  )>>> }
);

export type UploadAdImageMutationVariables = Exact<{
  image?: Maybe<Scalars['String']>;
}>;


export type UploadAdImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addMarketplaceImage'>
);

export type GetLocationQueryVariables = Exact<{
  coords?: Maybe<Array<Maybe<Scalars['Float']>> | Maybe<Scalars['Float']>>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { getLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'address' | 'city' | 'coords'>
  )> }
);

export type CreateAdMutationVariables = Exact<{
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  profileId?: Maybe<Scalars['ID']>;
  modificationId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['ID']>;
  price?: Maybe<Scalars['Float']>;
  location?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  delivery?: Maybe<Scalars['Boolean']>;
  coords?: Maybe<Array<Maybe<Scalars['Float']>> | Maybe<Scalars['Float']>>;
  tradeType?: Maybe<Scalars['String']>;
  condition?: Maybe<Scalars['String']>;
  hidePhone?: Maybe<Scalars['Boolean']>;
  country: Scalars['String'];
}>;


export type CreateAdMutation = (
  { __typename?: 'Mutation' }
  & { createMarketplace?: Maybe<(
    { __typename?: 'Marketplace' }
    & Pick<Marketplace, 'id'>
  )> }
);

export type MarketplaceRegisterUserQueryVariables = Exact<{
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pass?: Maybe<Scalars['String']>;
}>;


export type MarketplaceRegisterUserQuery = (
  { __typename?: 'Query' }
  & { marketplaceRegisterUser?: Maybe<(
    { __typename?: 'UserRegisterResponse' }
    & Pick<UserRegisterResponse, 'UserConfirmed' | 'UserSub' | 'Username'>
    & { CodeDeliveryDetails?: Maybe<(
      { __typename?: 'CodeDeliveryDetailsType' }
      & Pick<CodeDeliveryDetailsType, 'Destination' | 'DeliveryMedium' | 'AttributeName'>
    )> }
  )> }
);

export type GetAdChildrenCategoriesQueryVariables = Exact<{
  id: Scalars['ID'];
  category?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetAdChildrenCategoriesQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'slug' | 'filter'>
  )>, parentChildrenCategories?: Maybe<(
    { __typename?: 'RelatedCategories' }
    & Pick<RelatedCategories, 'count'>
    & { parents?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'id' | 'name' | 'count' | 'slug'>
    )>>>, children?: Maybe<Array<Maybe<(
      { __typename?: 'PopularCategories' }
      & Pick<PopularCategories, 'slug' | 'count' | 'name' | 'id'>
    )>>> }
  )> }
);

export type GetMarketplaceQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type GetMarketplaceQuery = (
  { __typename?: 'Query' }
  & { marketplace?: Maybe<(
    { __typename?: 'Marketplace' }
    & Pick<Marketplace, 'id' | 'title' | 'description' | 'publishedDate' | 'categoryId' | 'price' | 'oldPrice' | 'location' | 'currency' | 'condition' | 'coords' | 'delivery' | 'tradeType' | 'viewsCount' | 'bookmarks' | 'status' | 'otherMarketplaceCount' | 'inBookmarks'>
    & { media?: Maybe<Array<Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'image'>
    ) | (
      { __typename?: 'Video' }
      & { video?: Maybe<(
        { __typename?: 'VideoInfo' }
        & Pick<VideoInfo, 'duration' | 'videoId'>
      )> }
    )>>>, profile?: Maybe<(
      { __typename?: 'userProduct' }
      & Pick<UserProduct, 'id' | 'title' | 'description' | 'defaultPicture' | 'rating'>
    )>, author?: Maybe<(
      { __typename?: 'MarketplaceAuthor' }
      & Pick<MarketplaceAuthor, 'id' | 'name' | 'phone' | 'creationDate' | 'marketplaceCount'>
    )> }
  )> }
);

export type GetAdCharacteristicsQueryVariables = Exact<{
  id: Scalars['ID'];
  lang?: Maybe<Scalars['String']>;
}>;


export type GetAdCharacteristicsQuery = (
  { __typename?: 'Query' }
  & { profile?: Maybe<(
    { __typename?: 'Profile' }
    & { parameters?: Maybe<Array<Maybe<(
      { __typename?: 'ProfileParameters' }
      & Pick<ProfileParameters, 'categoryId' | 'categoryName'>
      & { parameterGroups?: Maybe<Array<Maybe<(
        { __typename?: 'ProfileParameterGroups' }
        & Pick<ProfileParameterGroups, 'id' | 'groupName'>
        & { params?: Maybe<Array<Maybe<(
          { __typename?: 'ProfileParamsValue' }
          & Pick<ProfileParamsValue, 'id' | 'name' | 'parameterType' | 'groups'>
          & { unit?: Maybe<(
            { __typename?: 'Unit' }
            & Pick<Unit, 'id' | 'name'>
          )>, value?: Maybe<(
            { __typename?: 'ParameterValue' }
            & Pick<ParameterValue, 'Number' | 'Boolean'>
            & { String?: Maybe<Array<Maybe<(
              { __typename?: 'ProfileStringValue' }
              & Pick<ProfileStringValue, 'value' | 'valueId'>
            )>>>, StringColor?: Maybe<(
              { __typename?: 'StringColorValue' }
              & Pick<StringColorValue, 'value' | 'color'>
            )>, Group?: Maybe<Array<Maybe<(
              { __typename?: 'GroupParameterValue' }
              & Pick<GroupParameterValue, 'group' | 'value' | 'valueId'>
            )>>> }
          )> }
        )>>> }
      )>>> }
    )>>> }
  )> }
);

export type GetRelatedAdQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetRelatedAdQuery = (
  { __typename?: 'Query' }
  & { marketplaceRelated?: Maybe<(
    { __typename?: 'MarketlaceWithCount' }
    & { marketplace?: Maybe<Array<Maybe<(
      { __typename?: 'Marketplace' }
      & Pick<Marketplace, 'id' | 'title' | 'publishedDate' | 'price' | 'oldPrice' | 'location' | 'currency'>
      & { media?: Maybe<Array<Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'image'>
      ) | (
        { __typename?: 'Video' }
        & { video?: Maybe<(
          { __typename?: 'VideoInfo' }
          & Pick<VideoInfo, 'videoId' | 'duration'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type ToggleBookmarkMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleBookmarkMutation = (
  { __typename?: 'Mutation' }
  & { toggleBookmark?: Maybe<(
    { __typename?: 'OkMessage' }
    & Pick<OkMessage, 'id'>
  )> }
);

export type LocationAutocompleteQueryVariables = Exact<{
  search: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type LocationAutocompleteQuery = (
  { __typename?: 'Query' }
  & { locationAutocomplete?: Maybe<Array<Maybe<(
    { __typename?: 'Autocomplete' }
    & Pick<Autocomplete, 'address' | 'city' | 'coords'>
  )>>> }
);

export type MarketplaceUserQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type MarketplaceUserQuery = (
  { __typename?: 'Query' }
  & { marketplaceUser?: Maybe<(
    { __typename?: 'MarketplaceUserWithCount' }
    & { marketplace?: Maybe<Array<Maybe<(
      { __typename?: 'Marketplace' }
      & Pick<Marketplace, 'id' | 'title' | 'publishedDate' | 'price' | 'oldPrice' | 'location' | 'currency' | 'status' | 'inBookmarks'>
      & { media?: Maybe<Array<Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'image'>
      ) | (
        { __typename?: 'Video' }
        & { video?: Maybe<(
          { __typename?: 'VideoInfo' }
          & Pick<VideoInfo, 'duration' | 'videoId'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type ProductOffersQueryVariables = Exact<{
  productId?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type ProductOffersQuery = (
  { __typename?: 'Query' }
  & { productOffers?: Maybe<(
    { __typename?: 'MarketplacesOffersWithCount' }
    & Pick<MarketplacesOffersWithCount, 'totalCount'>
    & { marketsOffers?: Maybe<Array<Maybe<(
      { __typename?: 'Marketplace' }
      & Pick<Marketplace, 'id' | 'title' | 'description' | 'publishedDate' | 'categoryId' | 'price' | 'oldPrice' | 'location' | 'currency' | 'inBookmarks'>
      & { author?: Maybe<(
        { __typename?: 'MarketplaceAuthor' }
        & Pick<MarketplaceAuthor, 'id' | 'name' | 'phone'>
      )>, media?: Maybe<Array<Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'image'>
      ) | (
        { __typename?: 'Video' }
        & { video?: Maybe<(
          { __typename?: 'VideoInfo' }
          & Pick<VideoInfo, 'videoId' | 'duration'>
        )> }
      )>>> }
    ) | (
      { __typename?: 'Offer' }
      & Pick<Offer, 'id' | 'active' | 'description' | 'image' | 'url' | 'availability'>
      & { offerTitle: Offer['title'], offerPrice: Offer['price'], offerCurrency: Offer['currency'] }
    )>>> }
  )> }
);

export type GetCategoryShortInfoQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type GetCategoryShortInfoQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'slug' | 'filter'>
  )> }
);

export type MyMarketplaceQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type MyMarketplaceQuery = (
  { __typename?: 'Query' }
  & { marketplaceUser?: Maybe<(
    { __typename?: 'MarketplaceUserWithCount' }
    & Pick<MarketplaceUserWithCount, 'totalCount'>
    & { marketplace?: Maybe<Array<Maybe<(
      { __typename?: 'Marketplace' }
      & Pick<Marketplace, 'id' | 'title' | 'description' | 'creationDate' | 'publishedDate' | 'categoryId' | 'price' | 'oldPrice' | 'location' | 'currency' | 'condition' | 'coords' | 'status' | 'viewsCount' | 'bookmarks' | 'rejectReason'>
      & { media?: Maybe<Array<Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'image'>
      ) | (
        { __typename?: 'Video' }
        & { video?: Maybe<(
          { __typename?: 'VideoInfo' }
          & Pick<VideoInfo, 'duration' | 'videoId'>
        )> }
      )>>>, author?: Maybe<(
        { __typename?: 'MarketplaceAuthor' }
        & Pick<MarketplaceAuthor, 'id' | 'name'>
      )>, views?: Maybe<Array<Maybe<(
        { __typename?: 'MarketplaceViews' }
        & Pick<MarketplaceViews, 'date' | 'views'>
      )>>> }
    )>>>, counters?: Maybe<(
      { __typename?: 'MarketplaceUserCounters' }
      & Pick<MarketplaceUserCounters, 'published' | 'unpublished' | 'rejected'>
    )> }
  )> }
);

export type MarketplaceUserFiltersQueryVariables = Exact<{
  userId?: Maybe<Scalars['ID']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type MarketplaceUserFiltersQuery = (
  { __typename?: 'Query' }
  & { marketplaceUserFilters?: Maybe<(
    { __typename?: 'UserMarketplaceFiltes' }
    & { categories?: Maybe<Array<Maybe<(
      { __typename?: 'FilterCount' }
      & Pick<FilterCount, 'name' | 'id' | 'count'>
    )>>>, locations?: Maybe<Array<Maybe<(
      { __typename?: 'FilterCount' }
      & Pick<FilterCount, 'name' | 'id' | 'count'>
    )>>> }
  )> }
);

export type SearchSuggestQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
}>;


export type SearchSuggestQuery = (
  { __typename?: 'Query' }
  & { search?: Maybe<Array<Maybe<(
    { __typename?: 'MainSearch' }
    & Pick<MainSearch, 'type' | 'id' | 'name' | 'value' | 'defaultPicture' | 'slug'>
  )>>> }
);

export type GetShopsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShopsQuery = (
  { __typename?: 'Query' }
  & { shops?: Maybe<Array<Maybe<(
    { __typename?: 'Shop' }
    & Pick<Shop, 'id' | 'name' | 'userId' | 'address' | 'website' | 'companyName' | 'taxNumber' | 'personName' | 'personEmail' | 'personPhone' | 'photos' | 'status' | 'balance' | 'isLimitExpenses' | 'limitExpensesValue'>
    & { openingHours?: Maybe<Array<Maybe<(
      { __typename?: 'OpeningHours' }
      & Pick<OpeningHours, 'daysOfWeek' | 'workFrom' | 'workTo' | 'breakFrom' | 'breakTo'>
    )>>>, sources?: Maybe<Array<Maybe<(
      { __typename?: 'Source' }
      & Pick<Source, 'id' | 'name' | 'type' | 'file' | 'url' | 'count' | 'updatedAt'>
    )>>>, strategies?: Maybe<Array<Maybe<(
      { __typename?: 'Strategy' }
      & Pick<Strategy, 'id' | 'name' | 'rate' | 'priority' | 'status' | 'model'>
      & { restrictions?: Maybe<Array<Maybe<(
        { __typename?: 'Restriction' }
        & Pick<Restriction, 'categories' | 'minPrice' | 'maxPrice' | 'products'>
      )>>> }
    )>>> }
  )>>> }
);

export type GetOffersQueryVariables = Exact<{
  shopId: Scalars['ID'];
  status?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Maybe<Scalars['ObjectId']>> | Maybe<Scalars['ObjectId']>>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type GetOffersQuery = (
  { __typename?: 'Query' }
  & { offers?: Maybe<(
    { __typename?: 'Offers' }
    & Pick<Offers, 'total'>
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Offer' }
      & Pick<Offer, 'id' | 'title' | 'shopId' | 'sourceId' | 'shopSKU' | 'active' | 'image' | 'price' | 'currency'>
      & { product?: Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'productId' | 'title' | 'defaultPicture' | 'categoryName'>
      )> }
    )>>> }
  )> }
);

export type UserPhoneImageQueryVariables = Exact<{
  marketplaceId: Scalars['String'];
}>;


export type UserPhoneImageQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userPhoneImage'>
);

export const CommentNodeFragmentDoc = gql`
    fragment CommentNode on Comment {
  id
  postId
  author {
    id
    name
  }
  creationDate
  message
  answer
  replyCount
}
    `;
export const PostNodeFragmentDoc = gql`
    fragment PostNode on Post {
  id
  profileId
  profileName
  author {
    id
    name
  }
  creationDate
  message
  originalMessage
  postLanguage
  comments
  rating
  likes
  isLiked
  media {
    ... on Image {
      image
    }
    ... on Video {
      video {
        duration
        videoId
      }
    }
  }
  market {
    tradeType
    location
    coords
    currency
    price
    condition
  }
  quiz {
    topic
    multiple
    isVoted
    options {
      name
      lang
      original
      id
      votes
      percent
      userVote
    }
  }
  qa {
    question
    answer {
      ...CommentNode
    }
  }
}
    ${CommentNodeFragmentDoc}`;
export const UserInfoFragmentDoc = gql`
    fragment UserInfo on User {
  id
  email
  email_verified
  phone
  phone_verified
  unreadMessages
  name
  family_name
  about
  website
  facebook
  vkontakte
  twitter
  instagram
}
    `;
export const CollectionElementFragmentDoc = gql`
    fragment CollectionElement on Collection {
  id
  name
  description
  profiles {
    id
    title
    description
    defaultPicture
    rating
  }
}
    `;
export const GetUserInfoDocument = gql`
    query getUserInfo {
  userInfo {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = Apollo.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const LoginDocument = gql`
    query login($login: String, $pass: String) {
  login(login: $login, pass: $pass) {
    userInfo {
      ...UserInfo
    }
    token {
      RefreshToken
      AccessToken
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useLoginQuery__
 *
 * To run a query within a React component, call `useLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoginQuery({
 *   variables: {
 *      login: // value for 'login'
 *      pass: // value for 'pass'
 *   },
 * });
 */
export function useLoginQuery(baseOptions?: Apollo.QueryHookOptions<LoginQuery, LoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
      }
export function useLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LoginQuery, LoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LoginQuery, LoginQueryVariables>(LoginDocument, options);
        }
export type LoginQueryHookResult = ReturnType<typeof useLoginQuery>;
export type LoginLazyQueryHookResult = ReturnType<typeof useLoginLazyQuery>;
export type LoginQueryResult = Apollo.QueryResult<LoginQuery, LoginQueryVariables>;
export const RegisterDocument = gql`
    query register($name: String, $surname: String, $login: String, $pass: String, $agree: String) {
  register(
    name: $name
    surname: $surname
    login: $login
    pass: $pass
    agree: $agree
  ) {
    UserConfirmed
    CodeDeliveryDetails {
      Destination
      DeliveryMedium
      AttributeName
    }
    UserSub
    Username
  }
}
    `;

/**
 * __useRegisterQuery__
 *
 * To run a query within a React component, call `useRegisterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterQuery({
 *   variables: {
 *      name: // value for 'name'
 *      surname: // value for 'surname'
 *      login: // value for 'login'
 *      pass: // value for 'pass'
 *      agree: // value for 'agree'
 *   },
 * });
 */
export function useRegisterQuery(baseOptions?: Apollo.QueryHookOptions<RegisterQuery, RegisterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisterQuery, RegisterQueryVariables>(RegisterDocument, options);
      }
export function useRegisterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterQuery, RegisterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisterQuery, RegisterQueryVariables>(RegisterDocument, options);
        }
export type RegisterQueryHookResult = ReturnType<typeof useRegisterQuery>;
export type RegisterLazyQueryHookResult = ReturnType<typeof useRegisterLazyQuery>;
export type RegisterQueryResult = Apollo.QueryResult<RegisterQuery, RegisterQueryVariables>;
export const ConfirmUserDocument = gql`
    query confirmUser($login: String, $pass: String, $code: String) {
  confirm(login: $login, pass: $pass, code: $code) {
    userInfo {
      ...UserInfo
    }
    token {
      RefreshToken
      AccessToken
    }
  }
}
    ${UserInfoFragmentDoc}`;

/**
 * __useConfirmUserQuery__
 *
 * To run a query within a React component, call `useConfirmUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmUserQuery({
 *   variables: {
 *      login: // value for 'login'
 *      pass: // value for 'pass'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmUserQuery(baseOptions?: Apollo.QueryHookOptions<ConfirmUserQuery, ConfirmUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmUserQuery, ConfirmUserQueryVariables>(ConfirmUserDocument, options);
      }
export function useConfirmUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmUserQuery, ConfirmUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmUserQuery, ConfirmUserQueryVariables>(ConfirmUserDocument, options);
        }
export type ConfirmUserQueryHookResult = ReturnType<typeof useConfirmUserQuery>;
export type ConfirmUserLazyQueryHookResult = ReturnType<typeof useConfirmUserLazyQuery>;
export type ConfirmUserQueryResult = Apollo.QueryResult<ConfirmUserQuery, ConfirmUserQueryVariables>;
export const ResendConfirmUserDocument = gql`
    query resendConfirmUser($login: String) {
  resendConfirm(login: $login) {
    CodeDeliveryDetails {
      Destination
      DeliveryMedium
      AttributeName
    }
  }
}
    `;

/**
 * __useResendConfirmUserQuery__
 *
 * To run a query within a React component, call `useResendConfirmUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResendConfirmUserQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useResendConfirmUserQuery(baseOptions?: Apollo.QueryHookOptions<ResendConfirmUserQuery, ResendConfirmUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResendConfirmUserQuery, ResendConfirmUserQueryVariables>(ResendConfirmUserDocument, options);
      }
export function useResendConfirmUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResendConfirmUserQuery, ResendConfirmUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResendConfirmUserQuery, ResendConfirmUserQueryVariables>(ResendConfirmUserDocument, options);
        }
export type ResendConfirmUserQueryHookResult = ReturnType<typeof useResendConfirmUserQuery>;
export type ResendConfirmUserLazyQueryHookResult = ReturnType<typeof useResendConfirmUserLazyQuery>;
export type ResendConfirmUserQueryResult = Apollo.QueryResult<ResendConfirmUserQuery, ResendConfirmUserQueryVariables>;
export const ForgotPasswordDocument = gql`
    query forgotPassword($login: String) {
  forgot(login: $login) {
    CodeDeliveryDetails {
      Destination
      DeliveryMedium
      AttributeName
    }
  }
}
    `;

/**
 * __useForgotPasswordQuery__
 *
 * To run a query within a React component, call `useForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordQuery({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useForgotPasswordQuery(baseOptions?: Apollo.QueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
      }
export function useForgotPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export type ForgotPasswordQueryHookResult = ReturnType<typeof useForgotPasswordQuery>;
export type ForgotPasswordLazyQueryHookResult = ReturnType<typeof useForgotPasswordLazyQuery>;
export type ForgotPasswordQueryResult = Apollo.QueryResult<ForgotPasswordQuery, ForgotPasswordQueryVariables>;
export const ForgotPasswordConfirmDocument = gql`
    query forgotPasswordConfirm($login: String, $pass: String, $code: String) {
  forgotConfirm(login: $login, pass: $pass, code: $code) {
    id
  }
}
    `;

/**
 * __useForgotPasswordConfirmQuery__
 *
 * To run a query within a React component, call `useForgotPasswordConfirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordConfirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordConfirmQuery({
 *   variables: {
 *      login: // value for 'login'
 *      pass: // value for 'pass'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useForgotPasswordConfirmQuery(baseOptions?: Apollo.QueryHookOptions<ForgotPasswordConfirmQuery, ForgotPasswordConfirmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForgotPasswordConfirmQuery, ForgotPasswordConfirmQueryVariables>(ForgotPasswordConfirmDocument, options);
      }
export function useForgotPasswordConfirmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForgotPasswordConfirmQuery, ForgotPasswordConfirmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForgotPasswordConfirmQuery, ForgotPasswordConfirmQueryVariables>(ForgotPasswordConfirmDocument, options);
        }
export type ForgotPasswordConfirmQueryHookResult = ReturnType<typeof useForgotPasswordConfirmQuery>;
export type ForgotPasswordConfirmLazyQueryHookResult = ReturnType<typeof useForgotPasswordConfirmLazyQuery>;
export type ForgotPasswordConfirmQueryResult = Apollo.QueryResult<ForgotPasswordConfirmQuery, ForgotPasswordConfirmQueryVariables>;
export const AddCommentDocument = gql`
    mutation addComment($id: ID!, $message: String!) {
  createComment(id: $id, message: $message) {
    node {
      ...CommentNode
    }
    cursor
  }
}
    ${CommentNodeFragmentDoc}`;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation deleteComment($id: ID!) {
  deleteComment(id: $id) {
    id
  }
}
    `;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const EditCommentDocument = gql`
    mutation editComment($id: ID!, $message: String) {
  updateComment(id: $id, message: $message) {
    ...CommentNode
  }
}
    ${CommentNodeFragmentDoc}`;
export type EditCommentMutationFn = Apollo.MutationFunction<EditCommentMutation, EditCommentMutationVariables>;

/**
 * __useEditCommentMutation__
 *
 * To run a mutation, you first call `useEditCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCommentMutation, { data, loading, error }] = useEditCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useEditCommentMutation(baseOptions?: Apollo.MutationHookOptions<EditCommentMutation, EditCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCommentMutation, EditCommentMutationVariables>(EditCommentDocument, options);
      }
export type EditCommentMutationHookResult = ReturnType<typeof useEditCommentMutation>;
export type EditCommentMutationResult = Apollo.MutationResult<EditCommentMutation>;
export type EditCommentMutationOptions = Apollo.BaseMutationOptions<EditCommentMutation, EditCommentMutationVariables>;
export const GetCommentsDocument = gql`
    query getComments($id: ID!, $first: Int, $last: Int, $before: String, $after: String, $date: String) {
  comments(
    id: $id
    first: $first
    last: $last
    before: $before
    after: $after
    date: $date
  ) {
    edges {
      node {
        ...CommentNode
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${CommentNodeFragmentDoc}`;

/**
 * __useGetCommentsQuery__
 *
 * To run a query within a React component, call `useGetCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetCommentsQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
      }
export function useGetCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsQuery, GetCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsQuery, GetCommentsQueryVariables>(GetCommentsDocument, options);
        }
export type GetCommentsQueryHookResult = ReturnType<typeof useGetCommentsQuery>;
export type GetCommentsLazyQueryHookResult = ReturnType<typeof useGetCommentsLazyQuery>;
export type GetCommentsQueryResult = Apollo.QueryResult<GetCommentsQuery, GetCommentsQueryVariables>;
export const AddReplyDocument = gql`
    mutation addReply($id: ID!, $message: String!, $reply: ID) {
  createReply(id: $id, message: $message, reply: $reply) {
    node {
      id
      parentId
      author {
        id
        name
      }
      creationDate
      message
      replyTo
    }
    cursor
  }
}
    `;
export type AddReplyMutationFn = Apollo.MutationFunction<AddReplyMutation, AddReplyMutationVariables>;

/**
 * __useAddReplyMutation__
 *
 * To run a mutation, you first call `useAddReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReplyMutation, { data, loading, error }] = useAddReplyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *      reply: // value for 'reply'
 *   },
 * });
 */
export function useAddReplyMutation(baseOptions?: Apollo.MutationHookOptions<AddReplyMutation, AddReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReplyMutation, AddReplyMutationVariables>(AddReplyDocument, options);
      }
export type AddReplyMutationHookResult = ReturnType<typeof useAddReplyMutation>;
export type AddReplyMutationResult = Apollo.MutationResult<AddReplyMutation>;
export type AddReplyMutationOptions = Apollo.BaseMutationOptions<AddReplyMutation, AddReplyMutationVariables>;
export const EditReplyDocument = gql`
    mutation editReply($id: ID!, $message: String) {
  updateReply(id: $id, message: $message) {
    id
    parentId
    creationDate
    message
    author {
      name
      id
    }
    replyTo
  }
}
    `;
export type EditReplyMutationFn = Apollo.MutationFunction<EditReplyMutation, EditReplyMutationVariables>;

/**
 * __useEditReplyMutation__
 *
 * To run a mutation, you first call `useEditReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editReplyMutation, { data, loading, error }] = useEditReplyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useEditReplyMutation(baseOptions?: Apollo.MutationHookOptions<EditReplyMutation, EditReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditReplyMutation, EditReplyMutationVariables>(EditReplyDocument, options);
      }
export type EditReplyMutationHookResult = ReturnType<typeof useEditReplyMutation>;
export type EditReplyMutationResult = Apollo.MutationResult<EditReplyMutation>;
export type EditReplyMutationOptions = Apollo.BaseMutationOptions<EditReplyMutation, EditReplyMutationVariables>;
export const DeleteReplyDocument = gql`
    mutation deleteReply($id: ID!) {
  deleteReply(id: $id) {
    id
  }
}
    `;
export type DeleteReplyMutationFn = Apollo.MutationFunction<DeleteReplyMutation, DeleteReplyMutationVariables>;

/**
 * __useDeleteReplyMutation__
 *
 * To run a mutation, you first call `useDeleteReplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReplyMutation, { data, loading, error }] = useDeleteReplyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReplyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReplyMutation, DeleteReplyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReplyMutation, DeleteReplyMutationVariables>(DeleteReplyDocument, options);
      }
export type DeleteReplyMutationHookResult = ReturnType<typeof useDeleteReplyMutation>;
export type DeleteReplyMutationResult = Apollo.MutationResult<DeleteReplyMutation>;
export type DeleteReplyMutationOptions = Apollo.BaseMutationOptions<DeleteReplyMutation, DeleteReplyMutationVariables>;
export const GetRepliesDocument = gql`
    query getReplies($id: ID!, $first: Int, $last: Int, $before: String, $after: String, $date: String) {
  replies(
    id: $id
    first: $first
    last: $last
    before: $before
    after: $after
    date: $date
  ) {
    edges {
      node {
        id
        parentId
        author {
          id
          name
        }
        creationDate
        message
        replyTo
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useGetRepliesQuery__
 *
 * To run a query within a React component, call `useGetRepliesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRepliesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRepliesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetRepliesQuery(baseOptions: Apollo.QueryHookOptions<GetRepliesQuery, GetRepliesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRepliesQuery, GetRepliesQueryVariables>(GetRepliesDocument, options);
      }
export function useGetRepliesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRepliesQuery, GetRepliesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRepliesQuery, GetRepliesQueryVariables>(GetRepliesDocument, options);
        }
export type GetRepliesQueryHookResult = ReturnType<typeof useGetRepliesQuery>;
export type GetRepliesLazyQueryHookResult = ReturnType<typeof useGetRepliesLazyQuery>;
export type GetRepliesQueryResult = Apollo.QueryResult<GetRepliesQuery, GetRepliesQueryVariables>;
export const GetPostsDocument = gql`
    query getPosts($id: ID, $userId: ID, $cursor: String, $filter: Filter, $lang: String) {
  posts(
    id: $id
    userId: $userId
    first: 5
    after: $cursor
    filter: $filter
    lang: $lang
  ) {
    edges {
      node {
        ...PostNode
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    ${PostNodeFragmentDoc}`;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *      cursor: // value for 'cursor'
 *      filter: // value for 'filter'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostsQuery, GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostsQuery, GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<GetPostsQuery, GetPostsQueryVariables>;
export const GetPostDocument = gql`
    query getPost($id: ID!, $lang: String) {
  post(id: $id, lang: $lang) {
    ...PostNode
  }
}
    ${PostNodeFragmentDoc}`;

/**
 * __useGetPostQuery__
 *
 * To run a query within a React component, call `useGetPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetPostQuery(baseOptions: Apollo.QueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
      }
export function useGetPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPostQuery, GetPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPostQuery, GetPostQueryVariables>(GetPostDocument, options);
        }
export type GetPostQueryHookResult = ReturnType<typeof useGetPostQuery>;
export type GetPostLazyQueryHookResult = ReturnType<typeof useGetPostLazyQuery>;
export type GetPostQueryResult = Apollo.QueryResult<GetPostQuery, GetPostQueryVariables>;
export const AddPostDocument = gql`
    mutation addPost($id: ID!, $message: String!, $media: [String], $rating: Int, $marketplace: MarketInput, $qa: String, $quiz: QuizInput) {
  createPost(
    id: $id
    message: $message
    media: $media
    rating: $rating
    marketplace: $marketplace
    qa: $qa
    quiz: $quiz
  ) {
    ...PostNode
  }
}
    ${PostNodeFragmentDoc}`;
export type AddPostMutationFn = Apollo.MutationFunction<AddPostMutation, AddPostMutationVariables>;

/**
 * __useAddPostMutation__
 *
 * To run a mutation, you first call `useAddPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostMutation, { data, loading, error }] = useAddPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *      media: // value for 'media'
 *      rating: // value for 'rating'
 *      marketplace: // value for 'marketplace'
 *      qa: // value for 'qa'
 *      quiz: // value for 'quiz'
 *   },
 * });
 */
export function useAddPostMutation(baseOptions?: Apollo.MutationHookOptions<AddPostMutation, AddPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPostMutation, AddPostMutationVariables>(AddPostDocument, options);
      }
export type AddPostMutationHookResult = ReturnType<typeof useAddPostMutation>;
export type AddPostMutationResult = Apollo.MutationResult<AddPostMutation>;
export type AddPostMutationOptions = Apollo.BaseMutationOptions<AddPostMutation, AddPostMutationVariables>;
export const DeletePostDocument = gql`
    mutation deletePost($id: ID!) {
  deletePost(id: $id) {
    message
  }
}
    `;
export type DeletePostMutationFn = Apollo.MutationFunction<DeletePostMutation, DeletePostMutationVariables>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePostMutation(baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(DeletePostDocument, options);
      }
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<DeletePostMutation, DeletePostMutationVariables>;
export const UpdatePostDocument = gql`
    mutation updatePost($id: ID!, $update: Update) {
  updatePost(id: $id, update: $update) {
    ...PostNode
  }
}
    ${PostNodeFragmentDoc}`;
export type UpdatePostMutationFn = Apollo.MutationFunction<UpdatePostMutation, UpdatePostMutationVariables>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      update: // value for 'update'
 *   },
 * });
 */
export function useUpdatePostMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(UpdatePostDocument, options);
      }
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<UpdatePostMutation, UpdatePostMutationVariables>;
export const AddPostImageDocument = gql`
    mutation addPostImage($image: String!) {
  addPostImage(image: $image)
}
    `;
export type AddPostImageMutationFn = Apollo.MutationFunction<AddPostImageMutation, AddPostImageMutationVariables>;

/**
 * __useAddPostImageMutation__
 *
 * To run a mutation, you first call `useAddPostImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostImageMutation, { data, loading, error }] = useAddPostImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddPostImageMutation(baseOptions?: Apollo.MutationHookOptions<AddPostImageMutation, AddPostImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPostImageMutation, AddPostImageMutationVariables>(AddPostImageDocument, options);
      }
export type AddPostImageMutationHookResult = ReturnType<typeof useAddPostImageMutation>;
export type AddPostImageMutationResult = Apollo.MutationResult<AddPostImageMutation>;
export type AddPostImageMutationOptions = Apollo.BaseMutationOptions<AddPostImageMutation, AddPostImageMutationVariables>;
export const GetRatingFilterDocument = gql`
    query getRatingFilter($id: ID!, $filter: Filter) {
  filters(id: $id, filter: $filter) {
    rating {
      five
      four
      one
      three
      two
    }
  }
}
    `;

/**
 * __useGetRatingFilterQuery__
 *
 * To run a query within a React component, call `useGetRatingFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRatingFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRatingFilterQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRatingFilterQuery(baseOptions: Apollo.QueryHookOptions<GetRatingFilterQuery, GetRatingFilterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRatingFilterQuery, GetRatingFilterQueryVariables>(GetRatingFilterDocument, options);
      }
export function useGetRatingFilterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRatingFilterQuery, GetRatingFilterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRatingFilterQuery, GetRatingFilterQueryVariables>(GetRatingFilterDocument, options);
        }
export type GetRatingFilterQueryHookResult = ReturnType<typeof useGetRatingFilterQuery>;
export type GetRatingFilterLazyQueryHookResult = ReturnType<typeof useGetRatingFilterLazyQuery>;
export type GetRatingFilterQueryResult = Apollo.QueryResult<GetRatingFilterQuery, GetRatingFilterQueryVariables>;
export const GetFullprofileDocument = gql`
    query getFullprofile($id: ID!, $lang: String) {
  profile(id: $id, lang: $lang) {
    id
    title
    description
    categories {
      id
      slug
      name
    }
    defaultPicture
    rating
    isSubscribed
    postCounters {
      rating
      image
      video
      marketplace
      qa
    }
    imagesCount
    marketplaceCount
    parameters {
      categoryId
      categoryName
      parameterGroups {
        id
        groupName
        params {
          id
          name
          unit {
            name
          }
          inVariant
          value {
            String {
              value
              valueId
            }
            Number
            Boolean
            StringColor {
              value
              color
            }
            Group {
              group
              value
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetFullprofileQuery__
 *
 * To run a query within a React component, call `useGetFullprofileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFullprofileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFullprofileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetFullprofileQuery(baseOptions: Apollo.QueryHookOptions<GetFullprofileQuery, GetFullprofileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFullprofileQuery, GetFullprofileQueryVariables>(GetFullprofileDocument, options);
      }
export function useGetFullprofileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFullprofileQuery, GetFullprofileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFullprofileQuery, GetFullprofileQueryVariables>(GetFullprofileDocument, options);
        }
export type GetFullprofileQueryHookResult = ReturnType<typeof useGetFullprofileQuery>;
export type GetFullprofileLazyQueryHookResult = ReturnType<typeof useGetFullprofileLazyQuery>;
export type GetFullprofileQueryResult = Apollo.QueryResult<GetFullprofileQuery, GetFullprofileQueryVariables>;
export const GetProfileimagesDocument = gql`
    query getProfileimages($id: ID!) {
  images(id: $id)
}
    `;

/**
 * __useGetProfileimagesQuery__
 *
 * To run a query within a React component, call `useGetProfileimagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileimagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileimagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileimagesQuery(baseOptions: Apollo.QueryHookOptions<GetProfileimagesQuery, GetProfileimagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileimagesQuery, GetProfileimagesQueryVariables>(GetProfileimagesDocument, options);
      }
export function useGetProfileimagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileimagesQuery, GetProfileimagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileimagesQuery, GetProfileimagesQueryVariables>(GetProfileimagesDocument, options);
        }
export type GetProfileimagesQueryHookResult = ReturnType<typeof useGetProfileimagesQuery>;
export type GetProfileimagesLazyQueryHookResult = ReturnType<typeof useGetProfileimagesLazyQuery>;
export type GetProfileimagesQueryResult = Apollo.QueryResult<GetProfileimagesQuery, GetProfileimagesQueryVariables>;
export const UploadProfileImageDocument = gql`
    mutation UploadProfileImage($image: String!, $id: ID!) {
  addImage(image: $image, id: $id)
}
    `;
export type UploadProfileImageMutationFn = Apollo.MutationFunction<UploadProfileImageMutation, UploadProfileImageMutationVariables>;

/**
 * __useUploadProfileImageMutation__
 *
 * To run a mutation, you first call `useUploadProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfileImageMutation, { data, loading, error }] = useUploadProfileImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUploadProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadProfileImageMutation, UploadProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProfileImageMutation, UploadProfileImageMutationVariables>(UploadProfileImageDocument, options);
      }
export type UploadProfileImageMutationHookResult = ReturnType<typeof useUploadProfileImageMutation>;
export type UploadProfileImageMutationResult = Apollo.MutationResult<UploadProfileImageMutation>;
export type UploadProfileImageMutationOptions = Apollo.BaseMutationOptions<UploadProfileImageMutation, UploadProfileImageMutationVariables>;
export const GetShortProfileDocument = gql`
    query getShortProfile($id: ID!, $lang: String) {
  profile(id: $id, lang: $lang) {
    id
    title
    categories {
      id
      name
      slug
    }
    defaultPicture
    rating
  }
}
    `;

/**
 * __useGetShortProfileQuery__
 *
 * To run a query within a React component, call `useGetShortProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShortProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShortProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetShortProfileQuery(baseOptions: Apollo.QueryHookOptions<GetShortProfileQuery, GetShortProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShortProfileQuery, GetShortProfileQueryVariables>(GetShortProfileDocument, options);
      }
export function useGetShortProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShortProfileQuery, GetShortProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShortProfileQuery, GetShortProfileQueryVariables>(GetShortProfileDocument, options);
        }
export type GetShortProfileQueryHookResult = ReturnType<typeof useGetShortProfileQuery>;
export type GetShortProfileLazyQueryHookResult = ReturnType<typeof useGetShortProfileLazyQuery>;
export type GetShortProfileQueryResult = Apollo.QueryResult<GetShortProfileQuery, GetShortProfileQueryVariables>;
export const GetEditprofileDocument = gql`
    query getEditprofile($id: ID!, $lang: String) {
  profile(id: $id, lang: $lang) {
    id
    title
    description
    model
    barcode
    countries
    categories {
      id
      name
    }
    parameters {
      categoryId
      categoryName
      parameterGroups {
        id
        groupName
        params {
          id
          name
          parameterType
          unit {
            id
            name
          }
          value {
            String {
              value
              valueId
            }
            Number
            Boolean
            StringColor {
              color
              value
            }
            Group {
              value
              group
            }
          }
        }
      }
    }
    modifications {
      id
      title
      barcode
      images
      parameters {
        id
        value {
          Group {
            value
            group
          }
          StringColor {
            color
            value
          }
          Boolean
          Number
          String {
            value
            valueId
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetEditprofileQuery__
 *
 * To run a query within a React component, call `useGetEditprofileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditprofileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditprofileQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetEditprofileQuery(baseOptions: Apollo.QueryHookOptions<GetEditprofileQuery, GetEditprofileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEditprofileQuery, GetEditprofileQueryVariables>(GetEditprofileDocument, options);
      }
export function useGetEditprofileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEditprofileQuery, GetEditprofileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEditprofileQuery, GetEditprofileQueryVariables>(GetEditprofileDocument, options);
        }
export type GetEditprofileQueryHookResult = ReturnType<typeof useGetEditprofileQuery>;
export type GetEditprofileLazyQueryHookResult = ReturnType<typeof useGetEditprofileLazyQuery>;
export type GetEditprofileQueryResult = Apollo.QueryResult<GetEditprofileQuery, GetEditprofileQueryVariables>;
export const GetSearchProfilesDocument = gql`
    query getSearchProfiles($search: String!, $lang: String, $offset: Int, $limit: Int) {
  profiles(search: $search, lang: $lang, offset: $offset, limit: $limit) {
    profiles {
      id
      title
      description
      creationDate
      categories {
        id
        name
        slug
        count
      }
      defaultPicture
      status
      rating
      minOffer {
        price
        currency
        author {
          id
          name
        }
      }
      postCounters {
        marketplace
        image
        video
      }
    }
    totalCount
  }
}
    `;

/**
 * __useGetSearchProfilesQuery__
 *
 * To run a query within a React component, call `useGetSearchProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchProfilesQuery({
 *   variables: {
 *      search: // value for 'search'
 *      lang: // value for 'lang'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetSearchProfilesQuery(baseOptions: Apollo.QueryHookOptions<GetSearchProfilesQuery, GetSearchProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSearchProfilesQuery, GetSearchProfilesQueryVariables>(GetSearchProfilesDocument, options);
      }
export function useGetSearchProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSearchProfilesQuery, GetSearchProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSearchProfilesQuery, GetSearchProfilesQueryVariables>(GetSearchProfilesDocument, options);
        }
export type GetSearchProfilesQueryHookResult = ReturnType<typeof useGetSearchProfilesQuery>;
export type GetSearchProfilesLazyQueryHookResult = ReturnType<typeof useGetSearchProfilesLazyQuery>;
export type GetSearchProfilesQueryResult = Apollo.QueryResult<GetSearchProfilesQuery, GetSearchProfilesQueryVariables>;
export const DialogsDocument = gql`
    query dialogs($id: ID!, $cursor: String) {
  dialogs(id: $id, first: 5, after: $cursor) {
    edges {
      node {
        id
        participant {
          id
          name
        }
        blockedBy
        creationDate
        updatedDate
        lastMessage {
          message
        }
        unreadMessages
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useDialogsQuery__
 *
 * To run a query within a React component, call `useDialogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDialogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDialogsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useDialogsQuery(baseOptions: Apollo.QueryHookOptions<DialogsQuery, DialogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DialogsQuery, DialogsQueryVariables>(DialogsDocument, options);
      }
export function useDialogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DialogsQuery, DialogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DialogsQuery, DialogsQueryVariables>(DialogsDocument, options);
        }
export type DialogsQueryHookResult = ReturnType<typeof useDialogsQuery>;
export type DialogsLazyQueryHookResult = ReturnType<typeof useDialogsLazyQuery>;
export type DialogsQueryResult = Apollo.QueryResult<DialogsQuery, DialogsQueryVariables>;
export const MessagesDocument = gql`
    query messages($id: ID!, $before: String) {
  messages(id: $id, first: 10, before: $before) {
    edges {
      node {
        id
        dialogId
        sender {
          id
          name
        }
        status
        creationDate
        message
        images {
          image
        }
        postInfo {
          id
          imageId
          message
          price
          currency
        }
      }
      cursor
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const CreateMessageDocument = gql`
    mutation createMessage($id: ID, $participant: String, $message: String!, $images: [String], $postId: ID) {
  createMessage(
    id: $id
    participant: $participant
    message: $message
    images: $images
    postId: $postId
  ) {
    id
    dialogId
    sender {
      id
      name
    }
    status
    creationDate
    message
    images {
      image
    }
    postInfo {
      id
      message
      price
      currency
    }
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      participant: // value for 'participant'
 *      message: // value for 'message'
 *      images: // value for 'images'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const AddMessageImageDocument = gql`
    mutation addMessageImage($image: String!) {
  addMessageImage(image: $image)
}
    `;
export type AddMessageImageMutationFn = Apollo.MutationFunction<AddMessageImageMutation, AddMessageImageMutationVariables>;

/**
 * __useAddMessageImageMutation__
 *
 * To run a mutation, you first call `useAddMessageImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageImageMutation, { data, loading, error }] = useAddMessageImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddMessageImageMutation(baseOptions?: Apollo.MutationHookOptions<AddMessageImageMutation, AddMessageImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddMessageImageMutation, AddMessageImageMutationVariables>(AddMessageImageDocument, options);
      }
export type AddMessageImageMutationHookResult = ReturnType<typeof useAddMessageImageMutation>;
export type AddMessageImageMutationResult = Apollo.MutationResult<AddMessageImageMutation>;
export type AddMessageImageMutationOptions = Apollo.BaseMutationOptions<AddMessageImageMutation, AddMessageImageMutationVariables>;
export const ToggleBlockDialogDocument = gql`
    mutation toggleBlockDialog($id: ID!) {
  toggleBlockDialog(id: $id) {
    id
    participant {
      id
      name
    }
    blockedBy
    creationDate
    updatedDate
    lastMessage {
      message
    }
    unreadMessages
  }
}
    `;
export type ToggleBlockDialogMutationFn = Apollo.MutationFunction<ToggleBlockDialogMutation, ToggleBlockDialogMutationVariables>;

/**
 * __useToggleBlockDialogMutation__
 *
 * To run a mutation, you first call `useToggleBlockDialogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBlockDialogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBlockDialogMutation, { data, loading, error }] = useToggleBlockDialogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleBlockDialogMutation(baseOptions?: Apollo.MutationHookOptions<ToggleBlockDialogMutation, ToggleBlockDialogMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleBlockDialogMutation, ToggleBlockDialogMutationVariables>(ToggleBlockDialogDocument, options);
      }
export type ToggleBlockDialogMutationHookResult = ReturnType<typeof useToggleBlockDialogMutation>;
export type ToggleBlockDialogMutationResult = Apollo.MutationResult<ToggleBlockDialogMutation>;
export type ToggleBlockDialogMutationOptions = Apollo.BaseMutationOptions<ToggleBlockDialogMutation, ToggleBlockDialogMutationVariables>;
export const CreateProfileLinkDocument = gql`
    mutation createProfileLink($link: String!) {
  createProfileLink(link: $link) {
    id
  }
}
    `;
export type CreateProfileLinkMutationFn = Apollo.MutationFunction<CreateProfileLinkMutation, CreateProfileLinkMutationVariables>;

/**
 * __useCreateProfileLinkMutation__
 *
 * To run a mutation, you first call `useCreateProfileLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileLinkMutation, { data, loading, error }] = useCreateProfileLinkMutation({
 *   variables: {
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCreateProfileLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileLinkMutation, CreateProfileLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileLinkMutation, CreateProfileLinkMutationVariables>(CreateProfileLinkDocument, options);
      }
export type CreateProfileLinkMutationHookResult = ReturnType<typeof useCreateProfileLinkMutation>;
export type CreateProfileLinkMutationResult = Apollo.MutationResult<CreateProfileLinkMutation>;
export type CreateProfileLinkMutationOptions = Apollo.BaseMutationOptions<CreateProfileLinkMutation, CreateProfileLinkMutationVariables>;
export const CreateProfileDocument = gql`
    mutation createProfile($title: String!, $description: String, $model: String, $barcode: [String], $categories: [String], $images: [String], $parameters: [ProfileParameterInput], $modifications: [ModificationInput], $lang: String) {
  createProfile(
    title: $title
    description: $description
    model: $model
    barcode: $barcode
    categories: $categories
    images: $images
    parameters: $parameters
    modifications: $modifications
    lang: $lang
  ) {
    id
  }
}
    `;
export type CreateProfileMutationFn = Apollo.MutationFunction<CreateProfileMutation, CreateProfileMutationVariables>;

/**
 * __useCreateProfileMutation__
 *
 * To run a mutation, you first call `useCreateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProfileMutation, { data, loading, error }] = useCreateProfileMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      model: // value for 'model'
 *      barcode: // value for 'barcode'
 *      categories: // value for 'categories'
 *      images: // value for 'images'
 *      parameters: // value for 'parameters'
 *      modifications: // value for 'modifications'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCreateProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateProfileMutation, CreateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProfileMutation, CreateProfileMutationVariables>(CreateProfileDocument, options);
      }
export type CreateProfileMutationHookResult = ReturnType<typeof useCreateProfileMutation>;
export type CreateProfileMutationResult = Apollo.MutationResult<CreateProfileMutation>;
export type CreateProfileMutationOptions = Apollo.BaseMutationOptions<CreateProfileMutation, CreateProfileMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation updateProfile($id: ID!, $title: String!, $description: String, $model: String, $barcode: String, $categories: [String], $parameters: [ProfileParameterInput], $modifications: [ModificationInput], $lang: String) {
  updateProfile(
    id: $id
    title: $title
    description: $description
    model: $model
    barcode: $barcode
    categories: $categories
    parameters: $parameters
    modifications: $modifications
    lang: $lang
  ) {
    id
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      model: // value for 'model'
 *      barcode: // value for 'barcode'
 *      categories: // value for 'categories'
 *      parameters: // value for 'parameters'
 *      modifications: // value for 'modifications'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const AddProfileImageDocument = gql`
    mutation addProfileImage($image: String!) {
  addProfileImage(image: $image)
}
    `;
export type AddProfileImageMutationFn = Apollo.MutationFunction<AddProfileImageMutation, AddProfileImageMutationVariables>;

/**
 * __useAddProfileImageMutation__
 *
 * To run a mutation, you first call `useAddProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProfileImageMutation, { data, loading, error }] = useAddProfileImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<AddProfileImageMutation, AddProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddProfileImageMutation, AddProfileImageMutationVariables>(AddProfileImageDocument, options);
      }
export type AddProfileImageMutationHookResult = ReturnType<typeof useAddProfileImageMutation>;
export type AddProfileImageMutationResult = Apollo.MutationResult<AddProfileImageMutation>;
export type AddProfileImageMutationOptions = Apollo.BaseMutationOptions<AddProfileImageMutation, AddProfileImageMutationVariables>;
export const GetCategoryInfoDocument = gql`
    query getCategoryInfo($id: ID!, $lang: String) {
  category(id: $id, lang: $lang) {
    id
    name
    status
    parameterGroups {
      id
      groupName
      params {
        id
        name
        parameterType
        isFilter
        inVariant
        groups
        unit {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoryInfoQuery__
 *
 * To run a query within a React component, call `useGetCategoryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCategoryInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>(GetCategoryInfoDocument, options);
      }
export function useGetCategoryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>(GetCategoryInfoDocument, options);
        }
export type GetCategoryInfoQueryHookResult = ReturnType<typeof useGetCategoryInfoQuery>;
export type GetCategoryInfoLazyQueryHookResult = ReturnType<typeof useGetCategoryInfoLazyQuery>;
export type GetCategoryInfoQueryResult = Apollo.QueryResult<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>;
export const UnitsDocument = gql`
    query units($lang: String) {
  units(lang: $lang) {
    id
    name
  }
}
    `;

/**
 * __useUnitsQuery__
 *
 * To run a query within a React component, call `useUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUnitsQuery(baseOptions?: Apollo.QueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, options);
      }
export function useUnitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, options);
        }
export type UnitsQueryHookResult = ReturnType<typeof useUnitsQuery>;
export type UnitsLazyQueryHookResult = ReturnType<typeof useUnitsLazyQuery>;
export type UnitsQueryResult = Apollo.QueryResult<UnitsQuery, UnitsQueryVariables>;
export const GetCategoriesByNameDocument = gql`
    query getCategoriesByName($search: String, $lang: String) {
  categories(search: $search, lang: $lang) {
    id
    name
  }
}
    `;

/**
 * __useGetCategoriesByNameQuery__
 *
 * To run a query within a React component, call `useGetCategoriesByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesByNameQuery({
 *   variables: {
 *      search: // value for 'search'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCategoriesByNameQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoriesByNameQuery, GetCategoriesByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesByNameQuery, GetCategoriesByNameQueryVariables>(GetCategoriesByNameDocument, options);
      }
export function useGetCategoriesByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesByNameQuery, GetCategoriesByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesByNameQuery, GetCategoriesByNameQueryVariables>(GetCategoriesByNameDocument, options);
        }
export type GetCategoriesByNameQueryHookResult = ReturnType<typeof useGetCategoriesByNameQuery>;
export type GetCategoriesByNameLazyQueryHookResult = ReturnType<typeof useGetCategoriesByNameLazyQuery>;
export type GetCategoriesByNameQueryResult = Apollo.QueryResult<GetCategoriesByNameQuery, GetCategoriesByNameQueryVariables>;
export const GetRelatedCategoriesSuggestionDocument = gql`
    query getRelatedCategoriesSuggestion($categories: [String], $lang: String) {
  categories(categories: $categories, lang: $lang) {
    id
    name
  }
}
    `;

/**
 * __useGetRelatedCategoriesSuggestionQuery__
 *
 * To run a query within a React component, call `useGetRelatedCategoriesSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedCategoriesSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedCategoriesSuggestionQuery({
 *   variables: {
 *      categories: // value for 'categories'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetRelatedCategoriesSuggestionQuery(baseOptions?: Apollo.QueryHookOptions<GetRelatedCategoriesSuggestionQuery, GetRelatedCategoriesSuggestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRelatedCategoriesSuggestionQuery, GetRelatedCategoriesSuggestionQueryVariables>(GetRelatedCategoriesSuggestionDocument, options);
      }
export function useGetRelatedCategoriesSuggestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelatedCategoriesSuggestionQuery, GetRelatedCategoriesSuggestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRelatedCategoriesSuggestionQuery, GetRelatedCategoriesSuggestionQueryVariables>(GetRelatedCategoriesSuggestionDocument, options);
        }
export type GetRelatedCategoriesSuggestionQueryHookResult = ReturnType<typeof useGetRelatedCategoriesSuggestionQuery>;
export type GetRelatedCategoriesSuggestionLazyQueryHookResult = ReturnType<typeof useGetRelatedCategoriesSuggestionLazyQuery>;
export type GetRelatedCategoriesSuggestionQueryResult = Apollo.QueryResult<GetRelatedCategoriesSuggestionQuery, GetRelatedCategoriesSuggestionQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($name: String!, $categories: [String], $parameters: [ParametersGroupInput]) {
  createCategory(
    name: $name
    categories: $categories
    parameterGroups: $parameters
  ) {
    id
    message
  }
}
    `;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      categories: // value for 'categories'
 *      parameters: // value for 'parameters'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const GetCategoryFullInfoDocument = gql`
    query getCategoryFullInfo($id: ID!, $categories: [String], $lang: String) {
  category(id: $id, lang: $lang) {
    id
    name
    parameterGroups {
      id
      groupName
      params {
        id
        name
        parameterType
        isFilter
        inVariant
        groups
        unit {
          id
        }
      }
    }
  }
  categories(categories: $categories, lang: $lang) {
    id
    name
  }
}
    `;

/**
 * __useGetCategoryFullInfoQuery__
 *
 * To run a query within a React component, call `useGetCategoryFullInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryFullInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryFullInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      categories: // value for 'categories'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCategoryFullInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryFullInfoQuery, GetCategoryFullInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryFullInfoQuery, GetCategoryFullInfoQueryVariables>(GetCategoryFullInfoDocument, options);
      }
export function useGetCategoryFullInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryFullInfoQuery, GetCategoryFullInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryFullInfoQuery, GetCategoryFullInfoQueryVariables>(GetCategoryFullInfoDocument, options);
        }
export type GetCategoryFullInfoQueryHookResult = ReturnType<typeof useGetCategoryFullInfoQuery>;
export type GetCategoryFullInfoLazyQueryHookResult = ReturnType<typeof useGetCategoryFullInfoLazyQuery>;
export type GetCategoryFullInfoQueryResult = Apollo.QueryResult<GetCategoryFullInfoQuery, GetCategoryFullInfoQueryVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($id: ID!, $name: String!, $parentIds: [String], $parameters: [ParametersGroupInput], $lang: String) {
  updateCategory(
    id: $id
    name: $name
    parentIds: $parentIds
    parameterGroups: $parameters
    lang: $lang
  ) {
    id
    message
  }
}
    `;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      parentIds: // value for 'parentIds'
 *      parameters: // value for 'parameters'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const PopularCategoriesDocument = gql`
    query popularCategories($lang: String, $limit: Int) {
  popularCategories(lang: $lang, limit: $limit) {
    id
    slug
    name
    count
  }
}
    `;

/**
 * __usePopularCategoriesQuery__
 *
 * To run a query within a React component, call `usePopularCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePopularCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePopularCategoriesQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePopularCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PopularCategoriesQuery, PopularCategoriesQueryVariables>(PopularCategoriesDocument, options);
      }
export function usePopularCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PopularCategoriesQuery, PopularCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PopularCategoriesQuery, PopularCategoriesQueryVariables>(PopularCategoriesDocument, options);
        }
export type PopularCategoriesQueryHookResult = ReturnType<typeof usePopularCategoriesQuery>;
export type PopularCategoriesLazyQueryHookResult = ReturnType<typeof usePopularCategoriesLazyQuery>;
export type PopularCategoriesQueryResult = Apollo.QueryResult<PopularCategoriesQuery, PopularCategoriesQueryVariables>;
export const GetCategoryProfilesDocument = gql`
    query getCategoryProfiles($category: String, $country: String, $lang: String, $search: String, $filter: [ProfileParameter], $location: LocationFilter, $inStock: Boolean, $price: NumberFilter, $brand: [String], $sort: String, $offset: Int, $limit: Int) {
  profiles(
    category: $category
    country: $country
    lang: $lang
    search: $search
    filter: $filter
    location: $location
    inStock: $inStock
    price: $price
    brand: $brand
    sort: $sort
    offset: $offset
    limit: $limit
  ) {
    profiles {
      id
      title
      description
      categories {
        id
        name
      }
      defaultPicture
      rating
      minOffer {
        price
        currency
        author {
          id
          name
        }
      }
      postCounters {
        rating
        image
        video
        marketplace
      }
      marketplaceCount
    }
    totalCount
  }
}
    `;

/**
 * __useGetCategoryProfilesQuery__
 *
 * To run a query within a React component, call `useGetCategoryProfilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryProfilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryProfilesQuery({
 *   variables: {
 *      category: // value for 'category'
 *      country: // value for 'country'
 *      lang: // value for 'lang'
 *      search: // value for 'search'
 *      filter: // value for 'filter'
 *      location: // value for 'location'
 *      inStock: // value for 'inStock'
 *      price: // value for 'price'
 *      brand: // value for 'brand'
 *      sort: // value for 'sort'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetCategoryProfilesQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryProfilesQuery, GetCategoryProfilesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryProfilesQuery, GetCategoryProfilesQueryVariables>(GetCategoryProfilesDocument, options);
      }
export function useGetCategoryProfilesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryProfilesQuery, GetCategoryProfilesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryProfilesQuery, GetCategoryProfilesQueryVariables>(GetCategoryProfilesDocument, options);
        }
export type GetCategoryProfilesQueryHookResult = ReturnType<typeof useGetCategoryProfilesQuery>;
export type GetCategoryProfilesLazyQueryHookResult = ReturnType<typeof useGetCategoryProfilesLazyQuery>;
export type GetCategoryProfilesQueryResult = Apollo.QueryResult<GetCategoryProfilesQuery, GetCategoryProfilesQueryVariables>;
export const GetProfilesByIdDocument = gql`
    query getProfilesById($ids: [String], $lang: String) {
  profilesById(ids: $ids, lang: $lang) {
    id
    title
    categories {
      id
      name
    }
    postCounters {
      rating
    }
    defaultPicture
    rating
    parameters {
      categoryId
      categoryName
      parameterGroups {
        id
        groupName
        params {
          id
          name
          unit {
            id
            name
          }
          value {
            String {
              value
              valueId
            }
            Number
            Boolean
            StringColor {
              value
              color
            }
            Group {
              value
              group
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProfilesByIdQuery__
 *
 * To run a query within a React component, call `useGetProfilesByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfilesByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfilesByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetProfilesByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetProfilesByIdQuery, GetProfilesByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfilesByIdQuery, GetProfilesByIdQueryVariables>(GetProfilesByIdDocument, options);
      }
export function useGetProfilesByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfilesByIdQuery, GetProfilesByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfilesByIdQuery, GetProfilesByIdQueryVariables>(GetProfilesByIdDocument, options);
        }
export type GetProfilesByIdQueryHookResult = ReturnType<typeof useGetProfilesByIdQuery>;
export type GetProfilesByIdLazyQueryHookResult = ReturnType<typeof useGetProfilesByIdLazyQuery>;
export type GetProfilesByIdQueryResult = Apollo.QueryResult<GetProfilesByIdQuery, GetProfilesByIdQueryVariables>;
export const CategoryFiltersDocument = gql`
    query categoryFilters($id: ID!, $filter: [ProfileParameter], $price: NumberFilter, $inStock: Boolean, $brand: [String], $lang: String) {
  categoryFilters(
    id: $id
    filter: $filter
    price: $price
    inStock: $inStock
    brand: $brand
    lang: $lang
  ) {
    id
    name
    parameterType
    valuesInfo {
      String {
        valueId
        value
        count
      }
      Boolean {
        valueId
        value
        count
      }
      Number {
        min
        max
        unit {
          id
          name
        }
      }
      StringColor {
        valueId
        value
        count
        color
      }
      Group {
        group
        value
        valueId
        count
      }
    }
  }
}
    `;

/**
 * __useCategoryFiltersQuery__
 *
 * To run a query within a React component, call `useCategoryFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryFiltersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *      price: // value for 'price'
 *      inStock: // value for 'inStock'
 *      brand: // value for 'brand'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useCategoryFiltersQuery(baseOptions: Apollo.QueryHookOptions<CategoryFiltersQuery, CategoryFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CategoryFiltersQuery, CategoryFiltersQueryVariables>(CategoryFiltersDocument, options);
      }
export function useCategoryFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryFiltersQuery, CategoryFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CategoryFiltersQuery, CategoryFiltersQueryVariables>(CategoryFiltersDocument, options);
        }
export type CategoryFiltersQueryHookResult = ReturnType<typeof useCategoryFiltersQuery>;
export type CategoryFiltersLazyQueryHookResult = ReturnType<typeof useCategoryFiltersLazyQuery>;
export type CategoryFiltersQueryResult = Apollo.QueryResult<CategoryFiltersQuery, CategoryFiltersQueryVariables>;
export const GetCategoryNameDocument = gql`
    query getCategoryName($id: ID!, $category: String, $lang: String) {
  category(id: $id, lang: $lang) {
    id
    name
    slug
    filter
  }
  parentChildrenCategories(category: $category, lang: $lang) {
    count
    level
    parents {
      id
      name
      count
      slug
    }
    children {
      slug
      count
      name
      id
    }
  }
}
    `;

/**
 * __useGetCategoryNameQuery__
 *
 * To run a query within a React component, call `useGetCategoryNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *      category: // value for 'category'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCategoryNameQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryNameQuery, GetCategoryNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryNameQuery, GetCategoryNameQueryVariables>(GetCategoryNameDocument, options);
      }
export function useGetCategoryNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryNameQuery, GetCategoryNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryNameQuery, GetCategoryNameQueryVariables>(GetCategoryNameDocument, options);
        }
export type GetCategoryNameQueryHookResult = ReturnType<typeof useGetCategoryNameQuery>;
export type GetCategoryNameLazyQueryHookResult = ReturnType<typeof useGetCategoryNameLazyQuery>;
export type GetCategoryNameQueryResult = Apollo.QueryResult<GetCategoryNameQuery, GetCategoryNameQueryVariables>;
export const GetAllCategoriesDocument = gql`
    query getAllCategories($view: String, $offset: Int, $lang: String) {
  allCategories(view: $view, offset: $offset, lang: $lang) {
    id
    slug
    count
    name
    parentIds
  }
}
    `;

/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *      view: // value for 'view'
 *      offset: // value for 'offset'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAllCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
      }
export function useGetAllCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(GetAllCategoriesDocument, options);
        }
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const GetRecentActivityDocument = gql`
    query getRecentActivity($limit: Int, $lang: String) {
  recentActivity(limit: $limit, lang: $lang) {
    ... on ProfileForSearch {
      id
      creationDate
      title
      description
      rating
      defaultPicture
      categories {
        id
        name
      }
    }
    ... on Post {
      id
      profileId
      profileName
      creationDate
      message
      author {
        id
        name
      }
      postRating: rating
      media {
        ... on Image {
          image
        }
      }
      market {
        price
        currency
      }
    }
    ... on Comment {
      id
      postId
      profileId
      profileName
      creationDate
      message
      author {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetRecentActivityQuery__
 *
 * To run a query within a React component, call `useGetRecentActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecentActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecentActivityQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetRecentActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetRecentActivityQuery, GetRecentActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecentActivityQuery, GetRecentActivityQueryVariables>(GetRecentActivityDocument, options);
      }
export function useGetRecentActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecentActivityQuery, GetRecentActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecentActivityQuery, GetRecentActivityQueryVariables>(GetRecentActivityDocument, options);
        }
export type GetRecentActivityQueryHookResult = ReturnType<typeof useGetRecentActivityQuery>;
export type GetRecentActivityLazyQueryHookResult = ReturnType<typeof useGetRecentActivityLazyQuery>;
export type GetRecentActivityQueryResult = Apollo.QueryResult<GetRecentActivityQuery, GetRecentActivityQueryVariables>;
export const GetParameterValuesDocument = gql`
    query getParameterValues($id: ID!, $search: String, $lang: String) {
  parameterValues(id: $id, search: $search, lang: $lang)
}
    `;

/**
 * __useGetParameterValuesQuery__
 *
 * To run a query within a React component, call `useGetParameterValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParameterValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParameterValuesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      search: // value for 'search'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetParameterValuesQuery(baseOptions: Apollo.QueryHookOptions<GetParameterValuesQuery, GetParameterValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParameterValuesQuery, GetParameterValuesQueryVariables>(GetParameterValuesDocument, options);
      }
export function useGetParameterValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParameterValuesQuery, GetParameterValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParameterValuesQuery, GetParameterValuesQueryVariables>(GetParameterValuesDocument, options);
        }
export type GetParameterValuesQueryHookResult = ReturnType<typeof useGetParameterValuesQuery>;
export type GetParameterValuesLazyQueryHookResult = ReturnType<typeof useGetParameterValuesLazyQuery>;
export type GetParameterValuesQueryResult = Apollo.QueryResult<GetParameterValuesQuery, GetParameterValuesQueryVariables>;
export const UploadUserPhotoDocument = gql`
    mutation uploadUserPhoto($picture: String!) {
  uploadPhoto(picture: $picture)
}
    `;
export type UploadUserPhotoMutationFn = Apollo.MutationFunction<UploadUserPhotoMutation, UploadUserPhotoMutationVariables>;

/**
 * __useUploadUserPhotoMutation__
 *
 * To run a mutation, you first call `useUploadUserPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserPhotoMutation, { data, loading, error }] = useUploadUserPhotoMutation({
 *   variables: {
 *      picture: // value for 'picture'
 *   },
 * });
 */
export function useUploadUserPhotoMutation(baseOptions?: Apollo.MutationHookOptions<UploadUserPhotoMutation, UploadUserPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUserPhotoMutation, UploadUserPhotoMutationVariables>(UploadUserPhotoDocument, options);
      }
export type UploadUserPhotoMutationHookResult = ReturnType<typeof useUploadUserPhotoMutation>;
export type UploadUserPhotoMutationResult = Apollo.MutationResult<UploadUserPhotoMutation>;
export type UploadUserPhotoMutationOptions = Apollo.BaseMutationOptions<UploadUserPhotoMutation, UploadUserPhotoMutationVariables>;
export const DeleteUserPhotoDocument = gql`
    mutation deleteUserPhoto {
  deletePhoto {
    id
    message
  }
}
    `;
export type DeleteUserPhotoMutationFn = Apollo.MutationFunction<DeleteUserPhotoMutation, DeleteUserPhotoMutationVariables>;

/**
 * __useDeleteUserPhotoMutation__
 *
 * To run a mutation, you first call `useDeleteUserPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserPhotoMutation, { data, loading, error }] = useDeleteUserPhotoMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserPhotoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserPhotoMutation, DeleteUserPhotoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserPhotoMutation, DeleteUserPhotoMutationVariables>(DeleteUserPhotoDocument, options);
      }
export type DeleteUserPhotoMutationHookResult = ReturnType<typeof useDeleteUserPhotoMutation>;
export type DeleteUserPhotoMutationResult = Apollo.MutationResult<DeleteUserPhotoMutation>;
export type DeleteUserPhotoMutationOptions = Apollo.BaseMutationOptions<DeleteUserPhotoMutation, DeleteUserPhotoMutationVariables>;
export const GetMyPostsDocument = gql`
    query getMyPosts($offset: Int, $limit: Int, $lang: String) {
  myPosts(offset: $offset, limit: $limit, lang: $lang) {
    posts {
      id
      profileId
      profileName
      author {
        id
        name
      }
      creationDate
      originalMessage
      comments
      media {
        ... on Image {
          image
        }
      }
      rating
      market {
        currency
        price
      }
    }
    count
  }
}
    `;

/**
 * __useGetMyPostsQuery__
 *
 * To run a query within a React component, call `useGetMyPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPostsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetMyPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyPostsQuery, GetMyPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyPostsQuery, GetMyPostsQueryVariables>(GetMyPostsDocument, options);
      }
export function useGetMyPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyPostsQuery, GetMyPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyPostsQuery, GetMyPostsQueryVariables>(GetMyPostsDocument, options);
        }
export type GetMyPostsQueryHookResult = ReturnType<typeof useGetMyPostsQuery>;
export type GetMyPostsLazyQueryHookResult = ReturnType<typeof useGetMyPostsLazyQuery>;
export type GetMyPostsQueryResult = Apollo.QueryResult<GetMyPostsQuery, GetMyPostsQueryVariables>;
export const GetMyCommentsDocument = gql`
    query getMyComments($offset: Int, $limit: Int, $lang: String) {
  myComments(offset: $offset, limit: $limit, lang: $lang) {
    comments {
      id
      creationDate
      message
      post {
        id
        creationDate
        originalMessage
        media {
          ... on Image {
            image
          }
        }
        rating
        market {
          currency
          price
        }
      }
      profileId
      profileName
    }
    count
  }
}
    `;

/**
 * __useGetMyCommentsQuery__
 *
 * To run a query within a React component, call `useGetMyCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCommentsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetMyCommentsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyCommentsQuery, GetMyCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyCommentsQuery, GetMyCommentsQueryVariables>(GetMyCommentsDocument, options);
      }
export function useGetMyCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyCommentsQuery, GetMyCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyCommentsQuery, GetMyCommentsQueryVariables>(GetMyCommentsDocument, options);
        }
export type GetMyCommentsQueryHookResult = ReturnType<typeof useGetMyCommentsQuery>;
export type GetMyCommentsLazyQueryHookResult = ReturnType<typeof useGetMyCommentsLazyQuery>;
export type GetMyCommentsQueryResult = Apollo.QueryResult<GetMyCommentsQuery, GetMyCommentsQueryVariables>;
export const PollVoteDocument = gql`
    mutation pollVote($id: ID!, $optionId: ID!) {
  voteForQuiz(id: $id, optionId: $optionId) {
    ...PostNode
  }
}
    ${PostNodeFragmentDoc}`;
export type PollVoteMutationFn = Apollo.MutationFunction<PollVoteMutation, PollVoteMutationVariables>;

/**
 * __usePollVoteMutation__
 *
 * To run a mutation, you first call `usePollVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePollVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pollVoteMutation, { data, loading, error }] = usePollVoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      optionId: // value for 'optionId'
 *   },
 * });
 */
export function usePollVoteMutation(baseOptions?: Apollo.MutationHookOptions<PollVoteMutation, PollVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PollVoteMutation, PollVoteMutationVariables>(PollVoteDocument, options);
      }
export type PollVoteMutationHookResult = ReturnType<typeof usePollVoteMutation>;
export type PollVoteMutationResult = Apollo.MutationResult<PollVoteMutation>;
export type PollVoteMutationOptions = Apollo.BaseMutationOptions<PollVoteMutation, PollVoteMutationVariables>;
export const GetProfileMediaDocument = gql`
    query getProfileMedia($id: ID!) {
  profileMedia(id: $id) {
    ... on Video {
      video {
        duration
        videoId
      }
    }
    ... on Image {
      image
    }
  }
}
    `;

/**
 * __useGetProfileMediaQuery__
 *
 * To run a query within a React component, call `useGetProfileMediaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileMediaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileMediaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProfileMediaQuery(baseOptions: Apollo.QueryHookOptions<GetProfileMediaQuery, GetProfileMediaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileMediaQuery, GetProfileMediaQueryVariables>(GetProfileMediaDocument, options);
      }
export function useGetProfileMediaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileMediaQuery, GetProfileMediaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileMediaQuery, GetProfileMediaQueryVariables>(GetProfileMediaDocument, options);
        }
export type GetProfileMediaQueryHookResult = ReturnType<typeof useGetProfileMediaQuery>;
export type GetProfileMediaLazyQueryHookResult = ReturnType<typeof useGetProfileMediaLazyQuery>;
export type GetProfileMediaQueryResult = Apollo.QueryResult<GetProfileMediaQuery, GetProfileMediaQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($name: String, $family_name: String, $previousPassword: String, $ProposedPassword: String, $about: String, $website: String, $facebook: String, $vkontakte: String, $twitter: String, $instagram: String) {
  updateUser(
    name: $name
    family_name: $family_name
    previousPassword: $previousPassword
    proposedPassword: $ProposedPassword
    about: $about
    website: $website
    facebook: $facebook
    vkontakte: $vkontakte
    twitter: $twitter
    instagram: $instagram
  ) {
    ...UserInfo
  }
}
    ${UserInfoFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      family_name: // value for 'family_name'
 *      previousPassword: // value for 'previousPassword'
 *      ProposedPassword: // value for 'ProposedPassword'
 *      about: // value for 'about'
 *      website: // value for 'website'
 *      facebook: // value for 'facebook'
 *      vkontakte: // value for 'vkontakte'
 *      twitter: // value for 'twitter'
 *      instagram: // value for 'instagram'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserAttributeDocument = gql`
    mutation updateUserAttribute($login: String) {
  updateUserAttribute(login: $login) {
    CodeDeliveryDetails {
      Destination
      DeliveryMedium
      AttributeName
    }
  }
}
    `;
export type UpdateUserAttributeMutationFn = Apollo.MutationFunction<UpdateUserAttributeMutation, UpdateUserAttributeMutationVariables>;

/**
 * __useUpdateUserAttributeMutation__
 *
 * To run a mutation, you first call `useUpdateUserAttributeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserAttributeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserAttributeMutation, { data, loading, error }] = useUpdateUserAttributeMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useUpdateUserAttributeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserAttributeMutation, UpdateUserAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserAttributeMutation, UpdateUserAttributeMutationVariables>(UpdateUserAttributeDocument, options);
      }
export type UpdateUserAttributeMutationHookResult = ReturnType<typeof useUpdateUserAttributeMutation>;
export type UpdateUserAttributeMutationResult = Apollo.MutationResult<UpdateUserAttributeMutation>;
export type UpdateUserAttributeMutationOptions = Apollo.BaseMutationOptions<UpdateUserAttributeMutation, UpdateUserAttributeMutationVariables>;
export const ConfirmUserAttributeDocument = gql`
    query confirmUserAttribute($name: String, $code: String) {
  confirmUserAttribute(name: $name, code: $code) {
    id
    message
  }
}
    `;

/**
 * __useConfirmUserAttributeQuery__
 *
 * To run a query within a React component, call `useConfirmUserAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfirmUserAttributeQuery({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmUserAttributeQuery(baseOptions?: Apollo.QueryHookOptions<ConfirmUserAttributeQuery, ConfirmUserAttributeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConfirmUserAttributeQuery, ConfirmUserAttributeQueryVariables>(ConfirmUserAttributeDocument, options);
      }
export function useConfirmUserAttributeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConfirmUserAttributeQuery, ConfirmUserAttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConfirmUserAttributeQuery, ConfirmUserAttributeQueryVariables>(ConfirmUserAttributeDocument, options);
        }
export type ConfirmUserAttributeQueryHookResult = ReturnType<typeof useConfirmUserAttributeQuery>;
export type ConfirmUserAttributeLazyQueryHookResult = ReturnType<typeof useConfirmUserAttributeLazyQuery>;
export type ConfirmUserAttributeQueryResult = Apollo.QueryResult<ConfirmUserAttributeQuery, ConfirmUserAttributeQueryVariables>;
export const ResendConfirmUserAttributeDocument = gql`
    query resendConfirmUserAttribute($name: String) {
  resendConfirmUserAttribute(name: $name) {
    CodeDeliveryDetails {
      Destination
      DeliveryMedium
      AttributeName
    }
  }
}
    `;

/**
 * __useResendConfirmUserAttributeQuery__
 *
 * To run a query within a React component, call `useResendConfirmUserAttributeQuery` and pass it any options that fit your needs.
 * When your component renders, `useResendConfirmUserAttributeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResendConfirmUserAttributeQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useResendConfirmUserAttributeQuery(baseOptions?: Apollo.QueryHookOptions<ResendConfirmUserAttributeQuery, ResendConfirmUserAttributeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResendConfirmUserAttributeQuery, ResendConfirmUserAttributeQueryVariables>(ResendConfirmUserAttributeDocument, options);
      }
export function useResendConfirmUserAttributeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResendConfirmUserAttributeQuery, ResendConfirmUserAttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResendConfirmUserAttributeQuery, ResendConfirmUserAttributeQueryVariables>(ResendConfirmUserAttributeDocument, options);
        }
export type ResendConfirmUserAttributeQueryHookResult = ReturnType<typeof useResendConfirmUserAttributeQuery>;
export type ResendConfirmUserAttributeLazyQueryHookResult = ReturnType<typeof useResendConfirmUserAttributeLazyQuery>;
export type ResendConfirmUserAttributeQueryResult = Apollo.QueryResult<ResendConfirmUserAttributeQuery, ResendConfirmUserAttributeQueryVariables>;
export const UserRegionDocument = gql`
    query userRegion {
  userRegion {
    country
    countryCode
    city {
      name
      coords
      postal
    }
  }
}
    `;

/**
 * __useUserRegionQuery__
 *
 * To run a query within a React component, call `useUserRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRegionQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserRegionQuery(baseOptions?: Apollo.QueryHookOptions<UserRegionQuery, UserRegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRegionQuery, UserRegionQueryVariables>(UserRegionDocument, options);
      }
export function useUserRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRegionQuery, UserRegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRegionQuery, UserRegionQueryVariables>(UserRegionDocument, options);
        }
export type UserRegionQueryHookResult = ReturnType<typeof useUserRegionQuery>;
export type UserRegionLazyQueryHookResult = ReturnType<typeof useUserRegionLazyQuery>;
export type UserRegionQueryResult = Apollo.QueryResult<UserRegionQuery, UserRegionQueryVariables>;
export const ToggleAnswerDocument = gql`
    mutation toggleAnswer($id: ID!, $lang: String) {
  toggleAnswer(id: $id, lang: $lang) {
    comment {
      ...CommentNode
    }
    post {
      ...PostNode
    }
  }
}
    ${CommentNodeFragmentDoc}
${PostNodeFragmentDoc}`;
export type ToggleAnswerMutationFn = Apollo.MutationFunction<ToggleAnswerMutation, ToggleAnswerMutationVariables>;

/**
 * __useToggleAnswerMutation__
 *
 * To run a mutation, you first call `useToggleAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAnswerMutation, { data, loading, error }] = useToggleAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useToggleAnswerMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAnswerMutation, ToggleAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleAnswerMutation, ToggleAnswerMutationVariables>(ToggleAnswerDocument, options);
      }
export type ToggleAnswerMutationHookResult = ReturnType<typeof useToggleAnswerMutation>;
export type ToggleAnswerMutationResult = Apollo.MutationResult<ToggleAnswerMutation>;
export type ToggleAnswerMutationOptions = Apollo.BaseMutationOptions<ToggleAnswerMutation, ToggleAnswerMutationVariables>;
export const LikePostDocument = gql`
    mutation likePost($id: ID!) {
  likePost(id: $id) {
    ...PostNode
  }
}
    ${PostNodeFragmentDoc}`;
export type LikePostMutationFn = Apollo.MutationFunction<LikePostMutation, LikePostMutationVariables>;

/**
 * __useLikePostMutation__
 *
 * To run a mutation, you first call `useLikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likePostMutation, { data, loading, error }] = useLikePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikePostMutation(baseOptions?: Apollo.MutationHookOptions<LikePostMutation, LikePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikePostMutation, LikePostMutationVariables>(LikePostDocument, options);
      }
export type LikePostMutationHookResult = ReturnType<typeof useLikePostMutation>;
export type LikePostMutationResult = Apollo.MutationResult<LikePostMutation>;
export type LikePostMutationOptions = Apollo.BaseMutationOptions<LikePostMutation, LikePostMutationVariables>;
export const ToggleSubscribeProductDocument = gql`
    mutation toggleSubscribeProduct($id: ID!) {
  toggleSubscribe(profileId: $id) {
    id
    isSubscribed
  }
}
    `;
export type ToggleSubscribeProductMutationFn = Apollo.MutationFunction<ToggleSubscribeProductMutation, ToggleSubscribeProductMutationVariables>;

/**
 * __useToggleSubscribeProductMutation__
 *
 * To run a mutation, you first call `useToggleSubscribeProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSubscribeProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSubscribeProductMutation, { data, loading, error }] = useToggleSubscribeProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleSubscribeProductMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSubscribeProductMutation, ToggleSubscribeProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSubscribeProductMutation, ToggleSubscribeProductMutationVariables>(ToggleSubscribeProductDocument, options);
      }
export type ToggleSubscribeProductMutationHookResult = ReturnType<typeof useToggleSubscribeProductMutation>;
export type ToggleSubscribeProductMutationResult = Apollo.MutationResult<ToggleSubscribeProductMutation>;
export type ToggleSubscribeProductMutationOptions = Apollo.BaseMutationOptions<ToggleSubscribeProductMutation, ToggleSubscribeProductMutationVariables>;
export const GetCollectionsDocument = gql`
    query getCollections($userId: String, $lang: String) {
  collections(userId: $userId, lang: $lang) {
    ...CollectionElement
  }
}
    ${CollectionElementFragmentDoc}`;

/**
 * __useGetCollectionsQuery__
 *
 * To run a query within a React component, call `useGetCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollectionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCollectionsQuery(baseOptions?: Apollo.QueryHookOptions<GetCollectionsQuery, GetCollectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(GetCollectionsDocument, options);
      }
export function useGetCollectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollectionsQuery, GetCollectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollectionsQuery, GetCollectionsQueryVariables>(GetCollectionsDocument, options);
        }
export type GetCollectionsQueryHookResult = ReturnType<typeof useGetCollectionsQuery>;
export type GetCollectionsLazyQueryHookResult = ReturnType<typeof useGetCollectionsLazyQuery>;
export type GetCollectionsQueryResult = Apollo.QueryResult<GetCollectionsQuery, GetCollectionsQueryVariables>;
export const CreateCollectionDocument = gql`
    mutation createCollection($name: String!, $description: String) {
  createCollection(name: $name, description: $description) {
    ...CollectionElement
  }
}
    ${CollectionElementFragmentDoc}`;
export type CreateCollectionMutationFn = Apollo.MutationFunction<CreateCollectionMutation, CreateCollectionMutationVariables>;

/**
 * __useCreateCollectionMutation__
 *
 * To run a mutation, you first call `useCreateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCollectionMutation, { data, loading, error }] = useCreateCollectionMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCollectionMutation, CreateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCollectionMutation, CreateCollectionMutationVariables>(CreateCollectionDocument, options);
      }
export type CreateCollectionMutationHookResult = ReturnType<typeof useCreateCollectionMutation>;
export type CreateCollectionMutationResult = Apollo.MutationResult<CreateCollectionMutation>;
export type CreateCollectionMutationOptions = Apollo.BaseMutationOptions<CreateCollectionMutation, CreateCollectionMutationVariables>;
export const UpdateCollectionDocument = gql`
    mutation updateCollection($collectionId: ID!, $name: String, $description: String) {
  updateCollection(
    collectionId: $collectionId
    name: $name
    description: $description
  ) {
    ...CollectionElement
  }
}
    ${CollectionElementFragmentDoc}`;
export type UpdateCollectionMutationFn = Apollo.MutationFunction<UpdateCollectionMutation, UpdateCollectionMutationVariables>;

/**
 * __useUpdateCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollectionMutation, { data, loading, error }] = useUpdateCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateCollectionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>(UpdateCollectionDocument, options);
      }
export type UpdateCollectionMutationHookResult = ReturnType<typeof useUpdateCollectionMutation>;
export type UpdateCollectionMutationResult = Apollo.MutationResult<UpdateCollectionMutation>;
export type UpdateCollectionMutationOptions = Apollo.BaseMutationOptions<UpdateCollectionMutation, UpdateCollectionMutationVariables>;
export const DeleteCollectionDocument = gql`
    mutation deleteCollection($collectionId: ID!) {
  deleteCollection(collectionId: $collectionId) {
    id
  }
}
    `;
export type DeleteCollectionMutationFn = Apollo.MutationFunction<DeleteCollectionMutation, DeleteCollectionMutationVariables>;

/**
 * __useDeleteCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollectionMutation, { data, loading, error }] = useDeleteCollectionMutation({
 *   variables: {
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useDeleteCollectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>(DeleteCollectionDocument, options);
      }
export type DeleteCollectionMutationHookResult = ReturnType<typeof useDeleteCollectionMutation>;
export type DeleteCollectionMutationResult = Apollo.MutationResult<DeleteCollectionMutation>;
export type DeleteCollectionMutationOptions = Apollo.BaseMutationOptions<DeleteCollectionMutation, DeleteCollectionMutationVariables>;
export const AddToCollectionDocument = gql`
    mutation addToCollection($profileId: ID!, $collectionId: ID!) {
  addToCollection(profileId: $profileId, collectionId: $collectionId) {
    ...CollectionElement
  }
}
    ${CollectionElementFragmentDoc}`;
export type AddToCollectionMutationFn = Apollo.MutationFunction<AddToCollectionMutation, AddToCollectionMutationVariables>;

/**
 * __useAddToCollectionMutation__
 *
 * To run a mutation, you first call `useAddToCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCollectionMutation, { data, loading, error }] = useAddToCollectionMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useAddToCollectionMutation(baseOptions?: Apollo.MutationHookOptions<AddToCollectionMutation, AddToCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddToCollectionMutation, AddToCollectionMutationVariables>(AddToCollectionDocument, options);
      }
export type AddToCollectionMutationHookResult = ReturnType<typeof useAddToCollectionMutation>;
export type AddToCollectionMutationResult = Apollo.MutationResult<AddToCollectionMutation>;
export type AddToCollectionMutationOptions = Apollo.BaseMutationOptions<AddToCollectionMutation, AddToCollectionMutationVariables>;
export const RemoveFromCollectionDocument = gql`
    mutation removeFromCollection($profileId: ID!, $collectionId: ID!) {
  removeFromCollection(profileId: $profileId, collectionId: $collectionId) {
    id
  }
}
    `;
export type RemoveFromCollectionMutationFn = Apollo.MutationFunction<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables>;

/**
 * __useRemoveFromCollectionMutation__
 *
 * To run a mutation, you first call `useRemoveFromCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCollectionMutation, { data, loading, error }] = useRemoveFromCollectionMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      collectionId: // value for 'collectionId'
 *   },
 * });
 */
export function useRemoveFromCollectionMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables>(RemoveFromCollectionDocument, options);
      }
export type RemoveFromCollectionMutationHookResult = ReturnType<typeof useRemoveFromCollectionMutation>;
export type RemoveFromCollectionMutationResult = Apollo.MutationResult<RemoveFromCollectionMutation>;
export type RemoveFromCollectionMutationOptions = Apollo.BaseMutationOptions<RemoveFromCollectionMutation, RemoveFromCollectionMutationVariables>;
export const GetRewardsDocument = gql`
    query getRewards($userId: String) {
  rewards(userId: $userId) {
    name
    level
    points
    pointScale
  }
}
    `;

/**
 * __useGetRewardsQuery__
 *
 * To run a query within a React component, call `useGetRewardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRewardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRewardsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetRewardsQuery(baseOptions?: Apollo.QueryHookOptions<GetRewardsQuery, GetRewardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRewardsQuery, GetRewardsQueryVariables>(GetRewardsDocument, options);
      }
export function useGetRewardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRewardsQuery, GetRewardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRewardsQuery, GetRewardsQueryVariables>(GetRewardsDocument, options);
        }
export type GetRewardsQueryHookResult = ReturnType<typeof useGetRewardsQuery>;
export type GetRewardsLazyQueryHookResult = ReturnType<typeof useGetRewardsLazyQuery>;
export type GetRewardsQueryResult = Apollo.QueryResult<GetRewardsQuery, GetRewardsQueryVariables>;
export const GetOtherUserDocument = gql`
    query getOtherUser($userId: ID) {
  otherUserInfo(userId: $userId) {
    id
    name
    family_name
    about
    website
    facebook
    vkontakte
    twitter
    instagram
    rewards {
      name
      level
      points
      pointScale
    }
    counters {
      collections
      products
      marketplace
    }
  }
}
    `;

/**
 * __useGetOtherUserQuery__
 *
 * To run a query within a React component, call `useGetOtherUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOtherUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOtherUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetOtherUserQuery(baseOptions?: Apollo.QueryHookOptions<GetOtherUserQuery, GetOtherUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOtherUserQuery, GetOtherUserQueryVariables>(GetOtherUserDocument, options);
      }
export function useGetOtherUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOtherUserQuery, GetOtherUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOtherUserQuery, GetOtherUserQueryVariables>(GetOtherUserDocument, options);
        }
export type GetOtherUserQueryHookResult = ReturnType<typeof useGetOtherUserQuery>;
export type GetOtherUserLazyQueryHookResult = ReturnType<typeof useGetOtherUserLazyQuery>;
export type GetOtherUserQueryResult = Apollo.QueryResult<GetOtherUserQuery, GetOtherUserQueryVariables>;
export const GetUserProductsDocument = gql`
    query getUserProducts($userId: ID, $lang: String) {
  userProducts(userId: $userId, lang: $lang) {
    id
    title
    description
    defaultPicture
    rating
  }
}
    `;

/**
 * __useGetUserProductsQuery__
 *
 * To run a query within a React component, call `useGetUserProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProductsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetUserProductsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProductsQuery, GetUserProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProductsQuery, GetUserProductsQueryVariables>(GetUserProductsDocument, options);
      }
export function useGetUserProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProductsQuery, GetUserProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProductsQuery, GetUserProductsQueryVariables>(GetUserProductsDocument, options);
        }
export type GetUserProductsQueryHookResult = ReturnType<typeof useGetUserProductsQuery>;
export type GetUserProductsLazyQueryHookResult = ReturnType<typeof useGetUserProductsLazyQuery>;
export type GetUserProductsQueryResult = Apollo.QueryResult<GetUserProductsQuery, GetUserProductsQueryVariables>;
export const ProfilesMapDocument = gql`
    query profilesMap($categoryId: ID, $search: String, $bounds: [Float], $country: String, $lang: String, $after: String, $limit: Int) {
  profilesMap(
    categoryId: $categoryId
    search: $search
    bounds: $bounds
    country: $country
    lang: $lang
    after: $after
    limit: $limit
  ) {
    edges {
      cursor
      node {
        id
        title
        minOffer {
          price
          currency
          author {
            id
            name
          }
        }
        description
        creationDate
        categories {
          id
          name
          slug
          count
        }
        defaultPicture
        status
        rating
        postCounters {
          marketplace
          image
          video
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;

/**
 * __useProfilesMapQuery__
 *
 * To run a query within a React component, call `useProfilesMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilesMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilesMapQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      search: // value for 'search'
 *      bounds: // value for 'bounds'
 *      country: // value for 'country'
 *      lang: // value for 'lang'
 *      after: // value for 'after'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useProfilesMapQuery(baseOptions?: Apollo.QueryHookOptions<ProfilesMapQuery, ProfilesMapQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfilesMapQuery, ProfilesMapQueryVariables>(ProfilesMapDocument, options);
      }
export function useProfilesMapLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfilesMapQuery, ProfilesMapQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfilesMapQuery, ProfilesMapQueryVariables>(ProfilesMapDocument, options);
        }
export type ProfilesMapQueryHookResult = ReturnType<typeof useProfilesMapQuery>;
export type ProfilesMapLazyQueryHookResult = ReturnType<typeof useProfilesMapLazyQuery>;
export type ProfilesMapQueryResult = Apollo.QueryResult<ProfilesMapQuery, ProfilesMapQueryVariables>;
export const MarketplacePointsDocument = gql`
    query marketplacePoints($categoryId: ID, $bounds: [Float], $search: String, $country: String, $lang: String) {
  marketplacePoints(
    categoryId: $categoryId
    bounds: $bounds
    search: $search
    country: $country
    lang: $lang
  ) {
    id
    type
    properties {
      cluster
      profileId
      price
      currency
    }
    geometry {
      type
      coordinates
    }
  }
}
    `;

/**
 * __useMarketplacePointsQuery__
 *
 * To run a query within a React component, call `useMarketplacePointsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplacePointsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplacePointsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      bounds: // value for 'bounds'
 *      search: // value for 'search'
 *      country: // value for 'country'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useMarketplacePointsQuery(baseOptions?: Apollo.QueryHookOptions<MarketplacePointsQuery, MarketplacePointsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplacePointsQuery, MarketplacePointsQueryVariables>(MarketplacePointsDocument, options);
      }
export function useMarketplacePointsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplacePointsQuery, MarketplacePointsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplacePointsQuery, MarketplacePointsQueryVariables>(MarketplacePointsDocument, options);
        }
export type MarketplacePointsQueryHookResult = ReturnType<typeof useMarketplacePointsQuery>;
export type MarketplacePointsLazyQueryHookResult = ReturnType<typeof useMarketplacePointsLazyQuery>;
export type MarketplacePointsQueryResult = Apollo.QueryResult<MarketplacePointsQuery, MarketplacePointsQueryVariables>;
export const MarketplaceSearchProductDocument = gql`
    query marketplaceSearchProduct($search: String, $limit: Int, $lang: String, $currency: String) {
  marketplaceSearchProduct(
    search: $search
    limit: $limit
    lang: $lang
    currency: $currency
  ) {
    id
    title
    defaultPicture
    modifications {
      id
      title
      parameters {
        id
        name
        unit {
          id
          name
        }
        parameterType
        value {
          String {
            value
            valueId
          }
          Number
          Boolean
          StringColor {
            value
            color
          }
          Group {
            group
            value
            valueId
          }
        }
        groups
      }
    }
    avgPrice {
      price
      currency
      count
    }
  }
}
    `;

/**
 * __useMarketplaceSearchProductQuery__
 *
 * To run a query within a React component, call `useMarketplaceSearchProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceSearchProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceSearchProductQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      lang: // value for 'lang'
 *      currency: // value for 'currency'
 *   },
 * });
 */
export function useMarketplaceSearchProductQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceSearchProductQuery, MarketplaceSearchProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceSearchProductQuery, MarketplaceSearchProductQueryVariables>(MarketplaceSearchProductDocument, options);
      }
export function useMarketplaceSearchProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceSearchProductQuery, MarketplaceSearchProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceSearchProductQuery, MarketplaceSearchProductQueryVariables>(MarketplaceSearchProductDocument, options);
        }
export type MarketplaceSearchProductQueryHookResult = ReturnType<typeof useMarketplaceSearchProductQuery>;
export type MarketplaceSearchProductLazyQueryHookResult = ReturnType<typeof useMarketplaceSearchProductLazyQuery>;
export type MarketplaceSearchProductQueryResult = Apollo.QueryResult<MarketplaceSearchProductQuery, MarketplaceSearchProductQueryVariables>;
export const UploadAdImageDocument = gql`
    mutation uploadAdImage($image: String) {
  addMarketplaceImage(image: $image)
}
    `;
export type UploadAdImageMutationFn = Apollo.MutationFunction<UploadAdImageMutation, UploadAdImageMutationVariables>;

/**
 * __useUploadAdImageMutation__
 *
 * To run a mutation, you first call `useUploadAdImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAdImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAdImageMutation, { data, loading, error }] = useUploadAdImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadAdImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadAdImageMutation, UploadAdImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadAdImageMutation, UploadAdImageMutationVariables>(UploadAdImageDocument, options);
      }
export type UploadAdImageMutationHookResult = ReturnType<typeof useUploadAdImageMutation>;
export type UploadAdImageMutationResult = Apollo.MutationResult<UploadAdImageMutation>;
export type UploadAdImageMutationOptions = Apollo.BaseMutationOptions<UploadAdImageMutation, UploadAdImageMutationVariables>;
export const GetLocationDocument = gql`
    query getLocation($coords: [Float], $lang: String) {
  getLocation(coords: $coords, lang: $lang) {
    address
    city
    coords
  }
}
    `;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      coords: // value for 'coords'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const CreateAdDocument = gql`
    mutation createAd($title: String, $description: String, $media: [String], $profileId: ID, $modificationId: ID, $categoryId: ID, $price: Float, $location: String, $currency: String, $delivery: Boolean, $coords: [Float], $tradeType: String, $condition: String, $hidePhone: Boolean, $country: String!) {
  createMarketplace(
    title: $title
    description: $description
    media: $media
    profileId: $profileId
    modificationId: $modificationId
    categoryId: $categoryId
    price: $price
    location: $location
    currency: $currency
    delivery: $delivery
    coords: $coords
    tradeType: $tradeType
    condition: $condition
    hidePhone: $hidePhone
    country: $country
  ) {
    id
  }
}
    `;
export type CreateAdMutationFn = Apollo.MutationFunction<CreateAdMutation, CreateAdMutationVariables>;

/**
 * __useCreateAdMutation__
 *
 * To run a mutation, you first call `useCreateAdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdMutation, { data, loading, error }] = useCreateAdMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      media: // value for 'media'
 *      profileId: // value for 'profileId'
 *      modificationId: // value for 'modificationId'
 *      categoryId: // value for 'categoryId'
 *      price: // value for 'price'
 *      location: // value for 'location'
 *      currency: // value for 'currency'
 *      delivery: // value for 'delivery'
 *      coords: // value for 'coords'
 *      tradeType: // value for 'tradeType'
 *      condition: // value for 'condition'
 *      hidePhone: // value for 'hidePhone'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCreateAdMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdMutation, CreateAdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdMutation, CreateAdMutationVariables>(CreateAdDocument, options);
      }
export type CreateAdMutationHookResult = ReturnType<typeof useCreateAdMutation>;
export type CreateAdMutationResult = Apollo.MutationResult<CreateAdMutation>;
export type CreateAdMutationOptions = Apollo.BaseMutationOptions<CreateAdMutation, CreateAdMutationVariables>;
export const MarketplaceRegisterUserDocument = gql`
    query marketplaceRegisterUser($name: String!, $email: String, $phone: String, $pass: String) {
  marketplaceRegisterUser(name: $name, email: $email, phone: $phone, pass: $pass) {
    UserConfirmed
    CodeDeliveryDetails {
      Destination
      DeliveryMedium
      AttributeName
    }
    UserSub
    Username
  }
}
    `;

/**
 * __useMarketplaceRegisterUserQuery__
 *
 * To run a query within a React component, call `useMarketplaceRegisterUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceRegisterUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceRegisterUserQuery({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phone: // value for 'phone'
 *      pass: // value for 'pass'
 *   },
 * });
 */
export function useMarketplaceRegisterUserQuery(baseOptions: Apollo.QueryHookOptions<MarketplaceRegisterUserQuery, MarketplaceRegisterUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceRegisterUserQuery, MarketplaceRegisterUserQueryVariables>(MarketplaceRegisterUserDocument, options);
      }
export function useMarketplaceRegisterUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceRegisterUserQuery, MarketplaceRegisterUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceRegisterUserQuery, MarketplaceRegisterUserQueryVariables>(MarketplaceRegisterUserDocument, options);
        }
export type MarketplaceRegisterUserQueryHookResult = ReturnType<typeof useMarketplaceRegisterUserQuery>;
export type MarketplaceRegisterUserLazyQueryHookResult = ReturnType<typeof useMarketplaceRegisterUserLazyQuery>;
export type MarketplaceRegisterUserQueryResult = Apollo.QueryResult<MarketplaceRegisterUserQuery, MarketplaceRegisterUserQueryVariables>;
export const GetAdChildrenCategoriesDocument = gql`
    query getAdChildrenCategories($id: ID!, $category: String, $lang: String) {
  category(id: $id, lang: $lang) {
    id
    name
    slug
    filter
  }
  parentChildrenCategories(category: $category, lang: $lang) {
    count
    parents {
      id
      name
      count
      slug
    }
    children {
      slug
      count
      name
      id
    }
  }
}
    `;

/**
 * __useGetAdChildrenCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAdChildrenCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdChildrenCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdChildrenCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      category: // value for 'category'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAdChildrenCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetAdChildrenCategoriesQuery, GetAdChildrenCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdChildrenCategoriesQuery, GetAdChildrenCategoriesQueryVariables>(GetAdChildrenCategoriesDocument, options);
      }
export function useGetAdChildrenCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdChildrenCategoriesQuery, GetAdChildrenCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdChildrenCategoriesQuery, GetAdChildrenCategoriesQueryVariables>(GetAdChildrenCategoriesDocument, options);
        }
export type GetAdChildrenCategoriesQueryHookResult = ReturnType<typeof useGetAdChildrenCategoriesQuery>;
export type GetAdChildrenCategoriesLazyQueryHookResult = ReturnType<typeof useGetAdChildrenCategoriesLazyQuery>;
export type GetAdChildrenCategoriesQueryResult = Apollo.QueryResult<GetAdChildrenCategoriesQuery, GetAdChildrenCategoriesQueryVariables>;
export const GetMarketplaceDocument = gql`
    query getMarketplace($id: ID!, $lang: String) {
  marketplace(id: $id, lang: $lang) {
    id
    title
    description
    publishedDate
    categoryId
    media {
      ... on Image {
        image
      }
      ... on Video {
        video {
          duration
          videoId
        }
      }
    }
    profile {
      id
      title
      description
      defaultPicture
      rating
    }
    price
    oldPrice
    location
    currency
    condition
    coords
    delivery
    tradeType
    author {
      id
      name
      phone
      creationDate
      marketplaceCount
    }
    viewsCount
    bookmarks
    status
    otherMarketplaceCount
    inBookmarks
  }
}
    `;

/**
 * __useGetMarketplaceQuery__
 *
 * To run a query within a React component, call `useGetMarketplaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMarketplaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMarketplaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetMarketplaceQuery(baseOptions: Apollo.QueryHookOptions<GetMarketplaceQuery, GetMarketplaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMarketplaceQuery, GetMarketplaceQueryVariables>(GetMarketplaceDocument, options);
      }
export function useGetMarketplaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMarketplaceQuery, GetMarketplaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMarketplaceQuery, GetMarketplaceQueryVariables>(GetMarketplaceDocument, options);
        }
export type GetMarketplaceQueryHookResult = ReturnType<typeof useGetMarketplaceQuery>;
export type GetMarketplaceLazyQueryHookResult = ReturnType<typeof useGetMarketplaceLazyQuery>;
export type GetMarketplaceQueryResult = Apollo.QueryResult<GetMarketplaceQuery, GetMarketplaceQueryVariables>;
export const GetAdCharacteristicsDocument = gql`
    query getAdCharacteristics($id: ID!, $lang: String) {
  profile(id: $id, lang: $lang) {
    parameters {
      categoryId
      categoryName
      parameterGroups {
        id
        groupName
        params {
          id
          name
          unit {
            id
            name
          }
          parameterType
          groups
          value {
            String {
              value
              valueId
            }
            Number
            Boolean
            StringColor {
              value
              color
            }
            Group {
              group
              value
              valueId
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetAdCharacteristicsQuery__
 *
 * To run a query within a React component, call `useGetAdCharacteristicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdCharacteristicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdCharacteristicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetAdCharacteristicsQuery(baseOptions: Apollo.QueryHookOptions<GetAdCharacteristicsQuery, GetAdCharacteristicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdCharacteristicsQuery, GetAdCharacteristicsQueryVariables>(GetAdCharacteristicsDocument, options);
      }
export function useGetAdCharacteristicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdCharacteristicsQuery, GetAdCharacteristicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdCharacteristicsQuery, GetAdCharacteristicsQueryVariables>(GetAdCharacteristicsDocument, options);
        }
export type GetAdCharacteristicsQueryHookResult = ReturnType<typeof useGetAdCharacteristicsQuery>;
export type GetAdCharacteristicsLazyQueryHookResult = ReturnType<typeof useGetAdCharacteristicsLazyQuery>;
export type GetAdCharacteristicsQueryResult = Apollo.QueryResult<GetAdCharacteristicsQuery, GetAdCharacteristicsQueryVariables>;
export const GetRelatedAdDocument = gql`
    query getRelatedAd($id: ID, $lang: String, $limit: Int) {
  marketplaceRelated(id: $id, lang: $lang, limit: $limit) {
    marketplace {
      id
      title
      publishedDate
      media {
        ... on Image {
          image
        }
        ... on Video {
          video {
            videoId
            duration
          }
        }
      }
      price
      oldPrice
      location
      currency
    }
  }
}
    `;

/**
 * __useGetRelatedAdQuery__
 *
 * To run a query within a React component, call `useGetRelatedAdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRelatedAdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRelatedAdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetRelatedAdQuery(baseOptions?: Apollo.QueryHookOptions<GetRelatedAdQuery, GetRelatedAdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRelatedAdQuery, GetRelatedAdQueryVariables>(GetRelatedAdDocument, options);
      }
export function useGetRelatedAdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRelatedAdQuery, GetRelatedAdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRelatedAdQuery, GetRelatedAdQueryVariables>(GetRelatedAdDocument, options);
        }
export type GetRelatedAdQueryHookResult = ReturnType<typeof useGetRelatedAdQuery>;
export type GetRelatedAdLazyQueryHookResult = ReturnType<typeof useGetRelatedAdLazyQuery>;
export type GetRelatedAdQueryResult = Apollo.QueryResult<GetRelatedAdQuery, GetRelatedAdQueryVariables>;
export const ToggleBookmarkDocument = gql`
    mutation toggleBookmark($id: ID!) {
  toggleBookmark(id: $id) {
    id
  }
}
    `;
export type ToggleBookmarkMutationFn = Apollo.MutationFunction<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>;

/**
 * __useToggleBookmarkMutation__
 *
 * To run a mutation, you first call `useToggleBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBookmarkMutation, { data, loading, error }] = useToggleBookmarkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>(ToggleBookmarkDocument, options);
      }
export type ToggleBookmarkMutationHookResult = ReturnType<typeof useToggleBookmarkMutation>;
export type ToggleBookmarkMutationResult = Apollo.MutationResult<ToggleBookmarkMutation>;
export type ToggleBookmarkMutationOptions = Apollo.BaseMutationOptions<ToggleBookmarkMutation, ToggleBookmarkMutationVariables>;
export const LocationAutocompleteDocument = gql`
    query locationAutocomplete($search: String!, $country: String, $lang: String) {
  locationAutocomplete(search: $search, country: $country, lang: $lang) {
    address
    city
    coords
  }
}
    `;

/**
 * __useLocationAutocompleteQuery__
 *
 * To run a query within a React component, call `useLocationAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationAutocompleteQuery({
 *   variables: {
 *      search: // value for 'search'
 *      country: // value for 'country'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useLocationAutocompleteQuery(baseOptions: Apollo.QueryHookOptions<LocationAutocompleteQuery, LocationAutocompleteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationAutocompleteQuery, LocationAutocompleteQueryVariables>(LocationAutocompleteDocument, options);
      }
export function useLocationAutocompleteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationAutocompleteQuery, LocationAutocompleteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationAutocompleteQuery, LocationAutocompleteQueryVariables>(LocationAutocompleteDocument, options);
        }
export type LocationAutocompleteQueryHookResult = ReturnType<typeof useLocationAutocompleteQuery>;
export type LocationAutocompleteLazyQueryHookResult = ReturnType<typeof useLocationAutocompleteLazyQuery>;
export type LocationAutocompleteQueryResult = Apollo.QueryResult<LocationAutocompleteQuery, LocationAutocompleteQueryVariables>;
export const MarketplaceUserDocument = gql`
    query marketplaceUser($id: ID, $lang: String) {
  marketplaceUser(id: $id, lang: $lang) {
    marketplace {
      id
      title
      publishedDate
      media {
        ... on Video {
          video {
            duration
            videoId
          }
        }
        ... on Image {
          image
        }
      }
      price
      oldPrice
      location
      currency
      status
      inBookmarks
    }
  }
}
    `;

/**
 * __useMarketplaceUserQuery__
 *
 * To run a query within a React component, call `useMarketplaceUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useMarketplaceUserQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceUserQuery, MarketplaceUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceUserQuery, MarketplaceUserQueryVariables>(MarketplaceUserDocument, options);
      }
export function useMarketplaceUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceUserQuery, MarketplaceUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceUserQuery, MarketplaceUserQueryVariables>(MarketplaceUserDocument, options);
        }
export type MarketplaceUserQueryHookResult = ReturnType<typeof useMarketplaceUserQuery>;
export type MarketplaceUserLazyQueryHookResult = ReturnType<typeof useMarketplaceUserLazyQuery>;
export type MarketplaceUserQueryResult = Apollo.QueryResult<MarketplaceUserQuery, MarketplaceUserQueryVariables>;
export const ProductOffersDocument = gql`
    query productOffers($productId: ID, $lang: String) {
  productOffers(productId: $productId, lang: $lang) {
    marketsOffers {
      ... on Marketplace {
        id
        title
        description
        publishedDate
        author {
          id
          name
          phone
        }
        media {
          ... on Video {
            video {
              videoId
              duration
            }
          }
          ... on Image {
            image
          }
        }
        categoryId
        price
        oldPrice
        location
        currency
        inBookmarks
      }
      ... on Offer {
        id
        active
        offerTitle: title
        description
        image
        offerPrice: price
        offerCurrency: currency
        url
        availability
      }
    }
    totalCount
  }
}
    `;

/**
 * __useProductOffersQuery__
 *
 * To run a query within a React component, call `useProductOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductOffersQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useProductOffersQuery(baseOptions?: Apollo.QueryHookOptions<ProductOffersQuery, ProductOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductOffersQuery, ProductOffersQueryVariables>(ProductOffersDocument, options);
      }
export function useProductOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductOffersQuery, ProductOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductOffersQuery, ProductOffersQueryVariables>(ProductOffersDocument, options);
        }
export type ProductOffersQueryHookResult = ReturnType<typeof useProductOffersQuery>;
export type ProductOffersLazyQueryHookResult = ReturnType<typeof useProductOffersLazyQuery>;
export type ProductOffersQueryResult = Apollo.QueryResult<ProductOffersQuery, ProductOffersQueryVariables>;
export const GetCategoryShortInfoDocument = gql`
    query getCategoryShortInfo($id: ID, $lang: String) {
  category(id: $id, lang: $lang) {
    id
    name
    slug
    filter
  }
}
    `;

/**
 * __useGetCategoryShortInfoQuery__
 *
 * To run a query within a React component, call `useGetCategoryShortInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryShortInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryShortInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetCategoryShortInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCategoryShortInfoQuery, GetCategoryShortInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryShortInfoQuery, GetCategoryShortInfoQueryVariables>(GetCategoryShortInfoDocument, options);
      }
export function useGetCategoryShortInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryShortInfoQuery, GetCategoryShortInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryShortInfoQuery, GetCategoryShortInfoQueryVariables>(GetCategoryShortInfoDocument, options);
        }
export type GetCategoryShortInfoQueryHookResult = ReturnType<typeof useGetCategoryShortInfoQuery>;
export type GetCategoryShortInfoLazyQueryHookResult = ReturnType<typeof useGetCategoryShortInfoLazyQuery>;
export type GetCategoryShortInfoQueryResult = Apollo.QueryResult<GetCategoryShortInfoQuery, GetCategoryShortInfoQueryVariables>;
export const MyMarketplaceDocument = gql`
    query myMarketplace($id: ID, $lang: String, $limit: Int, $offset: Int) {
  marketplaceUser(id: $id, lang: $lang, limit: $limit, offset: $offset) {
    marketplace {
      id
      title
      description
      creationDate
      publishedDate
      media {
        ... on Video {
          video {
            duration
            videoId
          }
        }
        ... on Image {
          image
        }
      }
      categoryId
      price
      oldPrice
      location
      currency
      condition
      coords
      status
      author {
        id
        name
      }
      views {
        date
        views
      }
      viewsCount
      bookmarks
      rejectReason
    }
    counters {
      published
      unpublished
      rejected
    }
    totalCount
  }
}
    `;

/**
 * __useMyMarketplaceQuery__
 *
 * To run a query within a React component, call `useMyMarketplaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMarketplaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMarketplaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      lang: // value for 'lang'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useMyMarketplaceQuery(baseOptions?: Apollo.QueryHookOptions<MyMarketplaceQuery, MyMarketplaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyMarketplaceQuery, MyMarketplaceQueryVariables>(MyMarketplaceDocument, options);
      }
export function useMyMarketplaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyMarketplaceQuery, MyMarketplaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyMarketplaceQuery, MyMarketplaceQueryVariables>(MyMarketplaceDocument, options);
        }
export type MyMarketplaceQueryHookResult = ReturnType<typeof useMyMarketplaceQuery>;
export type MyMarketplaceLazyQueryHookResult = ReturnType<typeof useMyMarketplaceLazyQuery>;
export type MyMarketplaceQueryResult = Apollo.QueryResult<MyMarketplaceQuery, MyMarketplaceQueryVariables>;
export const MarketplaceUserFiltersDocument = gql`
    query marketplaceUserFilters($userId: ID, $lang: String) {
  marketplaceUserFilters(userId: $userId, lang: $lang) {
    categories {
      name
      id
      count
    }
    locations {
      name
      id
      count
    }
  }
}
    `;

/**
 * __useMarketplaceUserFiltersQuery__
 *
 * To run a query within a React component, call `useMarketplaceUserFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketplaceUserFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketplaceUserFiltersQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useMarketplaceUserFiltersQuery(baseOptions?: Apollo.QueryHookOptions<MarketplaceUserFiltersQuery, MarketplaceUserFiltersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketplaceUserFiltersQuery, MarketplaceUserFiltersQueryVariables>(MarketplaceUserFiltersDocument, options);
      }
export function useMarketplaceUserFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketplaceUserFiltersQuery, MarketplaceUserFiltersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketplaceUserFiltersQuery, MarketplaceUserFiltersQueryVariables>(MarketplaceUserFiltersDocument, options);
        }
export type MarketplaceUserFiltersQueryHookResult = ReturnType<typeof useMarketplaceUserFiltersQuery>;
export type MarketplaceUserFiltersLazyQueryHookResult = ReturnType<typeof useMarketplaceUserFiltersLazyQuery>;
export type MarketplaceUserFiltersQueryResult = Apollo.QueryResult<MarketplaceUserFiltersQuery, MarketplaceUserFiltersQueryVariables>;
export const SearchSuggestDocument = gql`
    query searchSuggest($search: String, $lang: String) {
  search(search: $search, lang: $lang) {
    type
    id
    name
    value
    defaultPicture
    slug
  }
}
    `;

/**
 * __useSearchSuggestQuery__
 *
 * To run a query within a React component, call `useSearchSuggestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSuggestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSuggestQuery({
 *   variables: {
 *      search: // value for 'search'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useSearchSuggestQuery(baseOptions?: Apollo.QueryHookOptions<SearchSuggestQuery, SearchSuggestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSuggestQuery, SearchSuggestQueryVariables>(SearchSuggestDocument, options);
      }
export function useSearchSuggestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSuggestQuery, SearchSuggestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSuggestQuery, SearchSuggestQueryVariables>(SearchSuggestDocument, options);
        }
export type SearchSuggestQueryHookResult = ReturnType<typeof useSearchSuggestQuery>;
export type SearchSuggestLazyQueryHookResult = ReturnType<typeof useSearchSuggestLazyQuery>;
export type SearchSuggestQueryResult = Apollo.QueryResult<SearchSuggestQuery, SearchSuggestQueryVariables>;
export const GetShopsDocument = gql`
    query getShops {
  shops {
    id
    name
    userId
    address
    website
    companyName
    taxNumber
    personName
    personEmail
    personPhone
    photos
    status
    balance
    isLimitExpenses
    limitExpensesValue
    openingHours {
      daysOfWeek
      workFrom
      workTo
      breakFrom
      breakTo
    }
    sources {
      id
      name
      type
      file
      url
      count
      updatedAt
    }
    strategies {
      id
      name
      rate
      priority
      status
      model
      restrictions {
        categories
        minPrice
        maxPrice
        products
      }
    }
  }
}
    `;

/**
 * __useGetShopsQuery__
 *
 * To run a query within a React component, call `useGetShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShopsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShopsQuery(baseOptions?: Apollo.QueryHookOptions<GetShopsQuery, GetShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShopsQuery, GetShopsQueryVariables>(GetShopsDocument, options);
      }
export function useGetShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShopsQuery, GetShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShopsQuery, GetShopsQueryVariables>(GetShopsDocument, options);
        }
export type GetShopsQueryHookResult = ReturnType<typeof useGetShopsQuery>;
export type GetShopsLazyQueryHookResult = ReturnType<typeof useGetShopsLazyQuery>;
export type GetShopsQueryResult = Apollo.QueryResult<GetShopsQuery, GetShopsQueryVariables>;
export const GetOffersDocument = gql`
    query getOffers($shopId: ID!, $status: Boolean, $title: String, $categories: [ObjectId], $offset: Int, $limit: Int) {
  offers(
    shopId: $shopId
    status: $status
    title: $title
    categories: $categories
    offset: $offset
    limit: $limit
  ) {
    nodes {
      id
      title
      shopId
      sourceId
      product {
        productId
        title
        defaultPicture
        categoryName
      }
      shopSKU
      active
      image
      price
      currency
    }
    total
  }
}
    `;

/**
 * __useGetOffersQuery__
 *
 * To run a query within a React component, call `useGetOffersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOffersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOffersQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *      status: // value for 'status'
 *      title: // value for 'title'
 *      categories: // value for 'categories'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetOffersQuery(baseOptions: Apollo.QueryHookOptions<GetOffersQuery, GetOffersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOffersQuery, GetOffersQueryVariables>(GetOffersDocument, options);
      }
export function useGetOffersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOffersQuery, GetOffersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOffersQuery, GetOffersQueryVariables>(GetOffersDocument, options);
        }
export type GetOffersQueryHookResult = ReturnType<typeof useGetOffersQuery>;
export type GetOffersLazyQueryHookResult = ReturnType<typeof useGetOffersLazyQuery>;
export type GetOffersQueryResult = Apollo.QueryResult<GetOffersQuery, GetOffersQueryVariables>;
export const UserPhoneImageDocument = gql`
    query userPhoneImage($marketplaceId: String!) {
  userPhoneImage(marketplaceId: $marketplaceId)
}
    `;

/**
 * __useUserPhoneImageQuery__
 *
 * To run a query within a React component, call `useUserPhoneImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPhoneImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPhoneImageQuery({
 *   variables: {
 *      marketplaceId: // value for 'marketplaceId'
 *   },
 * });
 */
export function useUserPhoneImageQuery(baseOptions: Apollo.QueryHookOptions<UserPhoneImageQuery, UserPhoneImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPhoneImageQuery, UserPhoneImageQueryVariables>(UserPhoneImageDocument, options);
      }
export function useUserPhoneImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPhoneImageQuery, UserPhoneImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPhoneImageQuery, UserPhoneImageQueryVariables>(UserPhoneImageDocument, options);
        }
export type UserPhoneImageQueryHookResult = ReturnType<typeof useUserPhoneImageQuery>;
export type UserPhoneImageLazyQueryHookResult = ReturnType<typeof useUserPhoneImageLazyQuery>;
export type UserPhoneImageQueryResult = Apollo.QueryResult<UserPhoneImageQuery, UserPhoneImageQueryVariables>;