import { InMemoryCache, makeVar } from '@apollo/client'
import { relayStylePagination } from "@apollo/client/utilities"
import { ICompareListType } from '../types/types'
import { ProfileParameter, PostEdge, CollectionElementFragment, UserRegisterResponse, ProductSearch, 
         MarketplaceCategorySearch, CreateAdMutationVariables, User, NumberFilter  } from '../types/graphql'
import { getLocalStorageWithTTL } from '../common'

/*export type IPostFilterVar = {
    postType: '' | 'media' | 'rating' | 'market'
    language: string
    mediaType?: '' | 'video' | 'photo'
    rating?: number | null
    tradeType?: '' | 'sell' | 'buy'
    condition?: '' | 'new' | 'used'
    location?: string
    //radius: ''
    price?: {
        min: number
        max: number
    }
}*/

type IDialogFieldsType = {
    post?: {
        id: string
        imageId: string | null | undefined
        message: string
        price: number | null | undefined
        currency: string | null | undefined
    }
    opponent: {
        id: string | null | undefined
        name: string | null | undefined
    }
} | null

type successType = {
    type: string
    message: string
} | null

export type errorsType = errorType[]

export interface errorType {
    text: string
    snackbar?: boolean
}

interface confirmUserVarType extends UserRegisterResponse {
    pass?: string
    timestamp: string
    __typename?: any
}

export type IAddAdAutosaveVarType = {
    product?: ProductSearch | null
    variables?: CreateAdMutationVariables
}

interface IRegionVar {
    country?: {
        name?: string,
        code?: string,
        phoneCode?: string
    },
    city?: {
        name?: String,
        coords?: number[]
        postal?: string
    },
    currency?: string
}

interface ICategoryFilter {
    price?: NumberFilter
    inStock?: boolean
    brand?: string[]
    filter?: ProfileParameter[]
}

interface ILocation {
    coords?: [number, number]
    radius?: number
    name?: string
}

const compareList = localStorage.getItem('compareList')
const defaultCompareList: ICompareListType = (compareList) ? JSON.parse(compareList) : null 

const location = localStorage.getItem('location')
const defaultLocation: ILocation = (location) ? JSON.parse(location) : null

export const successVar = makeVar<successType>(null)
export const errorsVar = makeVar<errorsType>([])
export const userVar = makeVar<User | null>(null)
export const editPostFieldsVar = makeVar<PostEdge['node'] | null>(null)
export const editCollectonVar = makeVar<CollectionElementFragment | null>(null)
export const dialogFieldsVar = makeVar<IDialogFieldsType>(null)
export const categoryFilterVar = makeVar<ICategoryFilter>({})
export const confirmUserVar = makeVar<confirmUserVarType | null>(null)
export const compareListVar = makeVar<ICompareListType>(defaultCompareList)
export const addAdAutosaveVar = makeVar<IAddAdAutosaveVarType>(JSON.parse(getLocalStorageWithTTL('add-ad-autosave') || '{}'))
export const regionVar = makeVar<IRegionVar>(JSON.parse(localStorage.getItem('region') || '{}'))
export const locationVar = makeVar<ILocation>(defaultLocation)

export const cache: InMemoryCache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                filters: {
                    keyArgs: ['id', 'filter']
                },
                posts: relayStylePagination(['id']),
                comments: relayStylePagination(['id', 'first', 'last']),
                replies: relayStylePagination(['id', 'first', 'last']),
                editPostFieldsVar: {
                    read: () => editPostFieldsVar()
                },
                messages: relayStylePagination(['id']),
                userInfo: {
                    read: (data) => (data === null) ? undefined : data
                },
                /*allCategories: {
                    keyArgs: ['id'],
                    merge(existing = [], incoming) {
                        return [...existing, ...incoming]
                    }
                }*/
            }
        },
        Image: {
            keyFields: ['image']
        },
        VideoInfo: {
            keyFields: ['videoId']
        },
        ProfileParameterGroups: {
            keyFields: ['id', 'params']
        },
        ProfileParamsValue: {
            keyFields: ['id', 'value']
        }
    }
})